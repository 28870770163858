import { StrikeLogo } from '../../../assets'
import { Page, type PageProps } from '../Page'

export interface LandingPageProps extends PageProps {
  backgroundImage?: string
  backgroundColor?: string
}

export function LandingPage({
  backgroundImage,
  backgroundColor = 'black',
  ...rest
}: LandingPageProps) {
  return (
    <Page
      fullWidth
      backgroundImage={backgroundImage ? `url('/${backgroundImage}')` : undefined}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundColor={backgroundColor}
      {...rest}
    >
      <StrikeLogo height={56} />
    </Page>
  )
}
