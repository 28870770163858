import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { progressAnatomy } from '@chakra-ui/anatomy'

export const progressStyles: ComponentMultiStyleConfig = {
  parts: progressAnatomy.keys,

  variants: {
    progressBar: {
      track: {
        bg: 'layerSecondary',
        borderRadius: '12px',
      },
      filledTrack: {
        bg: 'faceAccent',
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
      },
    },
    progressBarFull: {
      track: {
        bg: 'layerPrimary',
        borderRadius: '12px',
      },
      filledTrack: {
        bg: 'faceAccent',
      },
    },
  },
}
