import { Flex } from '@chakra-ui/react'
import { TextLimit } from '../Limit'
import { LimitCard } from '../LimitCard'

export function EuLimits() {
  return (
    <Flex direction="column" flex={1}>
      <LimitCard title="Deposit">
        <TextLimit caption="Bank deposit" limit="Unlimited" />
      </LimitCard>

      <LimitCard title="Trade">
        <TextLimit caption="Buy & sell" limit="Unlimited" />
      </LimitCard>
    </Flex>
  )
}
