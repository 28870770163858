import type { FC } from 'react'
import {
  TableContainer as BaseTableContainer,
  forwardRef,
  useMultiStyleConfig,
  type TableProps as BaseTableContainerProps,
} from '@chakra-ui/react'

export type TableContainerProps = BaseTableContainerProps

export const TableContainer: FC<TableContainerProps> = forwardRef<
  TableContainerProps,
  typeof BaseTableContainer
>(({ children, ...rest }, ref) => {
  const styles = useMultiStyleConfig('Table', rest)

  return (
    <BaseTableContainer ref={ref} sx={styles['container']} {...rest}>
      {children}
    </BaseTableContainer>
  )
})
