import type { FC, ReactElement, ReactNode } from 'react'
import { useFeatureFlag } from '../hooks/useFeatureFlag'

export interface FeatureProps {
  children: ReactNode
  featureKey: string
  fallback?: ReactElement
}

export const Feature: FC<FeatureProps> = ({ featureKey, fallback = null, children }) => {
  const featureFlag = useFeatureFlag<boolean>(featureKey, false)

  if (!featureFlag) {
    return fallback
  }

  // ! https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
