import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import { useCallback } from 'react'

export function useBlockExplorerUrlUtils() {
  const env = useEnvSettings()

  const getAddressUrl = useCallback(
    (onchainAddress: string) => {
      return `${env.blockExplorerAddressBaseUrl}/${onchainAddress}`
    },
    [env.blockExplorerAddressBaseUrl],
  )

  const getTxUrl = useCallback(
    (txid: string) => {
      return `${env.blockExplorerTxBaseUrl}/${txid}`
    },
    [env.blockExplorerTxBaseUrl],
  )

  return { getAddressUrl, getTxUrl }
}
