import { useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export type ChartProps = {
  data?: { date: string; close: number }[]
  onSelectionChange: (price: string | null) => void
  xTickFormatter?: (value: string) => string
  dataKey: string
  xKey: string
}

export function Chart({ data = [], dataKey, xKey, xTickFormatter, onSelectionChange }: ChartProps) {
  const [percentage, setPercentage] = useState(100)

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        data={data}
        onMouseMove={(hoveredData) => {
          if (hoveredData && hoveredData.activePayload) {
            const hoveredX = hoveredData.activePayload[0].payload.close
            const index = data.findIndex((d) => d.close === hoveredX)
            const p = ((data.length - index - 1) * 100) / (data.length - 1)
            setPercentage(100 - p)
            onSelectionChange && onSelectionChange(hoveredX)
          }
        }}
        onMouseLeave={() => {
          onSelectionChange && onSelectionChange(null)
          setPercentage(100)
        }}
      >
        <YAxis type="number" domain={['dataMin', 'auto']} hide />
        <XAxis
          dataKey={xKey}
          tickFormatter={xTickFormatter}
          minTickGap={60}
          axisLine={false}
          tickLine={false}
          fontSize="10px"
          interval="preserveStartEnd"
        />
        <Tooltip content={() => <div />} />
        <Area
          type="monotone"
          dataKey={dataKey}
          strokeWidth={2}
          fill="url(#colorUv)"
          stroke="url(#stroke)"
        />
        <defs>
          <linearGradient id="stroke" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#FFD4D4" stopOpacity={1} />
            <stop offset={`${percentage}%`} stopColor="#FFD4D4" stopOpacity={1} />
            <stop offset={`${percentage}%`} stopColor="#FFD4D4" stopOpacity={0.32} />
            <stop offset={`${100}%`} stopColor="#FFD4D4" stopOpacity={0.32} />
          </linearGradient>

          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset={'0%'} stopColor="#FFD4D4" stopOpacity={0.24} />
            <stop offset="100%" stopColor="#0" stopOpacity={0} />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  )
}
