export const styles = {
  global: {
    'html, body': {
      bg: 'layerBackground',
      color: 'faceSecondary',
    },
    '#chakra-toast-manager-bottom-right': {
      bottom: '80px !important',
    },
  },
}
