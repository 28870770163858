import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { BitcoinUnits } from '@ln-zap/consumer-api'
import { BITCOIN_UNIT_QUERY } from './queries'

export const useBitcoinUnit = () => {
  return useQuery<BitcoinUnits, StrikeFetchError<ErrorDetails>>([BITCOIN_UNIT_QUERY], () =>
    fetch<BitcoinUnits>('/api/settings/bitcoinunit', { method: 'GET' }),
  )
}
