import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type {
  MultiFactorSetup,
  MultiFactorStatus,
  MultiFactorVerificationResult,
} from '@strike-apps/strike/api-client'
import type { StrikeFetchError } from '@strike-apps/strike/fetch'
import { fetch } from '@strike-apps/strike/fetch'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SETUP_2FA, STATUS_2FA } from './queries'

export const use2FAStatus = () => {
  return useQuery<MultiFactorStatus | null, StrikeFetchError<ErrorDetails>>([STATUS_2FA], () =>
    fetch<MultiFactorStatus>('/api/auth/2fa/status'),
  )
}

export const useVerify2FA = () => {
  const verify2FA = useMutation({
    mutationFn: async (passCode: string) => {
      return fetch<MultiFactorVerificationResult>('/api/auth/2fa/verify', {
        method: 'POST',
        body: JSON.stringify({ passCode }),
      })
    },
  })
  return verify2FA
}

export const useEnable2FA = () => {
  const queryClient = useQueryClient()
  const verify2FA = useMutation({
    mutationFn: async (passCode: string) => {
      return fetch<MultiFactorStatus>('/api/auth/2fa/enable', {
        method: 'POST',
        body: JSON.stringify({ passCode }),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([STATUS_2FA])
    },
  })
  return verify2FA
}

export const useDisable2FA = () => {
  const queryClient = useQueryClient()
  const disable2FA = useMutation({
    mutationFn: async (passCode: string) => {
      return fetch<MultiFactorStatus>('/api/auth/2fa/disable', {
        method: 'POST',
        body: JSON.stringify({ passCode }),
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([STATUS_2FA])
    },
  })
  return disable2FA
}

export const useSetup2FA = () => {
  return useQuery<MultiFactorSetup | null, StrikeFetchError<ErrorDetails>>([SETUP_2FA], () =>
    fetch<
      MultiFactorSetup & {
        uri: string
      }
    >('/api/auth/2fa/setup', {
      method: 'POST',
    }),
  )
}

export const useMigration2FA = () => {
  const migrate2FA = useMutation({
    mutationFn: async (secretKey: string) => {
      return fetch<MultiFactorVerificationResult>('/api/auth/2fa/migrate', {
        method: 'POST',
        body: JSON.stringify({ secretKey }),
      })
    },
  })
  return migrate2FA
}
