export { default as Number1Icon } from './one.svg'
export { default as Number2Icon } from './two.svg'
export { default as Number3Icon } from './three.svg'
export { default as Number4Icon } from './four.svg'
export { default as Number5Icon } from './five.svg'
export { default as Number6Icon } from './six.svg'
export { default as Number7Icon } from './seven.svg'
export { default as Number8Icon } from './eight.svg'
export { default as Number9Icon } from './nine.svg'
export { default as Number0Icon } from './zero.svg'
