import type { ReactNode } from 'react'
import { Badge, Flex, Text, Spacer } from '@strike-apps/shared/ui'
import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'

interface ContentHeaderBaseProps {
  rightContent?: ReactNode
}
interface ContentHeaderPropsWithTitle extends ContentHeaderBaseProps {
  title: string
  children?: never
}
interface ContentHeaderPropsWithChildren extends ContentHeaderBaseProps {
  title?: never
  children: (isSandboxMode: boolean) => ReactNode
}
export type ContentHeaderProps = ContentHeaderPropsWithTitle | ContentHeaderPropsWithChildren

export const CONTENT_HEADER_TEXT_ID = 'content-header-data-label'

export function ContentHeader({ title, children, rightContent }: ContentHeaderProps) {
  const { isSandboxEnvironment } = useEnvSettings()

  return (
    <Flex justify="space-between" align="center">
      <Text id={CONTENT_HEADER_TEXT_ID} variant="body2" fontWeight="bold" color="facePrimary">
        {children ? children(isSandboxEnvironment) : title}
      </Text>
      {isSandboxEnvironment && (
        <Badge ml="8px" variant="warning" size="sm">
          TEST DATA SHOWN
        </Badge>
      )}
      <Spacer />
      {rightContent}
    </Flex>
  )
}
