import type { StyleFunctionProps } from '@chakra-ui/system'
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const alertStyles: ComponentMultiStyleConfig = {
  parts: ['description', 'title'],

  baseStyle: (props: StyleFunctionProps) => ({
    container: {
      bg: 'layerSecondary',
      px: 6,
      py: 4,
      borderRadius: 12,
    },

    title: {
      color: 'facePrimary',
      ...props.theme['textStyles'].subheadline2,
    },
    description: {
      color: 'faceSecondary',
      textAlign: 'center',
      ...props.theme['textStyles'].body3,
    },
  }),
}
