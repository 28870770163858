import { Flex, useClipboard } from '@chakra-ui/react'
import { Box } from '../../layout/Box'
import { Button } from '../Button'
import { Text } from '../../typography/Text'
import { Icon } from '@chakra-ui/react'
import { CircleCheckIcon, CopyIcon } from '@strike-apps/shared/icons/core'

export interface TextAreaClipboardCopyV2Props {
  value: string
}

export const TextAreaClipboardCopyV2 = (props: TextAreaClipboardCopyV2Props) => {
  const { value } = props
  const { hasCopied, onCopy } = useClipboard(value)

  return (
    <Box position="relative" w="100%" bg="layerSecondary" color="facePrimary" borderRadius="8px">
      <Flex justify="center" alignItems="center" flexWrap="wrap" p={6}>
        <Text variant="mono1" textAlign="center" wordBreak="break-all">
          {props.value}
        </Text>
      </Flex>

      <Box
        role="textbox"
        position="absolute"
        w="100%"
        h="100%"
        top="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        transitionProperty="common"
        transitionDuration="normal"
        opacity={0}
        _hover={{ opacity: 1 }}
        borderRadius="8px"
        bg="rgba(43, 43, 43, 0.57)"
      >
        <Button
          onClick={onCopy}
          size="sm"
          colorScheme="secondary"
          leftIcon={
            <Icon
              color={hasCopied ? 'facePositive' : 'facePrimary'}
              as={hasCopied ? CircleCheckIcon : CopyIcon}
              boxSize={4}
            />
          }
        >
          {hasCopied ? 'Copied' : 'Copy to clipboard'}
        </Button>
      </Box>
    </Box>
  )
}
