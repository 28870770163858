import { padStart } from 'lodash-es'

type ColorSchemeInput = {
  dark: string[]
  light: string[]
}

/**
 * Builds color map for theme out of dark/light color arrays.
 *
 * @param dark Array with dark colors.
 * @param light Array with light colors.
 * @returns Returns a map with each color provided in array. If there are multiple colors provided in array,
 * it will create indexed colors in map like `dark.01`, `dark.02`, `light.01`, `light.02`.
 */
const buildColorMap = ({ dark, light }: ColorSchemeInput) => {
  if (dark.length !== light.length) {
    throw new Error('Dark and light colors must have the same length')
  }

  if (dark.length === 1) {
    return {
      dark: dark[0],
      light: light[0],
    }
  }

  const colors: Record<string, string> = {}

  for (let i = 0; i < dark.length; i++) {
    const prefix = padStart(`${i + 1}`, 2, '0')

    colors[`${prefix}.dark`] = dark[i]
    colors[`${prefix}.light`] = light[i]
  }

  return colors
}

const opacityLayers = [32, 52, 64]

/**
 * Function will take a object with colors and will add multiple opacity variants by adding HEX opacity part at the end of the HEX value.
 *
 * @param palette Color scheme input
 * @returns Object with HEX colors enriched by another opacity variants.
 */
const buildColorSchemeWithOpacityLayers = (palette: ColorSchemeInput): Record<string, string> => {
  const colors = buildColorMap(palette)
  const newColors: Record<string, string> = {}

  Object.entries(colors).forEach(([colorKey, colorHex]) => {
    newColors[colorKey] = colorHex

    opacityLayers.forEach((opacity) => {
      const opacityHex = Math.round((opacity / 100) * 255).toString(16)
      const newColor = `${colorHex}${opacityHex}`
      const newKey = `${colorKey}/${opacity}`

      newColors[newKey] = newColor.toUpperCase()
    })
  })

  return newColors
}

export const colors = {
  background: buildColorSchemeWithOpacityLayers({
    dark: ['#000000'],
    light: ['#FCFCFC'],
  }),
  primaryInverse: buildColorSchemeWithOpacityLayers({
    dark: ['#050505'],
    light: ['#FCFCFC'],
  }),
  primary: buildColorSchemeWithOpacityLayers({
    dark: ['#FFFFFF'],
    light: ['#050505'],
  }),
  grey: buildColorSchemeWithOpacityLayers({
    dark: ['#1E1E1E', '#2E2E2E', '#3E3E3E', '#BFBFBF', '#E6E6E6', '#F2F2F2'],
    light: ['#F5F5F5', '#EBEBEB', '#D1D1D1', '#A8A8A8', '#7A7A7A', '#292929'],
  }),
  rose: buildColorSchemeWithOpacityLayers({
    dark: ['#471414', '#EB8D8D', '#FFD4D4'],
    light: ['#471414', '#EB8D8D', '#FFD4D4'],
  }),
  green: buildColorSchemeWithOpacityLayers({
    dark: ['#0F3D15', '#42B852', '#BAF5C2'],
    light: ['#D9FCE2', '#2E994A', '#1C5C2D'],
  }),
  red: buildColorSchemeWithOpacityLayers({
    dark: ['#52130A', '#EB5842', '#F0C4BD'],
    light: ['#FCDEDE', '#EA2525', '#7A1313'],
  }),
}
