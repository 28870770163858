import { useEffect, useState } from 'react'
import random from 'lodash-es/random'

const PROGRESS_START_THRESHOLD = 700
let startTimeoutId: ReturnType<typeof setTimeout>
let incrementTimeoutId: ReturnType<typeof setTimeout>
let limitOfArbitraryUpdates = 0

/**
 * Provides randomly generated progress value (from 0 to 100) to give the user a sense of progress while something is loading.
 */
export function useFakeProgress() {
  const [progress, setProgress] = useState(0)

  function start() {
    limitOfArbitraryUpdates = random(75, 95)
    startTimeoutId = setTimeout(() => {
      setProgress(1)
      increment()
    }, PROGRESS_START_THRESHOLD)
  }

  function increment() {
    const nextUpdateTimeout = Math.round(Math.random() * 50)

    setProgress((progress) => {
      const percentToBeAdded = Math.round(Math.random() * 1.5)
      const nextProgressBarPercentage = Math.min(
        progress + percentToBeAdded,
        limitOfArbitraryUpdates,
      )

      if (progress === 100) return progress

      if (nextProgressBarPercentage < limitOfArbitraryUpdates) {
        incrementTimeoutId = setTimeout(increment, nextUpdateTimeout)
        return nextProgressBarPercentage
      }

      return limitOfArbitraryUpdates
    })
  }

  function complete() {
    clearTimeout(startTimeoutId)
    clearTimeout(incrementTimeoutId)
    if (progress > 0) {
      setProgress(100)
      setTimeout(() => {
        setProgress(0)
      }, 100)
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(startTimeoutId)
      clearTimeout(incrementTimeoutId)
    }
  }, [])

  return { progress, start, complete }
}
