import type { FC } from 'react'
import {
  Th as BaseTh,
  forwardRef,
  type TableColumnHeaderProps as BaseTableColumnHeaderProps,
} from '@chakra-ui/react'

export interface TableColumnHeaderProps extends BaseTableColumnHeaderProps {
  align?: 'left' | 'right'
}

export const Th: FC<TableColumnHeaderProps> = forwardRef<TableColumnHeaderProps, typeof BaseTh>(
  ({ align = 'left', children, ...rest }, ref) => {
    return (
      <BaseTh ref={ref} {...rest} isNumeric={align === 'right'}>
        {children}
      </BaseTh>
    )
  },
)
