import { Currency } from '@strike-apps/strike/api-client'
import { useBalances } from './useBalances'

export function useIsUK() {
  const { cashBalance } = useBalances()
  return cashBalance?.currency === Currency.GBP
}

export function useIsUS() {
  const { cashBalance } = useBalances()
  return cashBalance?.currency === Currency.USD
}
