import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { textStyles } from '../foundations/textStyles'

export const tableStyles: ComponentMultiStyleConfig = {
  parts: ['table', 'tbody', 'tr', 'th', 'td', 'caption'],

  baseStyle: {
    container: {
      borderTopWidth: '1px',
      borderColor: 'borderPrimary',
    },
    table: {
      fontVariantNumeric: 'unset',
    },
    thead: {
      th: {
        height: '68px',
        textTransform: 'none',
        color: 'facePrimary',
        borderColor: 'borderPrimary',
      },
    },
    tbody: {
      tr: {
        color: 'faceTertiary',
        _hover: {
          bg: 'layerSecondary',
          color: 'facePrimary',
        },
      },
      td: {
        borderTop: '1px solid',
        borderBottom: 0,
        borderColor: 'borderPrimary',
        ...textStyles.body4,
      },
    },
  },
}
