import { createContext, type FC, type ReactNode, useContext } from 'react'
import type { Environment } from '@strike-apps/shared/config'

export interface EnvSettings {
  strikeIdentityServerUrl: string
  origin: string
  environment: Environment
  bugsnagApiKey: string
  gaMeasurementId: string
  isRunningOnCI: boolean
  strikeApiScope: string
  featureFlagsClientKey: string
  rqRetryCount: number
  rqRetryIncrementalDelay: number
  supportEmailAddress: string
  isSandboxEnvironment: boolean
  firestoreDatabaseId: string
  firestoreEmulator: { host: string; port: number } | null
  plaidRedirectURL: string
  blockExplorerAddressBaseUrl: string
  blockExplorerTxBaseUrl: string
  maxBtcBuy: number
  maxLightningWithdrawal: number
  minOnchainWithdrawal: number
  maxRequestAmount: number
  minRequestAmount: number
  personaEnvironmentUS: string
  personaEnvironmentNonUS: string
}

const EnvSettingsContext = createContext<EnvSettings>({} as EnvSettings)
EnvSettingsContext.displayName = 'EnvSettingsContext'

interface EnvSettingsProviderProps {
  children: ReactNode
  envSettings: EnvSettings
}

export const EnvSettingsProvider: FC<EnvSettingsProviderProps> = ({ envSettings, ...props }) => {
  return <EnvSettingsContext.Provider value={envSettings} {...props} />
}

export function useEnvSettings() {
  const context = useContext(EnvSettingsContext)
  if (context === undefined) {
    throw new Error(`useEnvSettings() hook must be used within the EnvSettingsProvider`)
  }
  return context
}
