import type { StackProps as BaseStackProps } from '@chakra-ui/react'
import type { FC } from 'react'

import { VStack as BaseStack, StackDivider, forwardRef } from '@chakra-ui/react'

export interface VerticalStackProps extends BaseStackProps {
  withDivider?: boolean
}

export const VerticalStack: FC<VerticalStackProps> = forwardRef<
  VerticalStackProps,
  typeof BaseStack
>(({ children, withDivider, ...rest }, ref) => {
  const divider = withDivider ? <StackDivider /> : undefined

  return (
    <BaseStack ref={ref} divider={divider} {...rest}>
      {children}
    </BaseStack>
  )
})
