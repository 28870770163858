import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function useRemoveQueryParam(paramName: string, saveToSessionStorage = false) {
  const router = useRouter()
  const paramValue = router.query[paramName]
  const result = Array.isArray(paramValue) ? paramValue[0] : paramValue
  useEffect(() => {
    if (result) {
      if (saveToSessionStorage) {
        sessionStorage.setItem(paramName, result)
      }
      delete router.query[paramName]
      router.push(router, undefined, { shallow: true })
    }
  }, [router, result, saveToSessionStorage, paramName])

  return result
}
