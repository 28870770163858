import type { ComponentMultiStyleConfig, ComponentSingleStyleConfig } from '@chakra-ui/theme'

export const fieldBaseStyles = {
  color: 'facePrimary',
  bg: 'layerBackground',
  textStyle: 'subheadline2',
  border: '1px solid',
  borderColor: 'borderPrimary',
  borderRadius: '16px',
  fontWeight: '500',
  px: '14px',

  _hover: {
    bg: 'layerSecondary',
  },

  _focus: {
    border: '1px solid',
    bg: 'layerSecondary',
    outline: 'none',
  },

  _placeholder: {
    textStyle: 'subheadline2',
    fontWeight: '500',
    color: 'facePrimaryDisabled',
  },

  _disabled: {
    pointerEvents: 'none',
    color: 'facePrimaryDisabled',
    bg: 'layerBackgroundDisabled',
  },
}

export const inputStyles: ComponentMultiStyleConfig = {
  parts: ['field'],
  baseStyle: {
    field: fieldBaseStyles,
  },
  sizes: {
    lg: {
      field: {
        height: '56px',
      },
    },
    md: {
      field: {
        height: '40px',
      },
    },
    sm: {
      field: {
        height: '32px',
      },
    },
  },
  variants: {
    normal: {
      field: {
        height: '68px',
        px: 4,
      },
    },
    modal: {
      field: {
        bg: 'objectPrimaryAlt',
      },
    },
    textField: {
      field: {
        height: '88px',
        paddingTop: '44px',
        paddingBottom: '24px',
      },
    },

    phoneTextFieldPart: {
      field: {
        height: '88px',
        bg: 'transparent',
        paddingTop: '44px',
        paddingBottom: '24px',
        transition: 'none',
        _focus: {
          outline: 'none',
          bg: 'transparent',
        },
      },
    },
  },
}

export const textAreaStyles: ComponentSingleStyleConfig = {
  baseStyle: {
    ...fieldBaseStyles,
    p: '16px',
  },
}
