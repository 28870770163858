import { useToast as useBaseToast, type ToastId } from '@chakra-ui/react'
import { useCallback } from 'react'
import { InfoToast, type InfoToastProps } from '../components/feedback/InfoToast'

interface UseToastProps extends InfoToastProps {
  message: string
  duplicateToasts?: boolean
}

export const useInfoToast = (toastId?: ToastId) => {
  const baseToast = useBaseToast({
    id: toastId,
    position: 'top',
    isClosable: false,
  })

  const handleIsActive = useCallback(() => {
    return toastId ? baseToast.isActive(toastId) : false
  }, [baseToast, toastId])

  const handleClose = useCallback(() => {
    if (toastId) {
      baseToast.close(toastId)
    }
  }, [baseToast, toastId])

  const handleCloseAll = useCallback(() => baseToast.closeAll(), [baseToast])

  const handleShowToast = useCallback(
    ({ message, duplicateToasts }: UseToastProps) => {
      if (duplicateToasts || !handleIsActive()) {
        baseToast({
          duration: 3000,
          render: () => <InfoToast message={message} />,
        })
      }
    },
    [baseToast, handleIsActive],
  )

  return {
    showToast: handleShowToast,
    isActive: handleIsActive,
    close: handleClose,
    closeAll: handleCloseAll,
  }
}
