export { ReactComponent as Number1Icon } from './one.svg'
export { ReactComponent as Number2Icon } from './two.svg'
export { ReactComponent as Number3Icon } from './three.svg'
export { ReactComponent as Number4Icon } from './four.svg'
export { ReactComponent as Number5Icon } from './five.svg'
export { ReactComponent as Number6Icon } from './six.svg'
export { ReactComponent as Number7Icon } from './seven.svg'
export { ReactComponent as Number8Icon } from './eight.svg'
export { ReactComponent as Number9Icon } from './nine.svg'
export { ReactComponent as Number0Icon } from './zero.svg'
