import type { FC } from 'react'
import { Table as BaseTable, forwardRef, type TableProps as BaseTableProps } from '@chakra-ui/react'

export interface TableProps extends BaseTableProps {
  tableLayout?: 'auto' | 'fixed'
}

export const Table: FC<TableProps> = forwardRef<TableProps, typeof BaseTable>(
  ({ children, tableLayout = 'auto', ...rest }, ref) => {
    return (
      <BaseTable ref={ref} {...rest} style={{ tableLayout }}>
        {children}
      </BaseTable>
    )
  },
)
