import type { ReactNode } from 'react'
import { Text } from '@strike-apps/shared/ui'

interface FooterProps {
  children: ReactNode
}

export function Footer({ children }: FooterProps) {
  return (
    <Text variant="body4" color="faceSecondary">
      {children}
    </Text>
  )
}
