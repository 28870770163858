import { Flex, Grid } from '@chakra-ui/react'
import { GridItem } from '@strike-apps/shared/ui'
import React from 'react'
import { GridConfig, GridGap } from './grid-config'

type NestedGridProps = {
  children: React.ReactElement | React.ReactElement[]
  config?: typeof GridConfig
}

export const NestedGrid = ({ children, config = GridConfig }: NestedGridProps) => {
  return (
    <GridItem gridColumn="1/-1">
      <Flex width="100%" justify="center">
        <Grid templateColumns={config} gap={GridGap}>
          {children}
        </Grid>
      </Flex>
    </GridItem>
  )
}
