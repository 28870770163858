import type { FC } from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button as BaseButton, forwardRef } from '@chakra-ui/react'

export const MenuButton: FC<ButtonProps> = forwardRef<ButtonProps, typeof BaseButton>(
  ({ children, ...rest }, ref) => {
    return (
      <BaseButton
        ref={ref}
        textAlign="left"
        fontSize="13px"
        lineHeight="16px"
        fontWeight={700}
        borderTopWidth={0}
        _active={{
          outlineColor: 'transparent',
        }}
        {...rest}
      >
        {children}
      </BaseButton>
    )
  },
)
