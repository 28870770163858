import type { FC, ReactNode } from 'react'
import { Box } from '../../layout/Box'
import { Flex } from '../../layout/Flex'
import { Spacer } from '../../layout/Spacer'
import { VerticalStack, type VerticalStackProps } from '../../layout/VerticalStack'
import { Text, type TextProps } from '../../typography/Text'

type DoubleListDividerVariant = 'top' | 'bottom' | 'both' | 'inner' | 'none' | 'all'

export interface DoubleListProps extends VerticalStackProps {
  divideVariant?: DoubleListDividerVariant
}

type DoubleListComponent = FC<DoubleListProps> & {
  Row: FC<DoubleListRowProps>
}

const DoubleList: DoubleListComponent = ({ divideVariant = 'bottom', children, ...rest }) => {
  const borderProps = getBorderProps(divideVariant)

  return (
    <VerticalStack
      align="left"
      withDivider={divideVariant !== 'none'}
      py={6}
      spacing={6}
      {...borderProps}
      borderColor="borderPrimary"
      {...rest}
    >
      {children}
    </VerticalStack>
  )
}

function getBorderProps(divideVariant: DoubleListDividerVariant) {
  const showBottomBorder = ['both', 'bottom'].includes(divideVariant)
  const showTopBorder = ['both', 'top'].includes(divideVariant)
  const showAllBorders = divideVariant === 'all'
  if (showAllBorders) {
    return { border: '1px solid' }
  }

  if (showTopBorder) {
    return showBottomBorder
      ? {
          borderTop: '1px solid',
          borderBottom: '1px solid',
        }
      : {
          borderTop: '1px solid',
        }
  }

  if (showBottomBorder) {
    return { borderBottom: '1px solid' }
  }

  return {}
}

export interface DoubleListRowProps {
  children?: ReactNode
  label: ReactNode
  labelProps?: TextProps
  withSpacer?: boolean
}

const DoubleListRow: FC<DoubleListRowProps> = ({
  children,
  label,
  labelProps,
  withSpacer = true,
}) => {
  return (
    <Flex alignItems="center">
      <Text as="label" variant="subheadline3" minWidth="100px" pr="12px" {...labelProps}>
        {label}
      </Text>

      {withSpacer && <Spacer minW={3} />}

      <Box color="facePrimary" textStyle="subheadline2">
        {children}
      </Box>
    </Flex>
  )
}

DoubleList.Row = DoubleListRow

export { DoubleList }
