import { Flex, IconWithBackground } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'

export interface InfoSectionProps {
  children?: ReactNode
  icon?: React.FC
}

export const InfoSection = ({ icon, children }: InfoSectionProps) => {
  return (
    <Flex p={6} direction="column" alignItems="flex-start">
      {icon && <IconWithBackground mb={12} icon={icon} color="facePrimary" />}
      {children}
    </Flex>
  )
}
