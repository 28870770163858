import { useToast as useBaseToast, type ToastId } from '@chakra-ui/react'
import { useCallback } from 'react'
import { Toast, type ToastProps } from '../components/feedback/Toast'

interface UseToastProps extends Omit<ToastProps, 'onClose'> {
  duplicateToasts?: boolean
}

export const useToast = (toastId?: ToastId) => {
  const baseToast = useBaseToast({
    id: toastId,
    position: 'bottom-right',
    isClosable: true,
    containerStyle: {
      paddingRight: '24px',
    },
  })

  const handleIsActive = useCallback(() => {
    return toastId ? baseToast.isActive(toastId) : false
  }, [baseToast, toastId])

  const handleClose = useCallback(() => {
    if (toastId) {
      baseToast.close(toastId)
    }
  }, [baseToast, toastId])

  const handleCloseAll = useCallback(() => baseToast.closeAll(), [baseToast])

  const handleShowToast = useCallback(
    ({
      toastType,
      title,
      icon,
      message,
      linkLabel,
      duplicateToasts,
      linkAction,
    }: UseToastProps) => {
      if (duplicateToasts || !handleIsActive()) {
        baseToast({
          duration: linkLabel && linkAction ? null : 8000,
          render: ({ onClose }) => (
            <Toast
              onClose={onClose}
              toastType={toastType}
              icon={icon}
              title={title}
              message={message}
              linkLabel={linkLabel}
              linkAction={linkAction}
            />
          ),
        })
      }
    },
    [baseToast, handleIsActive],
  )

  return {
    showToast: handleShowToast,
    isActive: handleIsActive,
    close: handleClose,
    closeAll: handleCloseAll,
  }
}
