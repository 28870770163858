import type { FC, ReactNode } from 'react'

import type { MenuItemProps as BaseMenuItemProps } from '@chakra-ui/react'
import { Flex, forwardRef, MenuItem as BaseMenuItem, MenuDivider } from '@chakra-ui/react'

interface MenuItemProps extends BaseMenuItemProps {
  rightContent?: ReactNode
}

export const MenuItem: FC<MenuItemProps> = forwardRef<MenuItemProps, typeof BaseMenuItem>(
  ({ children, rightContent, ...rest }, ref) => {
    return (
      <BaseMenuItem
        ref={ref}
        textStyle={'subheadline2'}
        color={'facePrimary'}
        bg={'layerPrimary'}
        px={3}
        py={4}
        height="auto"
        {...rest}
      >
        <Flex w="100%" justify="space-between">
          {children}
          {rightContent}
        </Flex>
      </BaseMenuItem>
    )
  },
)

export { MenuDivider }
