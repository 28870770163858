import lightBolt11, { type DecodedInvoice } from 'light-bolt11-decoder'
import { isPast } from 'date-fns'

interface AmountSection {
  name: 'amount'
  letters: string
  value: string
}

interface TimestampSection {
  name: 'timestamp'
  letters: string
  value: number
}

const getAmountOfSats = (decodedInvoice: DecodedInvoice) => {
  const amountSection = decodedInvoice.sections.find(({ name }) => name === 'amount')

  return amountSection ? Number((amountSection as AmountSection).value) / 1000 : null
}

const isInvoiceExpired = (decodedInvoice: DecodedInvoice) => {
  const timestampSection = decodedInvoice.sections.find(
    ({ name }) => name === 'timestamp',
  ) as TimestampSection
  const timestamp = timestampSection.value * 1000
  const expiry = decodedInvoice.expiry * 1000

  return isPast(timestamp + expiry)
}

interface SimplifiedDecodedInvoice {
  amountOfSats: number | null
  isExpired: boolean
}

const decode = (invoice?: string): SimplifiedDecodedInvoice | null => {
  if (!invoice) {
    return null
  }

  try {
    const decodedInvoice = lightBolt11.decode(invoice)
    const amountOfSats = getAmountOfSats(decodedInvoice)
    const isExpired = isInvoiceExpired(decodedInvoice)

    return { amountOfSats, isExpired }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const bolt11 = {
  decode,
}
