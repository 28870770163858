import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { UserInfoResp } from '@ln-zap/consumer-api'
import { USER_INFO_QUERY } from './queries'

export const useUserInfo = () => {
  return useQuery<UserInfoResp, StrikeFetchError<ErrorDetails>>([USER_INFO_QUERY], () =>
    fetch<UserInfoResp>('/api/user/info', { method: 'GET' }),
  )
}
