import type { StyleFunctionProps } from '@chakra-ui/system'
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const drawerStyles: ComponentMultiStyleConfig = {
  parts: ['header', 'footer'],

  baseStyle: (props: StyleFunctionProps) => ({
    sizes: {
      sm: {
        width: '600px',
      },
    },
    dialog: {
      width: {
        base: '100%',
        md: '680px',
      },
      m: {
        base: 0,
        md: 2,
      },
      borderRadius: {
        base: 0,
        md: 12,
      },
    },
    header: {
      color: 'facePrimary',
      textAlign: 'center',
      ...props.theme['textStyles'].subheadline1,
      p: 6,
    },
    closeButton: {
      color: 'facePrimary',
      boxSize: 5,
      left: 6,
      top: 6,
    },
    body: {
      p: 6,
    },
    footer: {
      px: 6,
      pt: 4,
      pb: 6,
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
      width: '100%',
    },
  }),
}
