import { useEffect, useRef, useCallback } from 'react'

/**
 * useScrollDrag - Allows to add drag scroll behavior to react components.
 *
 * @param isReversed scroll direction
 */
export const useScrollDrag = <T extends HTMLElement>(
  isReversed: boolean,
): [React.RefObject<T>, (fn: (...args: unknown[]) => void) => (...args: unknown[]) => void] => {
  // parent scroller ref
  const scroller = useRef<T>(null)

  // current scrolling offsets
  const scrollDeltaX = useRef<number>(0)
  const scrollDeltaY = useRef<number>(0)

  const isScrolling = useRef<boolean>(false)

  // parent global scroll position
  const scrollLeft = useRef<number>(0)
  const scrollTop = useRef<number>(0)

  // original click coordinates
  const originX = useRef<number>(0)
  const originY = useRef<number>(0)

  const onScroll = (clientX: number, clientY: number): void => {
    if (!isScrolling.current) {
      return
    }
    const DIRECTION_MULTIPLIER = isReversed ? -1 : 1
    // calculate scroll offset and update DOM scroll position
    if (scroller.current) {
      scroller.current.scrollLeft =
        scrollLeft.current + DIRECTION_MULTIPLIER * (originX.current - clientX)
      scroller.current.scrollTop =
        scrollTop.current + (DIRECTION_MULTIPLIER * originY.current - clientY)
    }

    // remember current scroll offset
    scrollDeltaX.current = originX.current - clientX
    scrollDeltaY.current = originY.current - clientY
  }

  const onStartScroll = (clientX: number, clientY: number): void => {
    scrollDeltaX.current = 0
    scrollDeltaY.current = 0
    isScrolling.current = true
    if (scroller.current) {
      scrollLeft.current = scroller.current.scrollLeft
      scrollTop.current = scroller.current.scrollTop
    }
    originX.current = clientX
    originY.current = clientY
  }

  const onEndScroll = (): void => {
    isScrolling.current = false
  }

  const onMouseMove = (event: MouseEvent): void => {
    onScroll(event.clientX, event.clientY)
  }

  const onMouseDown = (event: MouseEvent): void => {
    onStartScroll(event.clientX, event.clientY)
  }

  // checks if scroll was released far enough from origin to dismiss
  const isScrollRelease = (): boolean =>
    Math.abs(scrollDeltaX.current) > 10 || Math.abs(scrollDeltaY.current) > 10

  const wrappedOnClick = useCallback((fn: (...args: unknown[]) => void) => {
    return (...args: unknown[]) => {
      if (!isScrollRelease()) {
        fn(...args)
      }
    }
  }, [])

  const onMouseUp = (): void => {
    onEndScroll()
  }

  const onTouchStart = (e: TouchEvent): void => {
    const event = e.targetTouches.item(0)
    if (event) {
      onStartScroll(event.clientX, event.clientY)
    }
  }

  const onTouchMove = (e: TouchEvent): void => {
    const event = e.targetTouches.item(0)
    if (event) {
      onScroll(event.clientX, event.clientY)
    }
  }

  const onTouchCancel = (): void => {
    onEndScroll()
  }
  const onTouchEnd = (): void => {
    onEndScroll()
  }

  useEffect(() => {
    const touchProps = { passive: true }
    const { current } = scroller
    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('mouseup', onMouseUp, false)

    if (current) {
      current.addEventListener('mousedown', onMouseDown, false)
      current.addEventListener('touchstart', onTouchStart, touchProps)
    }

    window.addEventListener('touchmove', onTouchMove, touchProps)
    window.addEventListener('touchcancel', onTouchCancel, touchProps)
    window.addEventListener('touchend', onTouchEnd, touchProps)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)

      if (current) {
        current.removeEventListener('mousedown', onMouseDown)
        current.removeEventListener('touchstart', onTouchStart)
      }

      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('touchcancel', onTouchCancel)
      window.removeEventListener('touchend', onTouchEnd)
    }
  })

  return [scroller, wrappedOnClick]
}
