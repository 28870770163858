import type { StyleFunctionProps } from '@chakra-ui/system'
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const menuStyles: ComponentMultiStyleConfig = {
  parts: ['menu', 'list', 'item'],
  baseStyle: (props: StyleFunctionProps) => ({
    list: {
      p: 0,
      minWidth: 0,
      borderRadius: '12px',
      bg: 'objectTertiary',
      overflow: 'hidden',
      zIndex: 4,
      border: 'none',
      boxShadow:
        props.colorMode === 'dark'
          ? '0px 94px 38px rgba(0, 0, 0, 0.01), 0px 53px 32px rgba(0, 0, 0, 0.05), 0px 24px 24px rgba(0, 0, 0, 0.09), 0px 6px 13px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)'
          : '0px 88px 35px rgba(0, 0, 0, 0.02), 0px 50px 30px rgba(0, 0, 0, 0.08), 0px 22px 22px rgba(0, 0, 0, 0.13), 0px 6px 12px rgba(0, 0, 0, 0.16), 0px 0px 0px rgba(0, 0, 0, 0.16);',
    },
    divider: {
      color: 'borderPrimary',
    },
    item: {
      bg: 'objectSecondary',
      h: '40px',

      border: 0,

      _hover: { bg: 'layerPrimaryHover' },
      _focus: { bg: 'layerPrimaryHover' },
      _pressed: { bg: 'layerPrimaryPressed' },
    },
  }),
}
