/**
 * Function that creates object for Chakra Color Mode
 *
 * @param darkColor Color used for dark theme.
 * @param lightColor Color used for light theme. If not provided, function will take dark color and toggle `dark` keyword for `light`.
 * @returns Object usable for Chakra semantic tokens.
 */
const withLightColor = (darkColor: string, lightColor?: string) => {
  return {
    default: darkColor,
    _light: lightColor ?? darkColor.replace('dark', 'light'),
  }
}
// Semantic tokens
// https://www.figma.com/file/Zd6EhRfsTxFS3V79TWnAMY/Tokens?node-id=0%3A1&mode=dev
export const semanticTokens = {
  colors: {
    layerBackground: withLightColor('background.dark'),
    layerBackgroundHover: withLightColor('grey.01.dark'),
    layerBackgroundPressed: withLightColor('grey.02.dark/32'),
    layerBackgroundDisabled: withLightColor('grey.02.dark/32'),

    layerPrimary: withLightColor('grey.01.dark'),
    layerPrimaryHover: withLightColor('grey.02.dark'),
    layerPrimaryPressed: withLightColor('grey.02.dark/32'),
    layerPrimaryDisabled: withLightColor('grey.02.dark/32'),
    layerPrimaryGRW: {
      default: 'rgba(30,30,30, 0.8)',
      _light: 'rgba(245,245,245, 0.8)',
    },

    layerSecondary: withLightColor('grey.02.dark'),
    layerSecondaryHover: withLightColor('grey.03.dark'),
    layerSecondaryPressed: withLightColor('grey.02.dark/32'),
    layerSecondaryDisabled: withLightColor('grey.02.dark/32'),

    layerTertiary: withLightColor('grey.03.dark'),
    layerTertiaryHover: withLightColor('grey.04.dark'),
    layerTertiaryPressed: withLightColor('grey.02.dark/32'),
    layerTertiaryDisabled: withLightColor('grey.02.dark/32'),

    objectPrimary: withLightColor('primary.dark'),
    objectPrimaryHover: withLightColor('grey.04.dark'),
    objectPrimaryPressed: withLightColor('grey.02.dark/32'),
    objectPrimaryDisabled: withLightColor('grey.02.dark/32'),

    objectSecondary: withLightColor('grey.02.dark'),
    objectSecondaryHover: withLightColor('grey.03.dark'),
    objectSecondaryPressed: withLightColor('grey.02.dark/32'),
    objectSecondaryDisabled: withLightColor('grey.02.dark/32'),

    objectTertiary: withLightColor('grey.01.dark'),
    objectTertiaryHover: withLightColor('grey.03.dark'),
    objectTertiaryPressed: withLightColor('grey.02.dark/32'),
    objectTertiaryDisabled: withLightColor('grey.02.dark/32'),

    objectAccent: withLightColor('rose.03.dark'),
    objectAccentHover: withLightColor('rose.03.dark/64'),
    objectAccentPressed: withLightColor('rose.01.dark/32'),
    objectAccentDisabled: withLightColor('rose.01.dark/32'),

    objectPositive: withLightColor('green.02.dark'),
    objectPositiveHover: withLightColor('green.02.dark/64'),
    objectPositivePressed: withLightColor('green.01.dark/32'),
    objectPositiveDisabled: withLightColor('green.01.dark/32'),

    objectNegative: withLightColor('red.02.dark'),
    objectNegativeHover: withLightColor('red.02.dark/64'),
    objectNegativePressed: withLightColor('red.01.dark/32'),
    objectNegativeDisabled: withLightColor('red.01.dark/32'),

    facePrimary: withLightColor('primary.dark'),
    facePrimaryInverse: withLightColor('primaryInverse.dark'),
    facePrimaryHover: withLightColor('primary.dark/64'),
    facePrimaryPressed: withLightColor('primary.dark/32'),
    facePrimaryDisabled: withLightColor('primary.dark/32'),

    faceSecondary: withLightColor('primary.dark/64'),
    faceSecondaryHover: withLightColor('primary.dark/64'),
    faceSecondaryPressed: withLightColor('primary.dark/32'),
    faceSecondaryDisabled: withLightColor('primary.dark/32'),

    faceTertiary: withLightColor('primary.dark/52'),
    faceTertiaryHover: withLightColor('primary.dark/64'),
    faceTertiaryPressed: withLightColor('primary.dark/32'),
    faceTertiaryDisabled: withLightColor('primary.dark/32'),

    faceAccent: withLightColor('rose.03.dark'),
    faceAccentHover: withLightColor('rose.03.dark/64'),
    faceAccentPressed: withLightColor('rose.03.dark/32'),
    faceAccentDisabled: withLightColor('rose.03.dark/32'),

    facePositive: withLightColor('green.02.dark'),
    facePositiveHover: withLightColor('green.02.dark/64'),
    facePositivePressed: withLightColor('green.02.dark/32'),
    facePositiveDisabled: withLightColor('green.02.dark/32'),

    faceNegative: withLightColor('red.02.dark'),
    faceNegativeHover: withLightColor('red.02.dark/64'),
    faceNegativePressed: withLightColor('red.02.dark/32'),
    faceNegativeDisabled: withLightColor('red.02.dark/32'),

    facePrimaryAlt: withLightColor('background.dark'),
    facePrimaryAltHover: withLightColor('background.dark/64'),
    facePrimaryAltPressed: withLightColor('background.dark/32'),
    facePrimaryAltDisabled: withLightColor('background.dark/32'),

    // Border Semantic Tokens
    // https://www.figma.com/file/Zd6EhRfsTxFS3V79TWnAMY/Tokens?node-id=3455%3A4116&t=fl3fmS4s5FR7Yw0l-0

    borderPrimary: withLightColor('grey.02.dark/64'),
    borderPrimaryDisabled: withLightColor('grey.01.dark/32'),

    borderSecondary: withLightColor('grey.02.dark'),

    borderAccent: withLightColor('rose.03.dark'),
    borderPositive: withLightColor('green.02.dark'),
    borderNegative: withLightColor('red.02.dark'),
  },
}
