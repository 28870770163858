import Script from 'next/script'

export interface GoogleAnalyticsScriptsProps {
  measurementId: string
  cookiesAllowed: boolean
}

export const GoogleAnalyticsScripts = ({
  measurementId,
  cookiesAllowed,
}: GoogleAnalyticsScriptsProps) => (
  <>
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
    />
    <Script
      id="gtag-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('consent', 'default', {
            'ad_storage': ${cookiesAllowed ? "'granted'" : "'denied'"},
            'ad_user_data': ${cookiesAllowed ? "'granted'" : "'denied'"},
            'ad_personalization': ${cookiesAllowed ? "'granted'" : "'denied'"},
            'analytics_storage': ${cookiesAllowed ? "'granted'" : "'denied'"},
          });
          gtag('config', '${measurementId}', {
            page_path: window.location.pathname,
          });
        `,
      }}
    />
  </>
)
