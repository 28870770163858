import {
  VStack,
  Flex,
  Text,
  useBreakpointValue,
  type ButtonSize,
  Button,
  type ButtonProps,
} from '@strike-apps/shared/ui'
import { Page as BasePage } from '@strike-apps/shared/ui'
import { StrikeWordmarkHeader } from '../StrikeWordmarkHeader'
import type { ReactNode } from 'react'
import SvgSystem from './SvgSystem'

interface ErrorPageProps {
  header: string
  description: string
  includeLogout?: boolean
  children: ReactNode
}

export function ErrorPage({ header, description, includeLogout, children }: ErrorPageProps) {
  return (
    <BasePage header={<StrikeWordmarkHeader includeLogout={includeLogout} />}>
      <Flex
        flexDir="column"
        gap={16}
        borderWidth="1px"
        borderColor="borderPrimary"
        borderRadius={12}
        color="facePrimary"
        p={{ base: 6, sm: 16 }}
      >
        <VStack textAlign="center" spacing={4} maxWidth={343}>
          <Text variant={{ base: 'title3', sm: 'title2' }} color="facePrimary">
            {header}
          </Text>
          <SvgSystem />
          <Text variant="body3" color="faceSecondary">
            {description}
          </Text>
        </VStack>
        <Flex justifyContent="center" gap={2} flexDirection={{ base: 'column', md: 'row' }}>
          {children}
        </Flex>
      </Flex>
    </BasePage>
  )
}

function ErrorPageCtaButton(props: ButtonProps) {
  const buttonSize = useBreakpointValue(
    {
      base: 'md',
      sm: 'lg',
    },
    {
      fallback: 'lg',
    },
  )

  return <Button {...props} size={buttonSize as ButtonSize} colorScheme="primary" w="auto" />
}

ErrorPage.CtaButton = ErrorPageCtaButton
