import { getRegionFromCountryCode } from '@strike-apps/shared/utils'
import { EuLimits } from './eu'
import { UKLimits } from './uk'
import { USLimits } from './us'

type region = 'europe' | 'us' | 'uk'

const SUPPORTED_REGIONS: region[] = ['europe', 'us', 'uk']

export function Limits({ country }: { country: string }) {
  const region = getRegionFromCountryCode(country) as region

  if (!SUPPORTED_REGIONS.includes(region)) {
    return null
  }

  const RegionLimits = {
    europe: EuLimits,
    us: USLimits,
    uk: UKLimits,
  }[region]

  return <RegionLimits />
}
