import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { tabsAnatomy as parts } from '@chakra-ui/anatomy'
import type { PartsStyleFunction, PartsStyleInterpolation } from '@chakra-ui/theme-tools'
import { textStyles } from '../foundations/textStyles'
const sizes = {
  md: {
    tab: {
      fontSize: '12px',
      fontWeight: 400,
      padding: '8px 12px',
    },
    tablist: {
      padding: '4px',
    },
  },
  lg: {
    tab: {
      fontSize: '18px',
      fontWeight: 500,
      padding: '12px 16px',
    },
    tablist: {
      padding: '0px',
    },
  },
}

const variantLine: PartsStyleFunction<typeof parts> = (props) => {
  const { orientation } = props
  const isVertical = orientation === 'vertical'
  const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderBottom'
  const marginProp = isVertical ? 'marginStart' : 'marginBottom'

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: 'borderSecondary',
    },
    tab: {
      [borderProp]: '3px solid',
      borderColor: 'transparent',
      color: 'facePrimaryDisabled',
      fontWeight: 'bold',
      [marginProp]: '-2px',
      _selected: {
        color: 'facePrimary',
        borderColor: 'borderAccent',
      },
      _active: {
        bg: 'inherit',
        outline: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  }
}

const variantSoftRounded: PartsStyleFunction<typeof parts> = () => {
  return {
    tablist: {
      padding: '2px',
      border: '1px solid',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      width: 'fit-content',
      borderColor: 'borderPrimary',
      height: '38px',
    },
    tab: {
      ...textStyles.button2,
      borderRadius: '8px',
      px: 4,
      color: 'faceTertiary',
      _focus: {
        boxShadow: 'none',
      },
      _selected: {
        borderColor: 'borderPrimary',
        color: 'facePrimary',
        bg: 'objectSecondary',
      },
    },
  }
}

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  line: variantLine,
  'soft-rounded': variantSoftRounded,
}

export const tabsStyles: ComponentMultiStyleConfig = {
  parts: parts.keys,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
  },
}

export type SelectSize = keyof typeof sizes
