import type { LDSingleKindContext } from 'launchdarkly-js-client-sdk'
import type { Session } from 'next-auth'

export const parseUserContext = (session?: Session): LDSingleKindContext => {
  if (!session || !session.user) {
    return {
      kind: 'user',
      anonymous: true,
    } as LDSingleKindContext
  }

  return {
    kind: 'user',
    key: session.user.id,
    isCompany: session.user.business,
  } as LDSingleKindContext
}
