import { useQuery } from '@tanstack/react-query'

import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { AccountLimits } from '@strike-apps/strike/api-client'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'

import { GET_LIMITS_QUERY } from './queries'

export const useLimitsQuery = () => {
  return useQuery<AccountLimits, StrikeFetchError<ErrorDetails>>([GET_LIMITS_QUERY], () =>
    fetch<AccountLimits>('/api/limits', { method: 'GET' }),
  )
}

export const useGetEffectiveDepositLimit = () => {
  const { data, ...rest } = useLimitsQuery()
  return { ...rest, limit: data?.achDeposit?.amount?.effective }
}
