import { markdownToHtml as baseMarkdownToHtml, type TagTransformer } from '@strike-apps/shared/cms'
import { sanitizeHtml } from '@strike-apps/shared/utils'
import * as AllIcons from '@strike-apps/shared/icons/raw'

export async function markdownToHtml(markdown: string) {
  const tagTransformer: TagTransformer = (tag: string) => {
    const icon = AllIcons[tag as keyof typeof AllIcons]
    if (icon) {
      return {
        tagName: 'img',
        properties: {
          width: '18px',
          height: '18px',
          src: icon,
          class: 'icon',
        },
      }
    }

    return null
  }
  const result = await baseMarkdownToHtml(markdown, tagTransformer)
  return sanitizeHtml(result)
}
