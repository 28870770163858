import type { Currency } from './data-contracts'
import type { StrikeFetchError } from '@strike-apps/strike/fetch'
import { isStrikeFetchErrorWithData } from '@strike-apps/strike/fetch'

export enum ErrorCode {
  NotFound = 'NOT_FOUND',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  MaintenanceMode = 'MAINTENANCE_MODE',
  RateLimitExceeded = 'RATE_LIMIT_EXCEEDED',
  Unauthorized = 'UNAUTHORIZED',
  Forbidden = 'FORBIDDEN',
  InvalidData = 'INVALID_DATA',
  InvalidDataQuery = 'INVALID_DATA_QUERY',
  UnprocessableEntity = 'UNPROCESSABLE_ENTITY',
  AccountNotReady = 'ACCOUNT_NOT_READY',
  InvalidStateForInvoicePaid = 'INVALID_STATE_FOR_INVOICE_PAID',
  InvalidStateForInvoiceCancelled = 'INVALID_STATE_FOR_INVOICE_CANCELLED',
  InvalidRecipient = 'INVALID_RECIPIENT',
  ProcessingPayment = 'PROCESSING_PAYMENT',
  DuplicateInvoice = 'DUPLICATE_INVOICE',
  SelfPaymentNotAllowed = 'SELF_PAYMENT_NOT_ALLOWED',
  UserCurrencyUnavailable = 'USER_CURRENCY_UNAVAILABLE',
  LnUnavailable = 'LN_UNAVAILABLE',
  ExchangeRateNotAvailable = 'EXCHANGE_RATE_NOT_AVAILABLE',
  BalanceTooLow = 'BALANCE_TOO_LOW',
  InvalidAmount = 'INVALID_AMOUNT',
  AmountTooHigh = 'AMOUNT_TOO_HIGH',
  AmountTooLow = 'AMOUNT_TOO_LOW',
  PaymentMethodNotReady = 'PAYMENT_METHOD_NOT_READY',
  PayoutOriginatorNotApproved = 'PAYOUT_ORIGINATOR_NOT_APPROVED',
  PayoutAlreadyInitiated = 'PAYOUT_ALREADY_INITIATED',
  InvalidStateForInvoiceExpired = 'INVALID_STATE_FOR_INVOICE_EXPIRED',
  PaymentProcessed = 'PAYMENT_PROCESSED',
  InvalidLnInvoice = 'INVALID_LN_INVOICE',
  InvalidBitcoinAddress = 'INVALID_BITCOIN_ADDRESS',
  LnRouteNotFound = 'LN_ROUTE_NOT_FOUND',
  PaymentQuoteExpired = 'PAYMENT_QUOTE_EXPIRED',
  DuplicatePaymentQuote = 'DUPLICATE_PAYMENT_QUOTE',
  DuplicateCurrencyExchangeQuote = 'DUPLICATE_CURRENCY_EXCHANGE_QUOTE',
  CurrencyExchangeQuoteProcessed = 'CURRENCY_EXCHANGE_QUOTE_PROCESSED',
  CurrencyExchangeQuoteExpired = 'CURRENCY_EXCHANGE_QUOTE_EXPIRED',
  CurrencyExchangePairNotSupported = 'CURRENCY_EXCHANGE_PAIR_NOT_SUPPORTED',
  CurrencyExchangeAmountTooLow = 'CURRENCY_EXCHANGE_AMOUNT_TOO_LOW',
  DepositLimitExceeded = 'DEPOSIT_LIMIT_EXCEEDED',
  DuplicateDeposit = 'DUPLICATE_DEPOSIT',
}

export enum ValidationErrorCode {
  InvalidData = 'INVALID_DATA',
  InvalidDataRequired = 'INVALID_DATA_REQUIRED',
  InvalidDataLength = 'INVALID_DATA_LENGTH',
  InvalidDataMinlength = 'INVALID_DATA_MINLENGTH',
  InvalidDataMaxlength = 'INVALID_DATA_MAXLENGTH',
  InvalidDataValue = 'INVALID_DATA_VALUE',
  InvalidDataCurrency = 'INVALID_DATA_CURRENCY',
}

type ValidationFieldError<TCode, TValues = Record<string, never>> = {
  code: TCode
  values: { field: string } & TValues
}
export type ValidationError = { message: string } & (
  | { code: ValidationErrorCode.InvalidData }
  | ValidationFieldError<ValidationErrorCode.InvalidDataRequired>
  | ValidationFieldError<ValidationErrorCode.InvalidDataLength, { length: number }>
  | ValidationFieldError<ValidationErrorCode.InvalidDataMinlength, { minLength: number }>
  | ValidationFieldError<ValidationErrorCode.InvalidDataMaxlength, { maxLength: number }>
  | ValidationFieldError<ValidationErrorCode.InvalidDataValue>
  | ValidationFieldError<ValidationErrorCode.InvalidDataCurrency, { currency: Currency }>
)
export type ErrorData = { message: string } & (
  | {
      code: ErrorCode.NotFound
      status: 404
    }
  | {
      code: ErrorCode.InternalServerError
      status: 500
    }
  | {
      code: ErrorCode.MaintenanceMode
      status: 503
    }
  | {
      code: ErrorCode.RateLimitExceeded
      status: 429
    }
  | {
      code: ErrorCode.Unauthorized
      status: 401
    }
  | {
      code: ErrorCode.Forbidden
      status: 403
    }
  | {
      code: ErrorCode.InvalidData
      status: 400
      validationErrors: { [key: string]: ValidationError }
    }
  | {
      code: ErrorCode.InvalidDataQuery
      status: 400
    }
  | {
      code: ErrorCode.UnprocessableEntity
      status: 422
    }
  | {
      code: ErrorCode.AccountNotReady
      status: 425
    }
  | {
      code: ErrorCode.InvalidStateForInvoicePaid
      status: 422
    }
  | {
      code: ErrorCode.InvalidStateForInvoiceCancelled
      status: 422
    }
  | {
      code: ErrorCode.InvalidRecipient
      status: 422
    }
  | {
      code: ErrorCode.ProcessingPayment
      status: 422
    }
  | {
      code: ErrorCode.DuplicateInvoice
      status: 409
      values: { correlationId: string }
    }
  | {
      code: ErrorCode.SelfPaymentNotAllowed
      status: 422
    }
  | {
      code: ErrorCode.UserCurrencyUnavailable
      status: number
      values: { currency: Currency }
    }
  | {
      code: ErrorCode.LnUnavailable
      status: 422
    }
  | {
      code: ErrorCode.ExchangeRateNotAvailable
      status: 422
    }
  | {
      code: ErrorCode.BalanceTooLow
      status: 422
    }
  | {
      code: ErrorCode.InvalidAmount
      status: 422
    }
  | {
      code: ErrorCode.AmountTooHigh
      status: 422
      values: {
        limit: {
          currency: Currency
          amount: string
        }
      }
    }
  | {
      code: ErrorCode.AmountTooLow
      status: 422
      values: {
        limit: {
          currency: Currency
          amount: string
        }
      }
    }
  | {
      code: ErrorCode.PaymentMethodNotReady
      status: 422
    }
  | {
      code: ErrorCode.PayoutOriginatorNotApproved
      status: 422
    }
  | {
      code: ErrorCode.PayoutAlreadyInitiated
      status: 422
    }
  | {
      code: ErrorCode.InvalidStateForInvoiceExpired
      status: 422
    }
  | {
      code: ErrorCode.PaymentProcessed
      status: 422
      values: { paymentId: string }
    }
  | {
      code: ErrorCode.InvalidLnInvoice
      status: 422
    }
  | {
      code: ErrorCode.InvalidBitcoinAddress
      status: 422
    }
  | {
      code: ErrorCode.LnRouteNotFound
      status: 422
    }
  | {
      code: ErrorCode.PaymentQuoteExpired
      status: 422
    }
  | {
      code: ErrorCode.DuplicatePaymentQuote
      status: 422
      values: { paymentQuoteId: string }
    }
  | {
      code: ErrorCode.DuplicateCurrencyExchangeQuote
      status: 422
      values: { currencyExchangeQuoteId: string }
    }
  | {
      code: ErrorCode.CurrencyExchangeQuoteProcessed
      status: 422
    }
  | {
      code: ErrorCode.CurrencyExchangeQuoteExpired
      status: 422
    }
  | {
      code: ErrorCode.CurrencyExchangePairNotSupported
      status: 422
    }
  | {
      code: ErrorCode.CurrencyExchangeAmountTooLow
      status: 422
    }
  | {
      code: ErrorCode.DepositLimitExceeded
      status: 422
    }
  | {
      code: ErrorCode.DuplicateDeposit
      status: 422
      values: { depositId: string }
    }
)

export interface DebugError {
  full: string
  body?: string
}

export type ErrorResponse = {
  traceId?: string
  data: ErrorData
  debug?: DebugError
}

export const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const traceId = (obj as any).traceId
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = (obj as any).data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debug = (obj as any).debug

  return (
    (traceId === undefined || typeof traceId === 'string') &&
    typeof data === 'object' &&
    typeof data.message === 'string' &&
    typeof data.code === 'string' &&
    typeof data.status === 'number' &&
    (debug == undefined ||
      (typeof debug.full === 'string' &&
        (debug.body === undefined || typeof debug.body === 'string')))
  )
}

export const isStrikeApiError = (e: unknown): e is StrikeFetchError<ErrorResponse> =>
  isStrikeFetchErrorWithData(e, isErrorResponse)
