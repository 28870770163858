import type { TextareaProps as BaseTextAreaProps } from '@chakra-ui/react'
import { type FC } from 'react'

import { forwardRef, Textarea as BaseTextArea } from '@chakra-ui/react'
import { getBorderColor } from '../Input'

export type TextAreaVariant = 'normal' | 'modal'

export interface TextAreaProps extends BaseTextAreaProps {
  variant?: TextAreaVariant

  isInvalid?: boolean
  hasWarning?: boolean
}

export const TextArea: FC<TextAreaProps> = forwardRef<TextAreaProps, typeof BaseTextArea>(
  ({ children, hasWarning, variant, ...rest }, ref) => {
    const defaultVariant: TextAreaVariant = 'normal'

    return (
      <BaseTextArea
        variant={variant || defaultVariant}
        ref={ref}
        role="textbox"
        {...rest}
        sx={getBorderColor({ isInvalid: rest.isInvalid, hasWarning })}
      >
        {children}
      </BaseTextArea>
    )
  },
)
