import { useQuery, type UseQueryOptions } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { UserSettings } from '@strike-apps/commerce-dashboard/data-access'
import { USER_SETTINGS_QUERY } from './queries'

export const useUserSettings = (
  options?: UseQueryOptions<UserSettings, StrikeFetchError<ErrorDetails>>,
) => {
  return useQuery<UserSettings, StrikeFetchError<ErrorDetails>>(
    [USER_SETTINGS_QUERY],
    () => fetch<UserSettings>('/api/settings', { method: 'GET' }),
    options,
  )
}
