export { default as BitcoinIcon } from './bitcoin.svg'
export { default as DollarIcon } from './dollar.svg'
export { default as DollarSign } from './dollar-sign.svg'
export { default as EuroSign } from './euro-sign.svg'
export { default as PoundSign } from './pound-sign.svg'
export { default as EuroIcon } from './euro.svg'
export { default as PoundIcon } from './pound.svg'
export { default as GhanaiaCediIcon } from './ghanaia-cedi.svg'
export { default as KenyanShillingIcon } from './kenyan-shilling.svg'
export { default as NigerianNairaIcon } from './nigerian-naira.svg'
export { default as TetherIcon } from './tether.svg'
export { default as YenIcon } from './yen.svg'
export { default as IconSwitchGBP } from './icon-switch-gbp.svg'
export { default as IconSwitchEUR } from './icon-switch-eur.svg'
export { default as IconSwitchBTC } from './icon-switch-btc.svg'
export { default as IconSwitchUSD } from './icon-switch-usd.svg'
