import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { RoutingProgress, useFakeProgress } from '@strike-apps/shared/ui'

export function RoutingProgressBar() {
  const router = useRouter()
  const { progress, start, complete } = useFakeProgress()

  useEffect(() => {
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', complete)
    router.events.on('routeChangeError', complete)

    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', complete)
      router.events.off('routeChangeError', complete)
    }
  }, [complete, router.events, start])

  return <RoutingProgress progress={progress} />
}
