import { type OverridesDict } from '@strike-apps/shared/ui'
import { extendDefaultTheme } from '@strike-apps/shared/ui/theme'
import { strikeDiatype, strikeDiatypeMono } from '@strike-apps/shared/ui/fonts/next'
import type { ThemeConfig } from '@chakra-ui/react'
import { modalStyles as Modal } from './modal'

const config: ThemeConfig = {
  initialColorMode: 'dark',
}

const dashboardOverrides: OverridesDict = {
  config,
  styles: {
    global: {
      html: {},
    },
  },
  components: {
    Modal,
  },
}

export const dashboardTheme = extendDefaultTheme({
  ...dashboardOverrides,
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
      },
    },
  },
  fonts: {
    heading: strikeDiatype.style.fontFamily,
    body: strikeDiatype.style.fontFamily,
    mono: strikeDiatypeMono.style.fontFamily,
  },
})
