import { Flex, Icon, Spinner, VStack } from '@chakra-ui/react'
import { Number1Icon, Number2Icon, Number3Icon } from '@strike-apps/shared/icons'
import { InputClipboardCopy, Text } from '@strike-apps/shared/ui'
import { QRCodeSVG } from 'qrcode.react'
import { ReactComponent as QRError } from '../assets/qr-error.svg'

const ICONS = [Number1Icon, Number2Icon, Number3Icon]

type StepProps = {
  index: number
  text: string
  body: React.ReactNode
}
function Step({ index, text, body }: StepProps) {
  return (
    <Flex align="flex-start" gap={1}>
      <Icon as={ICONS[index]} mt="2px" boxSize={4} color="facePrimary" />
      <Flex direction="column">
        <Text variant="body3" color="facePrimary">
          {text}
        </Text>
        {body}
      </Flex>
    </Flex>
  )
}

export function Enable2FA({
  isLoading = false,
  secret,
  uri,
}: {
  isLoading?: boolean
  secret?: string
  uri?: string
}) {
  const size = 256
  return (
    <Flex direction="column" alignItems="flex-start">
      <Text variant="body3">Follow the steps below to add your authenticator app.</Text>
      <VStack mt={4} spacing={2} color="faceSecondary" align="flex-start">
        <Step
          index={0}
          text="Open your authenticator app"
          body={
            <Text variant="body3">
              If you don't have one, you can download Google Authenticator or Authy.
            </Text>
          }
        />
        <Step
          index={1}
          text="Scan the QR code"
          body={
            <Text variant="body3">Scan the QR code below or enter the setup key manually"</Text>
          }
        />
        <Step
          index={2}
          text="Enter the code"
          body={
            <Text variant="body3">
              Enter the 6-digit code from your authenticator app to complete setup.
            </Text>
          }
        />
      </VStack>
      <Flex
        direction="column"
        alignSelf="center"
        p={8}
        mt={8}
        borderRadius={12}
        borderStyle="solid"
        borderWidth="1px"
        borderColor="borderPrimary"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          bg="#FFFFFF"
          borderColor="borderPrimary"
          borderWidth="1px"
          borderRadius={12}
          alignSelf="center"
          p={7}
        >
          {isLoading && (
            <Flex bg="#FFFFFF" boxSize={size} align="center" justify="center">
              <Spinner color="#050505" />
            </Flex>
          )}
          {!isLoading && uri && <QRCodeSVG width={size} height={size} value={uri} />}
        </Flex>
      </Flex>
      {!isLoading && secret && (
        <Flex direction="column" alignSelf="stretch">
          <InputClipboardCopy
            mt={6}
            label="Setup key"
            inputProps={{
              borderColor: 'transparent',
              fontWeight: '500',
              letterSpacing: '0',
              fontFamily: 'mono',
              fontSize: '14px',
              textColor: 'faceTertiary',
            }}
            value={secret}
          />
        </Flex>
      )}
    </Flex>
  )
}

export function Enable2FAError() {
  const size = 256
  return (
    <Flex direction="column" alignItems="flex-start">
      <Text variant="body3">
        The setup key failed to generate. Please try again or contact support if the problem
        persists.
      </Text>

      <Flex
        direction="column"
        alignSelf="center"
        p={8}
        mt={8}
        borderRadius={12}
        borderStyle="solid"
        borderWidth="1px"
        borderColor="borderPrimary"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          bg="facePrimary"
          borderColor="transparent"
          borderRadius={12}
          alignSelf="center"
        >
          <QRError width={size} height={size} />
        </Flex>
      </Flex>
    </Flex>
  )
}
