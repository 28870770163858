import { TextArea, type TextAreaProps } from './TextArea'
import { useClipboard } from '@chakra-ui/react'
import { Box } from '../../layout/Box'
import { Button } from '../Button'
import { CopyIcon } from '@strike-apps/shared/icons'
import { Icon } from '@chakra-ui/react'

export interface TextAreaClipboardCopyProps extends TextAreaProps {
  value: string
}

export const TextAreaClipboardCopy = (props: TextAreaClipboardCopyProps) => {
  const { value } = props
  const { hasCopied, onCopy } = useClipboard(value)

  return (
    <Box position="relative" w="100%">
      <TextArea fontFamily="mono" fontWeight={400} {...props} isReadOnly resize="none" />

      <Box
        position="absolute"
        w="100%"
        h="100%"
        top="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        transitionProperty="common"
        transitionDuration="normal"
        opacity={0}
        _hover={{ opacity: 1 }}
        bg="specialModalOverlay"
      >
        <Button
          onClick={onCopy}
          size="sm"
          colorScheme="secondary"
          leftIcon={<Icon color={hasCopied ? 'facePositive' : 'facePrimary'} as={CopyIcon} />}
        >
          {hasCopied ? 'Copied' : 'Copy to clipboard'}
        </Button>
      </Box>
    </Box>
  )
}
