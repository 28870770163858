import { Box } from '@chakra-ui/react'

type CardProps = {
  children: React.ReactNode
}

export function Card({ children }: CardProps) {
  return (
    <Box
      mb={4}
      borderColor="borderPrimary"
      borderWidth="1px"
      borderRadius={16}
      px={4}
      py={6}
      bg="layerBackground"
    >
      {children}
    </Box>
  )
}
