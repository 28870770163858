import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import type { FC, SVGAttributes, ReactNode } from 'react'
import { Box } from '../../layout/Box'

export interface BorderTimerProps {
  children: ReactNode
  progressAsDecimal: number
  animationDuration: number
  size: number
  strokeWidth?: number
  isHidden?: boolean
}

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const AnimatedSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`

const Rect = styled.rect`
  stroke: orange;
`

const AnimatedRect = styled.rect`
  animation-name: ${dash};
  animation-timing-function: linear;
  animation-direction: normal;
  animation-fill-mode: forwards;

  stroke: white;
`

export const BorderTimer: FC<BorderTimerProps> = ({
  children,
  progressAsDecimal,
  animationDuration,
  size,
  strokeWidth = 4,
  isHidden = false,
}) => {
  const initialDashoffset = size * 4 * 0.96
  const commonSvgProps: SVGAttributes<SVGElement> = {
    width: size,
    height: size,
    viewBox: `0 0 ${size} ${size}`,
  }
  const commonRectProps: SVGAttributes<SVGRectElement> = {
    strokeWidth,
    width: size - strokeWidth,
    height: size - strokeWidth,
    x: strokeWidth / 2,
    y: strokeWidth / 2,
    fill: 'none',
    rx: 16,
  }

  return (
    <Box pos="relative">
      <Box pos="absolute" top={0} left="0" zIndex={1} hidden={isHidden}>
        <svg {...commonSvgProps}>
          <Rect {...commonRectProps} />
        </svg>
        <AnimatedSvg {...commonSvgProps}>
          <AnimatedRect
            {...commonRectProps}
            strokeDashoffset={progressAsDecimal * initialDashoffset}
            strokeDasharray={initialDashoffset}
            strokeWidth={strokeWidth + 2}
            style={{
              animationDuration: `${animationDuration}ms`,
            }}
          />
        </AnimatedSvg>
      </Box>
      {children}
    </Box>
  )
}
