import { Flex } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useWideModeBreakpoint } from './hooks'

export interface SidebarProps {
  children?: ReactNode
}

export const Sidebar: FC<SidebarProps> = ({ children }) => {
  const wideModeBreakpoint = useWideModeBreakpoint()

  return (
    <Flex direction="column" w="100%">
      <Flex
        direction="column"
        pt={{
          base: '24px',
          [wideModeBreakpoint]: '40px',
        }}
        pb="24px"
        px={2}
        gap={2}
      >
        {children}
      </Flex>
    </Flex>
  )
}
