import type { EnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import type { Session } from 'next-auth'

type PartialEnvSettings = Pick<EnvSettings, 'environment' | 'strikeIdentityServerUrl' | 'origin'>

export const getIdpLogoutUrl = (session: Session, envSettings: PartialEnvSettings) => {
  const { environment, strikeIdentityServerUrl, origin } = envSettings
  const params = new URLSearchParams({
    id_token_hint: session?.idToken || '',
    post_logout_redirect_uri: `${origin}/logout`,
  })

  if (environment === 'test') {
    // TODO: This can be removed after we will read the URL from openid-configuration
    return `${strikeIdentityServerUrl}/endsession?${params}`
  }

  return `${strikeIdentityServerUrl}/connect/endsession?${params}`
}
