import { formatCurrency } from '@strike-apps/shared/utils'
import type {
  CurrencyAmount,
  CycleLimit,
  LimitsSet,
  SpendableLimit,
} from '@strike-apps/strike/api-client'
import BigNumber from 'bignumber.js'
import currency from 'currency.js'

const countFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
})

type DepletableLimit = {
  total: string
  remaining: string
  percentage: number
}

type CycleDepletableLimit = DepletableLimit & {
  days: number
}

export function calcRemainingAndPercentage(used: string, total: string): DepletableLimit {
  const remaining = currency(total).subtract(used).toString()
  return {
    total,
    remaining,
    percentage: currency(remaining).divide(total).multiply(100).value,
  }
}

export function formatAmountLimit(
  limit: SpendableLimit<CurrencyAmount>,
  locale = 'en-US',
): DepletableLimit {
  const {
    used,
    limit: { amount: total, currency },
  } = limit
  const { remaining, percentage } = calcRemainingAndPercentage(used.amount, total)

  return {
    total: formatCurrency(
      {
        amount: total,
        currency,
      },
      locale,
    ).displayAmount,
    remaining: formatCurrency(
      {
        amount: remaining,
        currency,
      },
      locale,
    ).displayAmount,
    percentage,
  }
}

export function formatAmountCycles(
  limits: LimitsSet,
  locale = 'en-US',
): Array<CycleDepletableLimit> {
  if (limits?.amount?.cycles) {
    return limits.amount.cycles.map((cycleLimit) => {
      return {
        ...formatAmountLimit(cycleLimit, locale),
        days: cycleLimit.days,
      }
    })
  }
  return []
}

export function formatCountLimit(limit: SpendableLimit<number>): DepletableLimit {
  const remaining = limit.limit - limit.used
  const total = limit.limit
  return {
    remaining: formatCount(remaining, null),
    total: formatCount(total, null),
    percentage: currency(remaining).divide(total).multiply(100).value,
  }
}

export function formatCountCycleLimit(cycleLimit: CycleLimit<number>): CycleDepletableLimit {
  return {
    days: cycleLimit.days,
    ...formatCountLimit(cycleLimit),
  }
}

export function formatYearlyCountLimit(cycleLimit: SpendableLimit<number>): CycleDepletableLimit {
  return {
    days: 365,
    ...formatCountLimit(cycleLimit),
  }
}

export function formatCountCycleLimits(limits: LimitsSet): Array<CycleDepletableLimit> {
  if (limits?.count?.cycles) {
    return limits.count.cycles.map(formatCountCycleLimit)
  }
  return []
}

const findCycleLimit = (
  limits: LimitsSet,
  days: number,
): CycleLimit<CurrencyAmount> | undefined => {
  if (limits?.amount?.cycles) {
    return limits.amount.cycles.find((cycleLimit) => cycleLimit.days === days)
  }
  return undefined
}

export function shouldShowSingleTransactionLimit(limits: LimitsSet): boolean {
  const singleTxLimit = limits.amount?.single

  if (!singleTxLimit) {
    return false
  }

  const oneDayLimit = findCycleLimit(limits, 1)

  if (oneDayLimit) {
    const { remaining } = calcRemainingAndPercentage(
      oneDayLimit.used.amount,
      oneDayLimit.limit.amount,
    )

    // singleTx < oneDay.remaining condition
    return currency(remaining).subtract(singleTxLimit.amount).value > 0
  }

  // One day not present. Show single tx limit
  return true
}

export function formatSingleTransactionLimit(limit?: CurrencyAmount): string {
  if (!limit) {
    return ''
  }
  return `${formatCurrency(limit, 'en-US').displayAmount} per transaction`
}

function formatCount(count: number | string, days: number | null): string {
  if (days === null) {
    return countFormatter.format(BigNumber(count).toNumber())
  }
  return `${countFormatter.format(BigNumber(count).toNumber())} ${formatDayPeriod(days)}`
}

export function formatDayPeriod(days: number): string {
  if (days === 1) {
    return 'per day'
  }

  if (days === 7) {
    return 'per week'
  }

  if (days === 365) {
    return 'per year'
  }

  return `per ${days} days`
}

export function hasAmountLimits(limits?: LimitsSet): boolean {
  return Boolean(limits?.amount)
}

export function hasCountLimits(limits: LimitsSet): boolean {
  return Boolean(limits?.count)
}
