import type { FC } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { Flex, Box, Image, type BoxProps } from '@chakra-ui/react'

export interface QRCodeProps extends BoxProps {
  size: number
  value: string
  imageUrl?: string
  blurOverlay?: boolean
  blurProps?: BoxProps
  isBlurred?: boolean
}

export const QRCode: FC<QRCodeProps> = ({
  size,
  value,
  imageUrl,
  isBlurred,
  blurProps,
  blurOverlay = true,
  ...rest
}) => {
  return (
    <Flex
      position="relative"
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      bg="facePrimary"
      borderWidth={!isBlurred ? '4px' : 0}
      borderColor="transparent"
      borderRadius="20px"
      {...rest}
    >
      <Box {...(isBlurred ? blurProps : {})}>
        <QRCodeSVG size={size} value={value} style={{ filter: isBlurred ? 'blur(3px)' : 'none' }} />
      </Box>
      {imageUrl && (
        <Box position="absolute">
          <Image src={imageUrl} />
        </Box>
      )}
      {isBlurred && blurOverlay && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          bg="black"
          borderRadius="20px"
          opacity="0.6"
        />
      )}
    </Flex>
  )
}
