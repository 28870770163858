import { Currency, type Balance } from '@strike-apps/strike/api-client'

import { useBalanceQuery } from './useBalanceQuery'

interface Balances {
  status: string
  cashBalance?: Balance
  btcBalance?: Balance
}

export function useBalances(): Balances {
  const { status, data: balances } = useBalanceQuery()
  const cashBalance = balances?.find((b) =>
    [Currency.EUR, Currency.GBP, Currency.USD, Currency.USDT].includes(b.currency),
  )

  const btcBalance = balances?.find((b) => b.currency === Currency.BTC)

  return { status, cashBalance, btcBalance }
}
