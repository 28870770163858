import { Icon } from '@chakra-ui/react'
import { StrikeWordmarkIcon } from '@strike-apps/shared/icons'
import { Header } from './Header'

export const StrikeWordmarkHeader = () => {
  return (
    <Header>
      <Icon color="facePrimary" as={StrikeWordmarkIcon} height="10px" w="auto" />
    </Header>
  )
}
