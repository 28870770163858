import { useIsMounted } from './useIsMounted'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'

export const useDateFormat = (targetFormat = 'MM-dd-yyyy h:mm:ss aa') => {
  const isMounted = useIsMounted()

  return {
    formatDateTime: (date: string | Date) => {
      const enforcedDate = new Date(date)
      return isMounted && isValid(enforcedDate)
        ? // format DateTime values only on client side
          format(enforcedDate, targetFormat)
        : getDateAsString(date)
    },
  }
}

const getDateAsString = (date: string | Date) => {
  return typeof date === 'string' ? date : date.toString()
}
