import type { Dispatch, SetStateAction } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useIsMounted } from './useIsMounted'

declare type SetValue<T> = Dispatch<SetStateAction<T>>

/**
 * `useSafeLocalStorage` is a wrapper around `useLocalStorage` that should be used for conditional rendering along with SSR.
 */
export const useSafeLocalStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const isMounted = useIsMounted()
  const [value, setValue] = useLocalStorage(key, initialValue)
  return [isMounted ? value : initialValue, setValue]
}

export { useLocalStorage }
