import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { DepositFee, GetDepositFeeRequest } from '@strike-apps/strike/api-client'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import { useQuery } from '@tanstack/react-query'
import { GET_DEPOSIT_FEE_ESTIMATE } from './queries'

export const useEstimateDepositFee = (request: GetDepositFeeRequest) => {
  return useQuery<DepositFee, StrikeFetchError<ErrorDetails>>(
    [GET_DEPOSIT_FEE_ESTIMATE, request.paymentMethodId, request.feePolicy, request.amount],
    () =>
      fetch<DepositFee>('/api/payment-methods/fee', {
        method: 'POST',
        body: JSON.stringify(request),
        headers: { 'Content-Type': 'application/json' },
      }),
  )
}

export const useACHSettlementPeriod = (paymentMethodId: string | null) => {
  const { data, ...rest } = useQuery<DepositFee | null, StrikeFetchError<ErrorDetails>>(
    [GET_DEPOSIT_FEE_ESTIMATE, paymentMethodId],
    () => {
      if (!paymentMethodId) {
        return Promise.resolve(null)
      }

      return fetch<DepositFee>('/api/payment-methods/fee', {
        method: 'POST',
        body: JSON.stringify({
          paymentMethodId,
          amount: '1',
        }),
        headers: { 'Content-Type': 'application/json' },
      })
    },
  )

  return { ...rest, data: data?.settlementPeriodInDay }
}
