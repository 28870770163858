import { CardTitle } from './CardTitle'
import { Card } from './Card'

type LimitCardProps = {
  title: string
  children: React.ReactNode
}

export function LimitCard({ title, children }: LimitCardProps) {
  return (
    <>
      <CardTitle>{title}</CardTitle>
      <Card>{children}</Card>
    </>
  )
}
