import type { FC, ReactNode } from 'react'
import { AlertIcon } from '@strike-apps/shared/icons'
import { Text, type TextProps } from '../../typography/Text'
import { Icon } from '../../media-and-icons/Icon'
import { Box, type BoxProps } from '../../layout/Box'
import { Flex } from '../../layout/Flex'
import { Link, type LinkProps } from '@chakra-ui/react'

export interface AlertProps extends BoxProps {
  title?: string
  orientation?: 'horizontal' | 'vertical'
  messageType?: 'error' | 'warning' | 'success' | 'accent'
  variant?: 'border' | 'icon'
}

export const bordersStyleMap = {
  success: 'facePositive',
  accent: 'faceAccent',
  warning: 'faceWarn',
  error: 'faceNegative',
}

const bgStyleMap = {
  success: 'objectPositiveDisabled',
  accent: 'objectAccentDisabled',
  warning: 'objectWarnDisabled',
  error: 'objectNegativeDisabled',
}

export interface AlertBodyProps extends TextProps {
  children: ReactNode
}
const AlertBody: FC<AlertBodyProps> = ({ children, ...rest }) => {
  return (
    <Text variant="subheadline2" color="faceSecondary" {...rest}>
      {children}
    </Text>
  )
}

export interface AlertLinkProps extends LinkProps {
  children: ReactNode
  onClick?: () => void
}
const AlertLink: FC<AlertLinkProps> = ({ children, ...rest }) => {
  return (
    <Link textStyle="subline" {...rest}>
      {children}
    </Link>
  )
}

type AlertComponent = FC<AlertProps> & {
  Body: FC<AlertBodyProps>
  Link: FC<AlertLinkProps>
}

export const Alert: AlertComponent = ({
  children,
  orientation = 'horizontal',
  messageType = 'warning',
  title,
  variant = 'border',
  ...rest
}) => {
  return (
    <Box
      px="24px"
      py="12px"
      bg={bgStyleMap[messageType]}
      borderBottomWidth={1}
      borderBottomColor="borderSecondary"
      {...rest}
    >
      <Flex>
        <Box
          bg={variant === 'border' ? bordersStyleMap[messageType] : 'transparent'}
          w={1}
          borderRadius={2}
          mr={3}
        />

        <Flex
          flexDir={orientation === 'horizontal' ? 'row' : 'column'}
          alignItems="flex-start"
          gap={2}
        >
          {variant === 'icon' ? (
            <Flex alignItems="center" flexDir="row">
              <Icon as={AlertIcon} ml={-6} mr={2} color={bordersStyleMap[messageType]} />
              <Text variant="subheadline2" fontWeight="bold" color="facePrimary">
                {title}
              </Text>
            </Flex>
          ) : (
            <Text variant="subheadline2" fontWeight="bold" color="facePrimary">
              {title}
            </Text>
          )}

          {children}
        </Flex>
      </Flex>
    </Box>
  )
}

Alert.Body = AlertBody
Alert.Link = AlertLink
