import {
  Drawer as BaseDrawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader as BaseDrawerHeader,
  DrawerCloseButton,
  Flex,
} from '@chakra-ui/react'
import type { DrawerProps as BaseDrawerProps, ModalHeaderProps } from '@chakra-ui/react'
import { IconButton } from '../../forms/Button'
import { ChevronLeftIcon } from '@strike-apps/shared/icons'

export type DrawerProps = BaseDrawerProps

export const Drawer = ({ children, ...rest }: DrawerProps) => {
  return (
    <BaseDrawer placement="right" size="lg" {...rest}>
      <DrawerOverlay bg="rgba(43, 43, 43, 0.57)" backdropFilter="blur(4px)" />
      <DrawerContent
        minW={{
          base: '0',
          md: '480px',
        }}
        bg="layerBackground"
      >
        {children}
      </DrawerContent>
    </BaseDrawer>
  )
}

export interface DrawerHeaderProps extends ModalHeaderProps {
  onBack?: () => void
}

export const DrawerHeader = ({ children, onBack, ...rest }: DrawerHeaderProps) => {
  return (
    <BaseDrawerHeader position="relative" {...rest}>
      {onBack ? (
        <Flex position="absolute" left={6} top={0} h="100%" alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            variant="ghost"
            size="hug"
            aria-label="Back"
            onClick={onBack}
          />
        </Flex>
      ) : (
        <DrawerCloseButton />
      )}
      {children}
    </BaseDrawerHeader>
  )
}
