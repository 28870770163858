import {
  RadioGroup as BaseRadioGroup,
  type RadioGroupProps,
  Divider,
  VStack,
} from '@strike-apps/shared/ui'

export function RadioGroup({ value, onChange, children, ...rest }: RadioGroupProps) {
  return (
    <BaseRadioGroup
      onChange={onChange}
      value={value}
      px={4}
      bg="layerPrimary"
      borderRadius={12}
      borderWidth={1}
      borderColor="borderPrimary"
      {...rest}
    >
      <VStack
        divider={<Divider borderColor="borderPrimary" />}
        spacing={6}
        py={6}
        alignItems="flex-start"
      >
        {children}
      </VStack>
    </BaseRadioGroup>
  )
}
