import { useColorMode } from '@chakra-ui/system'
import { Button } from '@strike-apps/shared/ui'

export const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Button size="md" variant="outline" onClick={toggleColorMode}>
      {colorMode === 'dark' ? 'Light Theme' : 'Dark Theme'}
    </Button>
  )
}
