import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'

const variants = {
  h1: {
    fontSize: '60px',
    fontWeight: '700',
    lineHeight: '88px',
    letterSpacing: '0',
  },
  h2: {
    fontSize: '40px',
    fontWeight: '700',
    lineHeight: '60px',
    letterSpacing: '0',
  },
  h3: {
    fontSize: '34px',
    fontWeight: '700',
    lineHeight: '40px',
    letterSpacing: '0',
  },
  h4: {
    fontSize: '28px',
    fontWeight: '700',
    lineHeight: '36px',
    letterSpacing: '0',
  },
  h5: {
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: '0',
  },
}

export const headingStyles: ComponentSingleStyleConfig = {
  variants,
}

export type HeadingVariant = keyof typeof variants
