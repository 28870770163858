import { getBrowserLanguage, getNumberFormatter } from '@strike-apps/shared/utils'
import { useServerSide } from './useServerSide'

/**
 * Provides formatNumber function that allows to format numbers
 */
export const useNumberFormat = () => {
  const { isBrowser } = useServerSide()

  const formatter = getNumberFormatter(isBrowser ? getBrowserLanguage() : 'en')

  return {
    formatNumber: (amount: number): string | undefined => formatter.format(amount),
  }
}
