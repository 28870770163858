import type { Address } from '@strike-apps/strike/api-client'
import { Currency, getFormatter } from './currency'

export function formatLnInvoice(s: string, trailingLength: number) {
  if (s.length > trailingLength * 2) {
    return `${s.substring(0, trailingLength)}...${s.substring(s.length - trailingLength)}`
  }
  return s
}

export function formatRelativeTime(minutes: number) {
  if (minutes <= 0) {
    return 'now'
  }

  if (minutes < 60) {
    return minutes + ' mins'
  }

  if (minutes < 1440) {
    const hours = Math.floor(minutes / 60)
    return hours + ' hour' + (hours > 1 ? 's' : '')
  }

  const days = Math.floor(minutes / 1440)
  return days + ' day' + (days > 1 ? 's' : '')
}

export function removeNonDigits(s: string) {
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[^\d\.]+/g, '')
}

export function removeLeadingZeroes(s: string) {
  return s.replace(/^0+/g, '')
}

export function removeDashes(s?: string) {
  return s?.replace(/-/g, '') ?? ''
}

export function ensureOneDot(s: string) {
  if (s.includes('.')) {
    const [head, ...rest] = s.split('.')
    const tail = rest.join('')
    return `${head}.${tail.slice(0, 2)}`
  }
  return s
}

export function chunks(array: string[], chunkSize: number): string[][] {
  const results = []
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize))
  }
  return results
}

export function maskNumber(s: string, maxChunks?: number) {
  let cs = chunks(s.split(''), 3).map((s) => s.join(''))

  if (maxChunks) {
    cs = cs.slice(0, maxChunks)
  }

  return cs.join('-')
}

export function maskAccountNumber(accountNumber: string, visibleDigits = 4): string {
  const length = accountNumber.length
  const maskedPart = '----'
  const visiblePart = accountNumber.slice(length - visibleDigits)
  return `${maskedPart}  ${visiblePart}`
}

const usdFormatter = getFormatter(Currency.USD, 'en-US')

const eurFormatter = getFormatter(Currency.EUR, 'en-US')

const gbpFormatter = getFormatter(Currency.GBP, 'en-US')

const btcFormatter = getFormatter(Currency.BTC, 'en-US')

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export function formatAmountToCurrency(amount?: string, currency = Currency.USD): string {
  if (currency === Currency.USD || currency === Currency.USDT) {
    return usdFormatter.format(Number(amount ?? 0))
  }

  if (currency === Currency.EUR) {
    return eurFormatter.format(Number(amount ?? 0))
  }

  if (currency === Currency.GBP) {
    return gbpFormatter.format(Number(amount ?? 0))
  }

  if (currency === Currency.BTC) {
    return btcFormatter.format(Number(amount ?? 0)).replace(/\s*BTC\s*/, '₿')
  }

  console.error(
    `Currency '${currency}' did was not one of the expected values: USD, USDT, EUR, GBP or BTC`,
  )
  return `${currency} ${numberFormatter.format(Number(amount ?? 0))}`
}

export function formatAddress(address?: Address) {
  if (!address) return ''
  return `${address.line1}, ${address.city}, ${address.state ? address.state + ' ' : ''}${
    address.postCode
  }`
}
