export const Iframe = ({ htmlContent, title }: { htmlContent: string; title?: string }) => {
  const writeHTML = (frame: HTMLIFrameElement | null) => {
    if (!frame) {
      return
    }
    const doc = frame.contentDocument
    if (doc) {
      doc.open()
      doc.write('<base target="_blank"/>')
      doc.write(htmlContent)
      doc.close()
      frame.style.width = '100%'
      frame.onload = () => {
        frame.style.height = `${frame.contentWindow?.document.body.scrollHeight}px`
      }
    }
  }
  return <iframe title={title} src="about:blank" ref={writeHTML} />
}
