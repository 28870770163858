import {
  useField,
  useForm,
  type FieldProps,
  type FormApi,
  type FormController,
  type FormProps,
  type FormState,
  type InformedProps,
} from 'informed'

import { Box } from '@chakra-ui/react'
import { Warning } from '../../typography/Warning'
import { TextArea } from '../TextArea'
import { TextField, type TextFieldProps } from '../TextField'

export type StrikeFormProps = Omit<InformedProps<FormProps>, 'onSubmit'> & {
  onSubmit: (params: FormState, formApi: FormApi) => void
}

export function InformedForm({ children, onSubmit, ...rest }: StrikeFormProps) {
  const submitHandler = (params: FormState) => {
    if (onSubmit) {
      return onSubmit(params, getController().getFormApi())
    }
  }

  const { formController, render, userProps } = useForm({ onSubmit: submitHandler, ...rest })

  function getController(): FormController {
    return formController
  }

  return render(
    <form
      noValidate
      style={{ display: 'contents' }}
      {...(userProps as object)}
      onSubmit={formController.submitForm}
    >
      {children}
    </form>,
  )
}

interface UserProps {
  field: string
  clean?: (v: string) => string
  mask?: (v: string) => string
  parser?: (v: string) => string
}

export interface InformedTextFieldProps extends TextFieldProps {
  informed: Omit<FieldProps<UserProps>, 'name'>
  willShowError?: boolean
}

export function InformedTextField({
  type = 'text',
  willShowError = true,
  ...rest
}: InformedTextFieldProps) {
  const { fieldState, render, informed, userProps } = useField<UserProps, string>({
    type,
    name: rest.informed.field,
    ...rest.informed,
  })

  const { showError } = fieldState

  return render(
    showError && willShowError ? (
      <Box>
        <TextField {...rest} {...userProps} {...informed} />
        <Warning pt={2} ml={4} isInvalid={true}>
          {fieldState.error as string}
        </Warning>
      </Box>
    ) : (
      <TextField {...rest} {...userProps} {...informed} />
    ),
  )
}

export function InformedTextArea(props: InformedTextFieldProps) {
  const { render, informed, userProps } = useField({
    name: props.informed.field,
    type: 'text',
    ...props.informed,
  })
  return render(
    // @ts-expect-error Type
    <TextArea {...props} {...(userProps as object)} {...informed} />,
  )
}
