import { VStack } from '@chakra-ui/react'
import { Text, Divider } from '@strike-apps/shared/ui'

type LimitGroupProps = {
  title: string
  children: React.ReactNode
}

export const LimitGroup = ({ children, title }: LimitGroupProps) => {
  return (
    <>
      <Text variant="subheadline2" color="facePrimary" mb={3}>
        {title}
      </Text>
      <VStack gap={6} align="stretch">
        {children}
      </VStack>
    </>
  )
}

export const LimitGroupSeparator = () => <Divider borderColor="borderPrimary" my={6} />
