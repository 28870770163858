import { Text, Icon, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react'

import { InfoIcon } from '@strike-apps/shared/icons'

export interface InfoToastProps {
  message: string
}

export function InfoToast({ message }: InfoToastProps) {
  return (
    <Alert status="info" bg="objectPrimary" borderRadius={3}>
      <AlertIcon>
        <Icon color="objectSecondary" boxSize={5} as={InfoIcon} />
      </AlertIcon>
      <AlertTitle>
        <Text color="objectSecondary" variant="subheadline3">
          {message}
        </Text>
      </AlertTitle>
    </Alert>
  )
}
