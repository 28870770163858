import type { FC } from 'react'
import type { HeadingProps as BaseHeadingProps } from '@chakra-ui/react'
import { Heading as BaseHeading, forwardRef } from '@chakra-ui/react'
import type { HeadingVariant } from '../../../theme/components/heading'

export interface HeadingProps extends BaseHeadingProps {
  variant?: HeadingVariant
}

export const Heading: FC<HeadingProps> = forwardRef<HeadingProps, typeof BaseHeading>(
  ({ variant = 'h1', children, ...rest }, ref) => {
    return (
      <BaseHeading ref={ref} role="heading" as={variant} variant={variant} {...rest}>
        {children}
      </BaseHeading>
    )
  },
)
