import { useEffect, useState } from 'react'
import { useInterval } from './useInterval'

const INTERVAL_LENGTH_OFFLINE = 3 * 1000
const INTERVAL_LENGTH_ONLINE = 30 * 1000

export const useIsOnline = (pingResource: string) => {
  const [isOnline, setIsOnline] = useState(true)

  useInterval(
    () => {
      const fetchTestFile = async () => {
        const result = await fetch(pingResource, { cache: 'no-store' })
        const pingResult = result.status >= 200 && result.status < 300

        setIsOnline(pingResult)
      }

      fetchTestFile().catch(() => {
        setIsOnline(false)
      })
    },
    isOnline ? INTERVAL_LENGTH_ONLINE : INTERVAL_LENGTH_OFFLINE,
  )

  // only offline is reliable that we are connected to no internet or router
  // the online event can return false positives like you are
  // connected to a router but not to the internet
  useEffect(() => {
    const setToOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener('offline', setToOffline)
    return () => window.removeEventListener('offline', setToOffline)
  }, [])

  return isOnline
}
