import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
)

const baseStyle = definePartsStyle(() => {
  return {
    container: {
      width: 8,
    },
    thumb: {
      bg: 'facePrimaryDisabled',
      _checked: {
        //  bg: 'facePrimary',
        bg: '#FFFFFF',
        boxShadow: '0px 0.5px 0.5px rgba(0, 0, 0, 0.12)',
      },
    },
    track: {
      p: '1px',
      bg: 'objectSecondary',
      _checked: {
        bg: '#FFD4D4',

        //  bg: 'objectAccent',
      },
    },
  }
})

export const switchTheme = defineMultiStyleConfig({ baseStyle })
