import { type Currency, formatCurrency } from '@strike-apps/shared/utils'
import { useBitcoinUnit } from './useBitcoinUnit'
import { useLocale } from './useLocale'

export const useFormatCurrency = () => {
  const { data: bitcoinUnit } = useBitcoinUnit()
  const locale = useLocale()

  return (amount: string, currency: Currency, includeSymbol = true) => {
    return formatCurrency({ amount, currency }, locale, includeSymbol, bitcoinUnit)
  }
}
