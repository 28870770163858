import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useFeatureFlag } from './useFeatureFlag'

interface UseFeatureFlagRouteGuardOptions<T> {
  defaultValue: T
  expectedValue: T
  fallbackRoute?: string
}

export const useFeatureFlagRouteGuard = <T>(
  featureKey: string,
  { defaultValue, expectedValue, fallbackRoute = '/' }: UseFeatureFlagRouteGuardOptions<T>,
) => {
  const flagValue = useFeatureFlag<T>(featureKey, defaultValue)
  const router = useRouter()

  useEffect(() => {
    if (flagValue === expectedValue) {
      router.replace(fallbackRoute)
    }
  }, [router, flagValue, expectedValue, fallbackRoute])
}
