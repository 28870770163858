import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { inputStyles, fieldBaseStyles } from './input'

export const numberInputStyles: ComponentMultiStyleConfig = {
  parts: ['root', 'field'],

  baseStyle: {
    root: {
      width: '100%',
    },
    field: {
      ...fieldBaseStyles,
    },
  },

  sizes: inputStyles.sizes,
  variants: inputStyles.variants,
}
