import { useCallback, useState } from 'react'

import { useInterval } from './useInterval'

const TIMER_DELAY = 1000

export interface UseTimerHook {
  isRunning: boolean
  restart: (newExpireAt: Date) => void
  cancel: () => void
}

/**
 * Returns if timer is running, cancel / restart callbacks to control the timer.
 * @param expireAt Expiration time, when timer should stop. (null for no expiration time)
 */
export const useTimer = (expireAt: Date | null, onTick?: () => void): UseTimerHook => {
  const [isRunning, setIsRunning] = useState(true)
  const [expiration, setExpiration] = useState<Date | null>(expireAt)
  const [delay, setDelay] = useState<number | null>(TIMER_DELAY)

  // Setting a delay will periodically ticks and checks for expiration time
  // If expiration runs out, it will stop
  useInterval(() => {
    const current = new Date()

    if (onTick) {
      onTick()
    }

    if (expiration && current >= expiration) {
      setIsRunning(false)
      setDelay(null)
    }
  }, delay)

  // Will set new expiration time and restart interval for expiration check
  const restartTimer = useCallback((newExpireAt: Date) => {
    setIsRunning(true)
    setExpiration(newExpireAt)
    setDelay(TIMER_DELAY)
  }, [])

  // Stops expiration interval check
  const cancelTimer = useCallback(() => {
    setIsRunning(false)
    setExpiration(null)
    setDelay(null)
  }, [])

  return {
    isRunning,
    cancel: cancelTimer,
    restart: restartTimer,
  }
}
