import { Flex, Icon, Button } from '@strike-apps/shared/ui'
import { Alert as BaseAlert, AlertDescription, AlertTitle, useDisclosure } from '@chakra-ui/react'
import { InfoIcon } from '@strike-apps/shared/icons'

export type AlertProps = {
  title: React.ReactNode
  description?: React.ReactNode
  actionText?: string
  icon?: React.FC
  onAction?: () => void
  onDismiss?: () => void
}

export function Alert({
  title,
  description,
  actionText,
  icon = InfoIcon,
  onAction,
  onDismiss,
}: AlertProps) {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  return isVisible ? (
    <BaseAlert
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      alignItems="center"
      bg="layerPrimary"
      gap={{
        base: 3,
        md: 0,
      }}
    >
      <Icon
        as={icon}
        alignSelf={{
          base: 'center',
          md: 'flex-start',
        }}
        mb={{
          base: 1,
          md: 0,
        }}
        mr={2}
        mt={1}
        color="facePrimary"
      />
      <Flex
        direction="column"
        alignItems={{
          base: 'center',
          md: 'flex-start',
        }}
      >
        <AlertTitle>{title}</AlertTitle>
        {description && (
          <AlertDescription
            textAlign={{
              base: 'center',
              md: 'left',
            }}
          >
            {description}
          </AlertDescription>
        )}
      </Flex>
      <Flex
        ml={{
          base: 0,
          md: 'auto',
        }}
        gap={2}
      >
        {onAction && (
          <Button colorScheme="secondary" size="sm" onClick={onAction}>
            {actionText}
          </Button>
        )}
        {onDismiss && (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              onClose()
              onDismiss()
            }}
          >
            Dismiss
          </Button>
        )}
      </Flex>
    </BaseAlert>
  ) : null
}
