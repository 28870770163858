export {
  Thead,
  Tbody,
  Tr,
  TableCaption,
  Tfoot,
  type TableHeadProps,
  type TableBodyProps,
  type TableRowProps,
  type TableCaptionProps,
  type TableFooterProps,
} from '@chakra-ui/react'

export * from './Table'
export * from './Th'
export * from './Td'
export * from './TableContainer'
