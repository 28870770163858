import type { FC, ReactNode, ReactElement } from 'react'
import type { FormControlProps as BaseFormControlProps, FormLabelProps } from '@chakra-ui/react'
import { FormControl as BaseFormControl, FormLabel, forwardRef } from '@chakra-ui/react'
import { Badge } from '../../data-display/Badge'
import { Label, type LabelVariant } from '../Label'

export interface FormControlProps extends Omit<BaseFormControlProps, 'label'> {
  label: ReactNode
  labelProps?: FormLabelProps

  isInvalid?: boolean
  hasWarning?: boolean

  message?: ReactNode
  messageIcon?: ReactElement
  messageAboveInput?: boolean
}

const RequiredIndicator = () => (
  <Badge
    variant="warning"
    style={{
      position: 'absolute',
      top: '1px',
      right: 0,
      textTransform: 'none',
    }}
  >
    Required
  </Badge>
)

export const FormControl: FC<FormControlProps> = forwardRef<
  FormControlProps,
  typeof BaseFormControl
>(
  (
    {
      label,
      children,
      message,
      messageIcon,
      labelProps,
      hasWarning,
      messageAboveInput = false,
      ...rest
    },
    ref,
  ) => {
    const FormMessageComponent = () => (
      <Label
        variant={getMessageType({ isInvalid: rest.isInvalid, hasWarning })}
        leftIcon={messageIcon}
        mt="8px"
      >
        {message}
      </Label>
    )
    return (
      <BaseFormControl ref={ref} {...rest}>
        <FormLabel requiredIndicator={<RequiredIndicator />} {...labelProps}>
          {label}
        </FormLabel>
        {message && messageAboveInput && <FormMessageComponent />}
        {children}
        {message && !messageAboveInput && <FormMessageComponent />}
      </BaseFormControl>
    )
  },
)

const getMessageType = ({
  isInvalid,
  hasWarning,
}: Pick<FormControlProps, 'isInvalid' | 'hasWarning'>): LabelVariant => {
  if (isInvalid) return 'error'
  if (hasWarning) return 'warning'

  return 'reminder'
}
