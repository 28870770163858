import { isGDPRCountry } from './gdpr'
import type { GetServerSidePropsContext } from 'next'
import type { IncomingMessage } from 'http'
/**
 * getRegionFromCountryCode  get region from country code
 * @param countryCode country code
 * @returns `europe` for GDPR EU countries, `uk`, `us`, or the country code
 */
export const getRegionFromCountryCode = (countryCode: string): 'europe' | 'uk' | 'us' | string => {
  if (!countryCode) {
    return ''
  }

  return isGDPRCountry(countryCode.toLowerCase()) ? 'europe' : countryCode
}

export function getCountryFromReq(req?: IncomingMessage) {
  const countryHeader = req?.headers['x-country']
  const result = Array.isArray(countryHeader) ? countryHeader[0] : countryHeader

  if (!result) {
    return 'us'
  }

  return result.toLocaleLowerCase()
}

export function getCountryFromServerSideProps(ctx: GetServerSidePropsContext) {
  return getCountryFromReq(ctx.req)
}
