// Forked & modified from here
// https://github.com/remark-embedder/transformer-oembed
// Main modifications:
// Replace make-fetch-happen with node-fetch
// Embed oembed.json directly instead of fetching it
// Add wrappers around embedded iframes with classes to allow better styling
// Oembed in oembed.json was take from here https://oembed.com/providers.json
import type { Transformer } from '@remark-embedder/core'
import fetch from 'node-fetch'
import { URL } from 'url'
import providers from './oembed.json'

type Provider = {
  provider_name: string
  provider_url: string
  endpoints: Array<{
    schemes?: string[]
    discovery?: boolean
    url: string
  }>
}
type Providers = Array<Provider>

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace getProviders {
  let cache: Providers | undefined
}

function getProviders(): Providers {
  return providers
}

// TODO: Support providers that do not have schemes
function getProviderEndpointURLForURL(
  url: string,
): { provider: Provider; endpoint: string } | null {
  const providers = getProviders()
  for (const provider of providers) {
    for (const endpoint of provider.endpoints) {
      if (endpoint.schemes?.some((scheme) => new RegExp(scheme.replace(/\*/g, '(.*)')).test(url))) {
        return { provider, endpoint: endpoint.url }
      }
    }
  }
  return null
}

type Config = {
  params?: { [key: string]: unknown }
}

type GetConfig = ({
  url,
  provider,
}: {
  url: string
  provider: Provider
}) => Config | null | undefined

type OEmbedData = {
  html: string
}

const transformer: Transformer<Config | GetConfig> = {
  name: '@remark-embedder/transformer-oembed',
  shouldTransform: async (url) => {
    const result = getProviderEndpointURLForURL(url)
    return Boolean(result)
  },
  getHTML: async (urlString, getConfig = {}) => {
    const result = getProviderEndpointURLForURL(urlString)

    if (!result) return null

    const { provider, endpoint } = result

    const url = new URL(endpoint)
    url.searchParams.set('url', urlString)

    let config: Config = getConfig as Config
    if (typeof getConfig === 'function') {
      config = getConfig({ url: urlString, provider }) ?? {}
    }

    for (const [key, value] of Object.entries(config.params ?? {})) {
      url.searchParams.set(key, String(value))
    }
    // format has to be json so it is not configurable
    url.searchParams.set('format', 'json')
    const res = await fetch(url.toString())
    const data: OEmbedData = await res.json()

    return `<div class="${provider.provider_name} embedded-content">${data.html}</div>`
  },
}

export default transformer
type ExportedConfig = Config | GetConfig
export type { ExportedConfig as Config }
