import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'
import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'
import { capitalize } from 'lodash-es'
import { textStyles } from '../foundations/textStyles'

const baseStyle = defineStyle({
  fontWeight: 500,
  outlineColor: 'transparent',

  _focus: {
    boxShadow: 'none',
    outlineColor: 'objectAccent',
  },

  _active: {
    outlineColor: 'transparent',
  },

  _disabled: {
    pointerEvents: 'none',
  },
})

const sizes = {
  lg: defineStyle({
    height: '56px',
    minW: '114px',
    p: '18px 24px',
    borderRadius: '12px',
    ...textStyles.button1,
  }),
  md: defineStyle({
    height: '48px',
    minW: '98px',
    p: '14px 16px',
    borderRadius: '12px',
    ...textStyles.button1,
  }),
  sm: defineStyle({
    height: '40px',
    minW: '88px',
    p: '12px 16px',
    borderRadius: '12px',
    ...textStyles.button2,
  }),
  cd: defineStyle({
    height: '24px',
    minW: '24px',
    px: '8px',
    borderRadius: '8px',
    ...textStyles.caption,
  }),
  hug: defineStyle({
    borderRadius: '8px',
  }),
}

const solidVariant = defineStyle((props) => {
  const { colorScheme } = props
  const tokenScheme = capitalize(colorScheme)
  const useAlternateColor = colorScheme === 'primary' || colorScheme === 'accent'

  return {
    color: useAlternateColor ? 'facePrimaryAlt' : 'facePrimary',
    bg: `object${tokenScheme}`,

    _hover: {
      bg: `object${tokenScheme}Hover`,
    },
    _active: {
      bg: `object${tokenScheme}Pressed`,
      color: `facePrimaryPressed`,
    },
    _disabled: {
      bg: 'objectPrimaryDisabled',
      color: 'facePrimaryDisabled',
      opacity: 1,
    },
  }
})

const outlineVariant = defineStyle((props) => {
  const { colorScheme } = props
  const tokenScheme = capitalize(colorScheme)

  return {
    color: `face${tokenScheme}`,
    bg: 'transparent',
    borderWidth: '1px',
    borderColor: `object{tokenScheme}`,

    _hover: {
      color: `face${tokenScheme}Hover`,
      borderColor: `object${tokenScheme}Hover`,
    },
    _active: {
      color: `face${tokenScheme}Pressed`,
      bg: `object${tokenScheme}Pressed`,
      borderColor: 'transparent',
    },
    _disabled: {
      borderColor: 'transparent',
      background: 'objectPrimaryDisabled',
      color: 'facePrimaryPressed',
      opacity: 1,
    },
  }
})

const ghostVariant = defineStyle((props) => {
  const { colorScheme } = props
  const tokenScheme = capitalize(colorScheme)

  return {
    color: `face${tokenScheme}`,

    _hover: {
      color: `face${tokenScheme}Hover`,
    },
    _active: {
      color: `face${tokenScheme}Pressed`,
      outlineColor: 'transparent',
    },
    _disabled: {
      background: `transparent`,
      color: `face${tokenScheme}DisabledAlt`,
      opacity: 1,
    },
  }
})

const simpleVariant = defineStyle((props) => {
  const { colorScheme } = props
  const tokenScheme = capitalize(colorScheme)

  return {
    color: `face${tokenScheme}`,

    _hover: {
      color: `object${tokenScheme}Hover`,
    },
    _active: {
      color: `object${tokenScheme}Pressed`,
    },
    _focus: {
      boxShadow: 'none',
      outlineColor: 'none',
    },
    _disabled: {
      color: `face${tokenScheme}Disabled`,
    },
  }
})

const variants = {
  solid: solidVariant,
  outline: outlineVariant,
  ghost: ghostVariant,
  simple: simpleVariant,
}

export const buttonStyles: ComponentSingleStyleConfig = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    colorScheme: 'primary',
  },
})
