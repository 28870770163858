import { isGDPRCountry } from '@strike-apps/shared/utils'
import { useEffect, useState } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import getConfig from './CookieConsentConfig'
import addEventListeners from './CookieConsentListeners'
import './styles.css'

/**
 * This example uses custom event listeners.
 * You can use either event listeners or callback functions:
 * https://cookieconsent.orestbida.com/advanced/callbacks-events.html
 */

export const ResetCookieConsent = () => {
  CookieConsent.reset(true)
  CookieConsent.run(getConfig())
}

type Consent = {
  isBugsnagAllowed: boolean
  isGAAllowed: boolean
}

export function useCookieConsent(country: string | undefined | null) {
  const [consent, setConsent] = useState<Consent>(() => {
    if (!country || isGDPRCountry(country)) {
      return { isBugsnagAllowed: false, isGAAllowed: false }
    }
    return { isBugsnagAllowed: true, isGAAllowed: true }
  })

  function updateConsent() {
    const preferences = CookieConsent.getUserPreferences()
    setConsent({
      isBugsnagAllowed: isBugsnagAllowed(preferences),
      isGAAllowed: isGAAllowed(preferences),
    })
  }

  useEffect(() => {
    if (country && isGDPRCountry(country)) {
      addEventListeners({
        onChange: updateConsent,
      })
      CookieConsent.run(getConfig()).then(updateConsent)
    }
  }, [country])

  return consent
}

function isBugsnagAllowed(preferences: CookieConsent.UserPreferences) {
  if (!preferences) return false
  return (
    preferences.acceptType === 'all' ||
    preferences.acceptedServices['analytics']?.includes('bugsnag')
  )
}

function isGAAllowed(preferences: CookieConsent.UserPreferences) {
  if (!preferences) return false
  return (
    preferences.acceptType === 'all' || preferences.acceptedServices['analytics']?.includes('ga')
  )
}
