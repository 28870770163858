import type { FC, ReactNode } from 'react'
import { Page } from '@strike-apps/shared/ui'

interface DefaultLayoutProps {
  children: ReactNode
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  return <Page>{children}</Page>
}
