import type { FC, LegacyRef } from 'react'
import type { TextProps as BaseTextProps } from '@chakra-ui/react'
import { forwardRef, Text as BaseText } from '@chakra-ui/react'
import type { TextVariant } from '../../../theme/foundations/textStyles'

export type TextProps = BaseTextProps & {
  variant?: TextVariant
  ref?: LegacyRef<HTMLParagraphElement>
}

export const Text: FC<TextProps> = forwardRef<TextProps, typeof BaseText>(
  ({ variant = 'body1', children, ...rest }, ref) => {
    return (
      <BaseText ref={ref} textStyle={variant} {...rest}>
        {children}
      </BaseText>
    )
  },
)
