import { StrikeFetchError } from './error'

export const getResponseData = async (response: Response) => {
  let responseData

  // get data from response
  if (response.headers.get('content-type')?.includes('application/json')) {
    responseData = await response.json()
  } else {
    responseData = await response.text()
  }

  return responseData
}

export const strikeFetch = async <TData = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<TData> => {
  const response = await fetch(input, init)

  const responseData = await getResponseData(response)

  // throw response if status code is not ok
  if (!response.ok) {
    throw new StrikeFetchError(response, responseData)
  }

  // return success response
  return responseData as TData
}

export const strikeFetchRaw = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const response = await fetch(input, init)

  if (response.ok) {
    return response
  }

  const responseData = await getResponseData(response)
  throw new StrikeFetchError(response, responseData)
}
