import { type FC } from 'react'

import type { CheckboxProps as BaseCheckboxProps } from '@chakra-ui/react'
import { Checkbox as BaseCheckbox } from '@chakra-ui/react'

export { CheckboxGroup } from '@chakra-ui/react'

export type CheckboxVariant = 'normal' | 'modal'

export interface CheckboxProps extends BaseCheckboxProps {
  disabled?: boolean
  variant?: CheckboxVariant
}

export const Checkbox: FC<CheckboxProps> = ({ variant, ...rest }) => {
  const defaultVariant = 'normal'
  return <BaseCheckbox variant={variant || defaultVariant} {...rest} />
}
