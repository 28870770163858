import { Grid, type GridProps } from '@strike-apps/shared/ui'
import { GridConfig, GridGap, MaxGridSize } from '../grid'

export function PageGrid(props: GridProps) {
  return (
    <Grid
      width={{
        base: '100%',
      }}
      justifyContent={{
        xl: 'center',
      }}
      gridAutoRows="min-content"
      templateColumns={GridConfig}
      columnGap={GridGap}
      rowGap={GridGap}
      maxW={MaxGridSize}
      {...props}
    />
  )
}
