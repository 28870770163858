import { Box, Flex, type BoxProps, useStyleConfig, type FlexProps } from '@chakra-ui/react'
import type { FC } from 'react'

export type CardSize = 'md' | 'lg'

export interface CardProps extends BoxProps {
  size?: CardSize
  header?: React.ReactNode
  footer?: React.ReactNode
  contentProps?: FlexProps
}

export const Card: FC<CardProps> = ({
  header,
  footer,
  children,
  size = 'md',
  contentProps,
  ...rest
}) => {
  const styles = useStyleConfig('Card', { size })

  return (
    <Box __css={styles} {...rest}>
      {header && (
        <Flex pb="24px" w="100%" justifyContent="center" alignItems="center" gap="6px">
          {header}
        </Flex>
      )}

      <Flex flex={1} flexDir="column" gap="24px" w="100%" alignItems="center" {...contentProps}>
        {children}
      </Flex>

      {footer && (
        <Flex pt="32px" w="100%" gap="16px" flexDirection="column">
          {footer}
        </Flex>
      )}
    </Box>
  )
}
