import { useRouter } from 'next/navigation'

export const usePersistQueryRouter = () => {
  const router = useRouter()

  const pushWithPersistQuery = (href: string, options?: object) => {
    router.push(href + window.location.search, options)
  }

  const replaceWithPersistQuery = (href: string, options?: object) => {
    router.replace(href + window.location.search, options)
  }

  return {
    ...router,
    push: pushWithPersistQuery,
    replace: replaceWithPersistQuery,
  }
}
