import {
  Flex,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  useClipboard,
  type InputGroupProps,
} from '@chakra-ui/react'
import { CheckIcon, CopyIcon } from '@strike-apps/shared/icons'
import { formatLnInvoice } from '@strike-apps/shared/utils'
import { useInfoToast } from '../../../hooks'
import { TextField } from './TextField'
import { type InputProps } from '../Input'

export interface TextFieldClipboardCopyProps extends InputGroupProps {
  value: string
  trailingLength?: number
  inputProps?: InputProps
  infoMessage?: string
  label?: string
}

export const TextFieldClipboardCopy = (props: TextFieldClipboardCopyProps) => {
  const { value, label, inputProps, trailingLength, infoMessage, ...rest } = props
  const toast = useInfoToast()
  const { hasCopied, onCopy } = useClipboard(value)

  const copy = function () {
    if (infoMessage) {
      toast.showToast({ message: infoMessage })
    }
    onCopy()
  }

  return (
    <InputGroup as={Flex} {...rest} justify="center">
      <TextField
        value={trailingLength ? formatLnInvoice(value, trailingLength) : value}
        label={label}
        isReadOnly
        onClick={copy}
        cursor="pointer"
        {...inputProps}
      />
      <InputRightElement boxSize="64px" mt={3}>
        <IconButton
          bg={hasCopied ? 'objectPositive' : 'layerPrimary'}
          aria-label="Copy to clipboard"
          icon={<Icon as={hasCopied ? CheckIcon : CopyIcon} boxSize={4} />}
          colorScheme={hasCopied ? 'positive' : 'secondary'}
          onClick={copy}
          size="cd"
          boxSize="36px"
          isDisabled={inputProps?.isDisabled}
        />
      </InputRightElement>
    </InputGroup>
  )
}
