import React, { type ErrorInfo } from 'react'
import Bugsnag from '@bugsnag/js'
import { GenericErrorPage } from './GenericErrorPage'

interface LocalErrorBoundaryProps {
  children: React.ReactNode
}

interface LocalErrorBoundaryState {
  hasError: boolean
}

export class LocalErrorBoundary extends React.Component<
  LocalErrorBoundaryProps,
  LocalErrorBoundaryState
> {
  constructor(props: LocalErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): LocalErrorBoundaryState {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Bugsnag.notify(error, (event) => {
      event.addMetadata('errorInfo', {
        componentStack: errorInfo.componentStack,
      })
    })
  }

  override render() {
    if (this.state.hasError) {
      return <GenericErrorPage />
    }

    return this.props.children
  }
}
