import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useFeatureFlagsContext } from '../components/FeatureFlagsContext'

export const useFeatureFlag = <T>(featureKey: string, defaultValue: T): T => {
  const { options } = useFeatureFlagsContext()
  const client = useLDClient()

  if (options?.bootstrap && typeof options?.bootstrap === 'object') {
    return client?.variation(featureKey) ?? options?.bootstrap[featureKey] ?? defaultValue
  }

  return client?.variation(featureKey) ?? defaultValue
}
