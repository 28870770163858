import { Progress, type ProgressProps } from '@chakra-ui/react'

export type ProgressBarProps = ProgressProps

export function ProgressBar(props: ProgressProps) {
  return (
    <Progress
      variant={props.value === 100 ? 'progressBarFull' : 'progressBar'}
      size="xs"
      width="100%"
      {...props}
    />
  )
}
