import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const accordionStyles: ComponentMultiStyleConfig = {
  parts: ['container', 'button'],

  baseStyle: {
    container: {
      borderColor: 'borderPrimary',
    },
    button: {
      justifyContent: 'space-between',
      py: 3,
      px: 0,
    },
  },
}
