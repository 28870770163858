// NX produces an error if we try to import a json file directly from a library
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import countriesJson from 'country-region-data/data.json'

export type CountryItem = {
  name: string
  shortCode?: string
}
export type CountryStateItem = CountryItem

const SANCTIONED_COUNTRIES = new Set(['Cuba', 'Iran, Islamic Republic of'])

const countryNames: CountryItem[] = countriesJson
  .map(({ countryName, countryShortCode }) => ({ name: countryName, shortCode: countryShortCode }))
  .filter((c) => !SANCTIONED_COUNTRIES.has(c.name))

const statesByCountry = countriesJson.reduce((acc, { countryName, countryShortCode, regions }) => {
  const states =
    regions?.map(({ name, shortCode }: CountryItem) => ({
      name,
      shortCode,
    })) ?? []

  return {
    ...acc,
    [countryShortCode ?? countryName]: states,
  }
}, {} as Record<string, CountryStateItem[]>)

export function useCountries(): CountryItem[] {
  return countryNames
}

const emptySet: Set<string> = new Set([])

export function useStates(country?: string, forbiddenStates = emptySet): CountryItem[] {
  return country ? statesByCountry[country].filter((s) => !forbiddenStates.has(s.name)) : []
}
