import { Flex } from '@chakra-ui/react'
import { useLimitsQuery } from '@strike-apps/commerce-dashboard/hooks'
import { formatAmountCycles, formatDayPeriod, hasAmountLimits } from '@strike-apps/strike/limits'

import { Limit, TextLimit } from '../Limit'
import { LimitCard } from '../LimitCard'
import { LimitGroupSeparator } from '../LimitGroup'

export function USLimits() {
  const { data } = useLimitsQuery()
  const limitData = data?.achDeposit
  return (
    <Flex direction="column" flex={1}>
      <LimitCard title="Send & withdraw">
        <TextLimit caption="Send" limit="Unlimited for available balance" />
        <LimitGroupSeparator />
        <TextLimit caption="Withdraw" limit="Unlimited for available balance" />
      </LimitCard>

      <LimitCard title="Deposit">
        {limitData &&
          hasAmountLimits(limitData) &&
          formatAmountCycles(limitData).map((cycle, index) => {
            const { remaining, total, percentage, days } = cycle
            return (
              <Limit
                caption="Bank deposit"
                key={index}
                leftText={`${remaining} remaining`}
                rightText={`${total} ${formatDayPeriod(days)}`}
                value={percentage}
              />
            )
          })}
        <LimitGroupSeparator />
        <TextLimit caption="Wire transfer" limit="Unlimited" />
      </LimitCard>

      <LimitCard title="Trade">
        <TextLimit caption="Buy & sell" limit="Unlimited" />
      </LimitCard>
    </Flex>
  )
}
