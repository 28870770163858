export class MissingConfigError extends Error {
  constructor(public override name: string) {
    super(`${name} config missing.`)
  }
}

/**
 * Used to dynamically read values from env variables based on the current env.
 * Needed because secrets are set via Netlify UI and there is no option there to
 * set env variable for specific environment, so instead, for each secret we are
 * creating one env variable per environment with name <VAR_NAME>_<ENV>, so if we have
 * STRIKE_API_KEY env variable and next and live envs, in the Netlify UI we'd set
 * STRIKE_API_KEY_NEXT and STRIKE_API_KEY_LIVE variables. If the variable for the
 * given environment is not set, it will look it up just by the <VAR_NAME> instead.
 *
 * @deprecated The method should not be used since Netlify now can handle env vars
 * properly. Instead, please set the env var for desired env only in the Netlify UI
 * and use getFromEnvOrThrow instead
 */
export const getFromConfigOrThrow = (varName: string, env: string): string => {
  const uppercaseEnv = env.toUpperCase()
  const envSpecificValue = process.env[`${varName}_${uppercaseEnv}`]

  return envSpecificValue ?? process.env[varName] ?? throwMissingConfig(varName)
}

export const getFromEnvOrThrow = (varName: string): string => {
  return process.env[varName] ?? throwMissingConfig(varName)
}

export function throwMissingConfig(varName: string): never {
  throw new MissingConfigError(varName)
}
