import rehypeStringify from 'rehype-stringify'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import remarkEmbedder from '@remark-embedder/core'
import oembedTransformer from './oembed'
import { unified } from 'unified'

import rehypeIcon, { type TagProperties, type TagTransformer } from './rehypeCustomTag'

export { type TagTransformer }
export { type TagProperties }

export async function markdownToHtml(markdown: string, tagTransformer: TagTransformer) {
  const result = await unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkEmbedder, {
      transformers: [oembedTransformer],
    })
    .use(remarkRehype, {
      allowDangerousHtml: true,
    })
    .use(rehypeIcon, { tagTransformer })
    .use(rehypeStringify, {
      allowDangerousHtml: true,
    })
    .process(markdown)

  return String(result)
}
