import { Flex, type FlexProps } from '@chakra-ui/react'
import { StrikeWordmarkIcon } from '@strike-apps/shared/icons'

import { Icon } from '../../media-and-icons/Icon'
import { Text } from '../../typography/Text'

export type FooterVariant = 'centered' | 'spread' | 'left' | 'right'

export interface StrikeFooterProps extends FlexProps {
  variant?: FooterVariant
  copyrightNotice?: string
}

export function StrikeFooter({
  variant = 'centered',
  copyrightNotice = '© 2023',
  children,
}: StrikeFooterProps) {
  return (
    <Flex
      p={{
        base: 2,
        md: 8,
      }}
      w="100%"
      h={20}
      alignItems="center"
      justifyContent={
        variant === 'centered'
          ? 'center'
          : variant === 'left' || variant === 'spread'
          ? 'flex-start'
          : 'flex-end'
      }
    >
      {variant === 'right' && (
        <Flex alignItems="center" mr="auto">
          {children}
        </Flex>
      )}
      <Flex
        alignItems="center"
        flexDirection={variant === 'centered' ? 'column' : 'row'}
        justifyContent={variant === 'spread' ? 'space-between' : 'start'}
        flexGrow={variant === 'spread' ? '1' : '0'}
      >
        <Icon
          color="facePrimary"
          as={StrikeWordmarkIcon}
          mr={variant !== 'centered' ? 2 : 0}
          mb={variant === 'centered' ? 1 : 0}
          h={2.5}
          w={10}
        />
        <Text variant="caption" color="facePrimaryDisabled">
          {copyrightNotice}
        </Text>
      </Flex>
      {variant === 'left' && (
        <Flex alignItems="center" ml="auto">
          {children}
        </Flex>
      )}
    </Flex>
  )
}
