import type { StyleFunctionProps } from '@chakra-ui/system'
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const modalStyles: ComponentMultiStyleConfig = {
  parts: ['header', 'body', 'footer', 'overlay', 'dialogContainer', 'dialog'],
  baseStyle: (props: StyleFunctionProps) => ({
    header: {
      color: 'facePrimary',
      textAlign: 'center',
      ...props.theme['textStyles'].subheadline1,
      p: 6,
    },
    closeButton: {
      color: 'facePrimary',
      boxSize: 5,
      left: 6,
      top: 6,
    },
    body: {
      overflowY: 'auto',
      p: 6,
    },
    footer: {
      px: 6,
      pt: 4,
      pb: 6,
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
      width: '100%',
    },

    dialog: {
      bg: 'layerBackground',
      alignSelf: 'stretch',
      width: {
        base: '100%',
        md: '680px',
      },
      m: {
        base: 0,
        md: 2,
      },
      borderRadius: {
        base: 0,
        md: 12,
      },
    },
    overlay: {
      bg: 'rgba(43, 43, 43, 0.57)',
      backdropFilter: 'blur(4px)',
    },
  }),
  variants: {
    compact: {
      dialog: {
        alignSelf: 'center',
        m: 2,
        borderRadius: 12,
      },
    },
  },
}
