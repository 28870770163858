import Head from 'next/head'

export type SeoProps = {
  title?: string | null
  description?: string | null
  image?: string
}

export const SEO = ({ description, title }: SeoProps) => {
  const seo = {
    author: `@strike`,
    metaDescription: description || 'Welcome to your web portal to manage your Strike account.',
    metaTitle: title || 'Strike Dashboard',
    // TODO: Add a proper image
    // metaImage: image || 'https://dashboard.strike.me/og.png',
  }

  return (
    <Head>
      <title>{seo.metaTitle}</title>
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link rel="icon" href="/favicon.png" />
      {[
        {
          name: `title`,
          content: seo.metaTitle,
        },
        {
          name: `description`,
          content: seo.metaDescription,
        },
        {
          property: `og:title`,
          content: seo.metaTitle,
        },
        {
          property: `og:description`,
          content: seo.metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   property: `og:image`,
        //   content: seo.metaImage,
        // },
        // {
        //   property: `og:video`,
        //   content: seo.metaVideo,
        // },
        {
          property: `og:video:type`,
          content: 'video/mp4',
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: seo.author,
        },
        {
          name: `twitter:title`,
          content: seo.metaTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDescription,
        },
        // {
        //   name: `twitter:image`,
        //   content: seo.metaImage,
        // },
        // {
        //   name: `facebook-domain-verification`,
        //   content: 'lgouwsxdaxxbw1g4334n5eh3bqn98n',
        // },
      ].map((properties) => (
        <meta key={properties.name || properties.property} {...properties} />
      ))}
    </Head>
  )
}
