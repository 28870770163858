import { createContext, useContext, type ReactNode } from 'react'

export interface StrikeUiContextData {
  options?: StrikeUiOptions
  features?: StrikeUiFeatures
}

export interface StrikeUiOptions {
  lang?: 'en'
}

// Currently empty. Add options here. Option that is added here will be available in the context.
export type StrikeUiFeatures = Record<PropertyKey, unknown>

const StrikeUiContext = createContext<StrikeUiContextData>({} as StrikeUiContextData)
StrikeUiContext.displayName = 'StrikeUiContext'

interface StrikeUiProviderProps {
  children: ReactNode
  value: StrikeUiContextData
}

export const StrikeUiContextProvider = (props: StrikeUiProviderProps) => {
  return <StrikeUiContext.Provider {...props} />
}

export function useStrikeUi() {
  const context = useContext(StrikeUiContext)
  if (context === undefined) {
    throw new Error(`useStrikeUi() hook must be used within a StrikeUiContextProvider`)
  }
  return context
}
