import type { FC } from 'react'
import type { ButtonProps as BaseButtonProps } from '@chakra-ui/react'

import { Button as BaseButton, forwardRef } from '@chakra-ui/react'

export type ButtonVariant = 'ghost' | 'outline' | 'solid'
export type ButtonSize = 'lg' | 'md' | 'sm' | 'xs' | 'cd' | 'hug'
export type ButtonColorScheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'negative'
  | 'warn'

export interface ButtonProps extends BaseButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  colorScheme?: ButtonColorScheme
}
export const Button: FC<ButtonProps> = forwardRef<ButtonProps, typeof BaseButton>(
  ({ children, variant, size = 'lg', ...rest }, ref) => {
    return (
      <BaseButton ref={ref} variant={variant} size={size} {...rest}>
        {children}
      </BaseButton>
    )
  },
)
