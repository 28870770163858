import { ErrorPage } from './ErrorPage'

export function GenericErrorPage() {
  return (
    <ErrorPage
      header="Error"
      description="We're experiencing a temporary issue. Try refreshing the page or contact support if the problem persists."
      includeLogout
    >
      <ErrorPage.CtaButton onClick={() => window.location.reload()}>
        Refresh page
      </ErrorPage.CtaButton>
    </ErrorPage>
  )
}
