import type { AuthOptions, CallbacksOptions, Session } from 'next-auth'
import { getServerSession } from 'next-auth'
import type { GetServerSidePropsContext, NextApiRequest, NextApiResponse } from 'next'
import type { JWT } from 'next-auth/jwt'

export type ExtendedSession = { session: Session; token: JWT }

// These types are copied from getServerSession
type GetServerSessionOptions = Partial<Omit<AuthOptions, 'callbacks'>> & {
  callbacks?: Omit<AuthOptions['callbacks'], 'session'> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    session?: (...args: Parameters<CallbacksOptions['session']>) => any
  }
}

type GetServerSessionParams<O extends GetServerSessionOptions> =
  | [GetServerSidePropsContext['req'], GetServerSidePropsContext['res'], O]
  | [NextApiRequest, NextApiResponse, O]
  | [O]

/**
 * This function is a wrapper around getServerSession that returns an extended session which
 * includes the sensitive data that is never sent to the client side, like access and refresh tokens.
 * The function is intended to be used as a drop-in replacement for getServerSession for cases where
 * the access and refresh tokens are needed on the server side.
 **/
export const getExtendedServerSession = async <O extends GetServerSessionOptions>(
  ...args: GetServerSessionParams<O>
): Promise<ExtendedSession | null> => {
  let newToken: JWT | undefined

  // If you check the GetServerSessionParams<O> you will see that the method can be called with 1,
  // or 3 arguments. When called with 1 argument, the first argument is the options object. When
  // called with 3 arguments, the last one (args[2]) is the options object.
  const options: O = args.length === 1 ? args[0] : args[2]

  // We extend the options object to add a new event listener for the session event. This enables us
  // to get the jwt token which contains the access and refresh tokens which is not exposed normally
  // via the getServerSession method.
  const extendedOptions: O = {
    ...options,
    events: {
      ...options.events,
      session: ({ session, token }: { session: Session; token: JWT }) => {
        options.events?.session?.({ session, token })
        newToken = token
      },
    },
  }

  // Once we have the extended options object, need to form the args array again to call the
  // getServerSession. Again if the was called with 1 argument, we need to add create extended args
  // with options as the only argument. If the method was called with 3 arguments, we need to add
  // the original arg 1 (request), arg 2 (response) and the extended options object.
  const extendedArgs: GetServerSessionParams<O> =
    args.length === 1 ? [extendedOptions] : [args[0], args[1], extendedOptions]

  const session: Session | null = await getServerSession(...extendedArgs)

  if (!session) {
    return null
  }

  if (!newToken) {
    // Can't happen, but added to satisfy TS. It can't happen because the jwt token is used to build
    // the session returned by the getServerSession method and the event listener is always called
    // before the session is returned with the token.
    throw new Error('Token is missing')
  }

  return { session, token: newToken }
}
