import { AlertIcon, InfoIcon } from '@strike-apps/shared/icons'
import type { ReactNode } from 'react'

import type { BoxProps } from '../../layout/Box'
import { Flex } from '../../layout/Flex'
import { Icon } from '../../media-and-icons/Icon'
import { Text } from '../Text'

export interface WarningProps extends BoxProps {
  children: ReactNode
  isInvalid?: boolean
  isInfo?: boolean
}

export function Warning({ children, isInfo, isInvalid, ...rest }: WarningProps) {
  return (
    <Flex {...rest}>
      <Icon
        as={isInfo ? InfoIcon : AlertIcon}
        boxSize={4}
        color={isInvalid ? 'borderNegative' : 'facePrimary'}
      />
      <Text ml={1} variant="subheadline3" color="faceSecondary">
        {children}
      </Text>
    </Flex>
  )
}
