import { Alert as BaseAlert, Link } from '@chakra-ui/react'
import { Text } from '@strike-apps/shared/ui'
import NextLink from 'next/link'

export function UkBanner() {
  return (
    <BaseAlert
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      bg="#525252"
      alignItems="center"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius={12}
      gap={3}
    >
      <Text variant="body3" fontWeight="500" color="white">
        Don’t invest unless you’re prepared to lose all the money you invest. This is a high-risk
        investment and you are unlikely to be protected if anything goes wrong.{' '}
        <Link
          variant="body3"
          color="facePrimary"
          as={NextLink}
          href="https://strike.me/legal/uk-risk-warning"
        >
          Take 2 mins to learn more.
        </Link>
      </Text>
    </BaseAlert>
  )
}
