import { Flex } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

interface HeaderProps {
  children: ReactNode
}

export const Header: FC<HeaderProps> = ({ children, ...rest }) => {
  return (
    <Flex justifyContent="center" alignItems="center" p="32px" {...rest}>
      {children}
    </Flex>
  )
}
