import type { FC, ReactNode } from 'react'
import type { NumberInput as BaseInput } from '@chakra-ui/react'
import {
  forwardRef,
  Icon,
  type RadioGroupProps as BaseRadioGroupProps,
  type RadioProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { Flex } from '../../layout/Flex'
import { Text } from '../../typography/Text'
import { CheckIcon } from '@strike-apps/shared/icons'

export interface RadioGroupInputProps extends Omit<BaseRadioGroupProps, 'children'> {
  options: Record<string, RadioGroupOption>
  direction?: 'row' | 'column'
}

export interface RadioGroupOption {
  label: ReactNode
  note?: string
  isDisabled?: boolean
}

export const RadioGroupInput: FC<RadioGroupInputProps> = forwardRef<
  RadioGroupInputProps,
  typeof BaseInput
>(({ options, direction = 'column', ...rest }, ref) => {
  const { getRootProps, getRadioProps } = useRadioGroup(rest)
  const optionKeys = Object.keys(options)

  return (
    <Flex ref={ref} py={2} {...getRootProps()}>
      <Flex flexDir={direction} gap={6}>
        {optionKeys.map((key) => {
          const option = options[key]
          return (
            <Flex key={key} flexDir="column" gap={2}>
              <RadioInput {...getRadioProps({ value: key, isDisabled: option.isDisabled })}>
                {option.label}
              </RadioInput>
              {option.note && (
                <Text
                  variant="body3"
                  color={option.isDisabled ? 'facePrimaryDisabled' : 'faceTertiary'}
                  fontWeight={500}
                  pl={6}
                  pr={10}
                >
                  {option.note}
                </Text>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
})

const RadioInput = (props: RadioProps) => {
  const { getInputProps, getRadioProps, getLabelProps } = useRadio(props)
  const { children, isDisabled, onChange } = props
  const inputProps = getInputProps()
  const labelId = inputProps.id ? `${inputProps.id}-label` : undefined

  return (
    <Flex
      gap={2}
      align="flex-start"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      onClick={() => {
        if (!isDisabled && onChange) {
          // TODO: some incompatibilities with Chakra typedefs, this needs to be revisited later
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange(props.value as any)
        }
      }}
    >
      <input {...inputProps} hidden />
      <SelectableRadioCheck {...getRadioProps(props)} aria-labelledby={labelId} />
      <Text
        variant="body3"
        color={isDisabled ? 'facePrimaryDisabled' : 'facePrimary'}
        fontWeight={700}
        {...(labelId ? { id: labelId } : {})}
        {...getLabelProps()}
      >
        {children}
      </Text>
    </Flex>
  )
}

const SelectableRadioCheck = ({ isDisabled, isChecked, ...rest }: RadioProps) => {
  return (
    <Flex
      w="1.4em"
      h="1.4em"
      justify="center"
      align="center"
      rounded="full"
      bg={isDisabled ? 'objectSecondary' : 'layerBackground'}
      borderColor={isDisabled ? 'facePrimaryDisabled' : 'facePrimary'}
      borderWidth={1}
      color="facePrimary"
      role="radio"
      aria-checked={isChecked}
      {...rest}
      aria-hidden={false}
    >
      {isChecked && <Icon as={CheckIcon} boxSize={3} />}
    </Flex>
  )
}
