export { ReactComponent as BitcoinIcon } from './bitcoin.svg'
export { ReactComponent as DollarIcon } from './dollar.svg'
export { ReactComponent as DollarSign } from './dollar-sign.svg'
export { ReactComponent as EuroSign } from './euro-sign.svg'
export { ReactComponent as EuroIcon } from './euro.svg'
export { ReactComponent as PoundSign } from './pound-sign.svg'
export { ReactComponent as GhanaiaCediIcon } from './ghanaia-cedi.svg'
export { ReactComponent as KenyanShillingIcon } from './kenyan-shilling.svg'
export { ReactComponent as NigerianNairaIcon } from './nigerian-naira.svg'
export { ReactComponent as PoundIcon } from './pound.svg'
export { ReactComponent as TetherIcon } from './tether.svg'
export { ReactComponent as YenIcon } from './yen.svg'
export { ReactComponent as IconSwitchBTC } from './icon-switch-btc.svg'
export { ReactComponent as IconSwitchUSD } from './icon-switch-usd.svg'
export { ReactComponent as IconSwitchEUR } from './icon-switch-eur.svg'
export { ReactComponent as IconSwitchGBP } from './icon-switch-gbp.svg'
