import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const checkboxStyles: ComponentMultiStyleConfig = {
  parts: ['icon', 'control', 'label'],

  baseStyle: {
    icon: {
      boxSize: 3,
    },

    label: {
      color: 'facePrimary',
    },
    control: {
      width: '20px',
      height: '20px',
      border: '1px solid',
      borderColor: 'facePrimaryDisabled',
      backgroundColor: 'layerBackground',
      borderRadius: '50%',
      verticalAlign: 'middle',
      appearance: 'none',
      '-webkit-appearance': 'none',
      outline: 'none',
      cursor: 'pointer',
      _invalid: {
        borderColor: 'borderSecondary',
      },
      _hover: {
        color: 'facePrimary',
        borderColor: 'facePrimary',
        backgroundColor: 'layerBackground',
      },
      _checked: {
        color: 'facePrimary',
        borderColor: 'facePrimary',
        backgroundColor: 'layerBackground',
        outline: 'none',
      },
    },
  },

  variants: {
    modal: {
      control: {
        _disabled: {
          borderColor: 'gray100',
        },
      },
    },
  },
}
