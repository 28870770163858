import type { FC, ReactNode } from 'react'
import {
  Td as BaseTd,
  forwardRef,
  type TableCellProps as BaseTableCellProps,
} from '@chakra-ui/react'

export interface TableCellProps extends BaseTableCellProps {
  children?: ReactNode
  align?: 'left' | 'right'
}

export const Td: FC<TableCellProps> = forwardRef<TableCellProps, typeof BaseTd>(
  ({ align = 'left', children, ...rest }, ref) => {
    return (
      <BaseTd ref={ref} {...rest} isNumeric={align === 'right'}>
        {children}
      </BaseTd>
    )
  },
)
