import { useQuery } from '@tanstack/react-query'

import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { Balance } from '@strike-apps/strike/api-client'

import { GET_BALANCE_QUERY } from './queries'

export const useBalanceQuery = () => {
  return useQuery<Balance[], StrikeFetchError<ErrorDetails>>([GET_BALANCE_QUERY], () =>
    fetch<Balance[]>('/api/balance', { method: 'GET' }),
  )
}
