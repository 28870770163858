import type { FC, ReactNode } from 'react'
import { Container, Flex } from '@chakra-ui/react'

interface MainProps {
  children: ReactNode
}

export const Main: FC<MainProps> = ({ children }) => {
  return (
    <Container flex={1} display="flex">
      <Flex
        flex={1}
        direction="column"
        gap={4}
        justifyContent={['flex-start', 'center']}
        alignItems="center"
      >
        {children}
      </Flex>
    </Container>
  )
}
