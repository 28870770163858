import type { LDOptions } from 'launchdarkly-js-client-sdk'
import type { Session } from 'next-auth'
import { createContext, useContext } from 'react'

export interface FeatureFlagsContext {
  clientSideId: string
  options?: LDOptions
  session?: Session
}

const FeatureFlagsContext = createContext<FeatureFlagsContext>({ clientSideId: '' })
export const { Provider: FeatureFlagsContextProvider, Consumer: FeatureFlagsContextConsumer } =
  FeatureFlagsContext

export const useFeatureFlagsContext = () => {
  const context = useContext(FeatureFlagsContext)
  if (context === undefined) {
    throw new Error(`useFeatureFlagsContext() hook must be used within a FeatureFlagsContext`)
  }
  return context
}
