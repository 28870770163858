import { type ReactNode } from 'react'
import { Text } from '../../typography/Text'
import { Warning } from '../../typography/Warning'
import { FormControl } from '../FormControl'
import { Input, type InputProps } from '../Input'

export interface NumberFieldProps extends InputProps {
  label: string
  message?: ReactNode
  invalidMessage?: string
  placeholder?: string
}

export function NumberField({ label, message, invalidMessage, ...props }: NumberFieldProps) {
  return (
    <FormControl
      label={
        <Text
          color={props.isDisabled ? 'faceTertiaryDisabled' : 'faceTertiary'}
          variant="subheadline2"
        >
          {label}
        </Text>
      }
      labelProps={{
        sx: {
          position: 'absolute',
          top: 6,
          left: 4,
          zIndex: 2,
        },
      }}
      message={
        props.isInvalid ? (
          <Warning isInvalid={props.isInvalid}>{invalidMessage}</Warning>
        ) : (
          message && <Warning>{message}</Warning>
        )
      }
      sx={{ position: 'relative' }}
    >
      <Input variant="textField" {...props} />
    </FormControl>
  )
}
