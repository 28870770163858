import type { Session } from 'next-auth'
import intersection from 'lodash-es/intersection'

export const arePermissionsSufficient = (session: Session | null, requiredScopes: string) => {
  const sessionPermissionsRaw = session?.scope || ''

  const sessionPermissions = parsePermissions(sessionPermissionsRaw)
  const requiredPermissions = parsePermissions(requiredScopes)

  return intersection(sessionPermissions, requiredPermissions).length === requiredPermissions.length
}

const parsePermissions = (raw: string): string[] => {
  return raw.split(' ')
}
