export const UNKNOWN_ERROR_TEXT = 'An unknown error has occurred'

type TypeGuard<T> = (obj: unknown) => obj is T

export const isStrikeFetchError = (
  e: unknown,
): e is StrikeFetchError<string | Record<string, unknown>> => e instanceof StrikeFetchError

export const isStrikeFetchErrorWithData = <T extends string | Record<string, unknown>>(
  e: unknown,
  typeGuard: TypeGuard<T>,
): e is StrikeFetchError<T> => isStrikeFetchError(e) && typeGuard(e.data)

export class StrikeFetchError<T extends string | Record<string, unknown>> extends Error {
  public data: T
  public response: Response

  constructor(response: Response, data: T) {
    super()

    this.data = data
    this.response = response
    this.message = getMessage(response, data)
  }

  isClientError(): boolean {
    return 400 <= this.response.status && this.response.status < 500
  }
}

const getMessage = <T extends string | Record<string, unknown>>(
  response: Response,
  data: T,
): string => {
  if (typeof data === 'string') {
    return data
  }

  if (
    typeof data === 'object' &&
    data != null &&
    typeof data['message'] === 'string' &&
    data['message']
  ) {
    return data['message']
  }

  return response.statusText || UNKNOWN_ERROR_TEXT
}
