import type { FC, ReactElement } from 'react'
import { Text, type TextProps } from '../../typography/Text'
import { Box } from '../../layout/Box'
import { AlertIcon } from '@strike-apps/shared/icons'

export type LabelVariant = 'error' | 'warning' | 'reminder'

export interface LabelProps extends Omit<TextProps, 'variant'> {
  variant?: LabelVariant
  leftIcon?: ReactElement
}

export const Label: FC<LabelProps> = ({
  children,
  variant: type = 'reminder',
  leftIcon,
  ...rest
}) => {
  return (
    <Text
      as="div"
      variant="subheadline2"
      color="facePrimary"
      display="flex"
      alignItems="center"
      gap="6px"
      {...rest}
    >
      <Box color={getLabelColor(type)}>{leftIcon ? leftIcon : getDefaultIcon(type)}</Box> {children}
    </Text>
  )
}

function getDefaultIcon(type: LabelVariant) {
  switch (type) {
    case 'error':
    case 'warning':
      return <AlertIcon />
  }

  return null
}

function getLabelColor(type: LabelVariant) {
  switch (type) {
    case 'error':
      return 'faceNegative'
    case 'warning':
      return 'faceWarn'
  }

  return 'faceTertiary'
}
