import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UserProfilePatchReq, UserInfoResp } from '@ln-zap/consumer-api'
import { USER_INFO_QUERY } from './queries'

export const useSaveUserProfile = () => {
  const queryClient = useQueryClient()

  return useMutation<
    UserInfoResp,
    StrikeFetchError<ErrorDetails>,
    Partial<UserProfilePatchReq>,
    { previousUserInfo?: UserInfoResp }
  >(
    (data: Partial<UserProfilePatchReq>) =>
      fetch<UserInfoResp>('/api/profile', {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      // Optimistic update
      onMutate: async (newUserInfo) => {
        await queryClient.cancelQueries([USER_INFO_QUERY])

        const previousUserInfo = queryClient.getQueryData<UserInfoResp>([USER_INFO_QUERY])

        // Optimistically update to the new value
        queryClient.setQueryData([USER_INFO_QUERY], { ...previousUserInfo, ...newUserInfo })

        // Return a context object with the previous value
        return { previousUserInfo }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newUserInfo, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData([USER_INFO_QUERY], context.previousUserInfo)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([USER_INFO_QUERY])
      },
    },
  )
}
