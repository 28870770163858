import { Feature } from '@strike-apps/shared/feature-flags/react'
import { BitcoinSignIcon, LimitsIcon, LockIcon } from '@strike-apps/shared/icons'
import { Flex, IconWithBackground, Text } from '@strike-apps/shared/ui'
import { useSession } from 'next-auth/react'
import { Manage2FAButton } from './components'
import { BitcoinSectionContent } from './components/BitcoinSectionContent'
import { Limits } from './components/limits'
import { use2FAStatus } from './hooks'

function Section({
  icon,
  title,
  children,
}: {
  icon: React.FC
  title: string
  children: React.ReactNode
}) {
  return (
    <Flex
      flexDir="column"
      borderRadius="12px"
      borderWidth="1px"
      borderColor="borderPrimary"
      px={6}
      pt={6}
    >
      <Flex align="center" gap={4} mb={6}>
        <IconWithBackground icon={icon} color="facePrimary" />
        <Text variant="subheadline2" color="facePrimary">
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}

export function SettingsPageContent({ country }: { country: string | undefined }) {
  const { data } = useSession()
  const { data: mfaStatus } = use2FAStatus()
  const isBusinessUser = data?.user.business
  return (
    <Flex direction="column" flex={1} gap={6}>
      <Feature featureKey="feature-enable-2fa">
        <Section icon={LockIcon} title="Security">
          <Flex justify="space-between" align="center" my={6}>
            <Flex direction="column">
              <Text variant="subheadline2" color="facePrimary">
                Enable 2FA
              </Text>
              <Text variant="subheadline2" color="faceTertiary">
                Require a 2-factor authentication (2FA) code to access your account
              </Text>
            </Flex>

            <Manage2FAButton is2FAEnabled={mfaStatus?.enabled ?? false} />
          </Flex>
        </Section>
      </Feature>
      <Section icon={BitcoinSignIcon} title="Bitcoin">
        <BitcoinSectionContent />
      </Section>

      {country && isBusinessUser && (
        <Section icon={LimitsIcon} title="Limits">
          <Limits country={country} />
        </Section>
      )}
    </Flex>
  )
}
