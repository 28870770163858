import { dashboardTheme } from '@strike-apps/commerce-dashboard/ui'
import { StrikeUiProvider } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'

export interface StrikeDashboardUiProviderProps {
  children: ReactNode
}
export const StrikeDashboardUiProvider = ({ children }: StrikeDashboardUiProviderProps) => {
  return (
    <StrikeUiProvider
      features={{
        sideBarFullWidthBreakpoint: 'xl',
      }}
      theme={dashboardTheme}
      colorModeManager={{
        type: 'cookie', // this value doesn't matter, but is required
        get: () => 'dark', // always return dark mode
        set: () => {
          // do nothing when setting the color mode
        },
      }}
    >
      {children}
    </StrikeUiProvider>
  )
}
