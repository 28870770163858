import { Flex } from '@chakra-ui/react'
import { ProgressBar, Text, type TextProps } from '@strike-apps/shared/ui'

export type LimitProps = {
  caption: string
  leftText: string
  rightText: string
  value: number
  showProgressBar?: boolean
}

export function Limit({ caption, leftText, rightText, value }: LimitProps) {
  return (
    <Flex flex={1} direction="column">
      <Text mb={3} color="facePrimary" variant="subheadline2">
        {caption}
      </Text>
      <ProgressBar value={value} />
      <Flex justify="space-between">
        <Text mt={3} color="faceTertiary" variant="subheadline2">
          {leftText}
        </Text>
        <Text mt={3} color="faceTertiary" variant="subheadline2">
          {rightText}
        </Text>
      </Flex>
    </Flex>
  )
}

type TextLimitProps = TextProps & {
  limit: string
  caption: string
}

export function TextLimit({ caption, limit, ...rest }: TextLimitProps) {
  return (
    <Flex flex={1} direction="column" {...rest}>
      <Text color="facePrimary" variant="subheadline2">
        {caption}
      </Text>
      <Text color="faceTertiary" variant="subheadline2">
        {limit}
      </Text>
    </Flex>
  )
}
