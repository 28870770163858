import type { FirebaseApp } from 'firebase/app'
import { getAuth as getFirebaseAuth, connectAuthEmulator, type Auth } from 'firebase/auth'

export type { Auth } from 'firebase/auth'

type Config = {
  firebaseApp: FirebaseApp
  emulator?: { host: string; port: number } | null
}

type Global = {
  authEmulatorStarted: boolean
}

const _global = global as unknown as Global

export function getAuth(config: Config): Auth {
  const auth = getFirebaseAuth(config.firebaseApp)

  if (config.emulator && !_global.authEmulatorStarted) {
    connectAuthEmulator(auth, `http://${config.emulator.host}:${config.emulator.port}`)
    _global.authEmulatorStarted = true
  }

  return auth
}
