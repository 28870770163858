import type { MenuProps } from '@chakra-ui/react'
import { Flex, IconButton, Menu as BaseMenu } from '@chakra-ui/react'
import { CloseIcon } from '@strike-apps/shared/icons'
import type { FC, ReactNode } from 'react'
import { Text } from '../../typography/Text'

export const Menu: FC<MenuProps> = ({ children, ...rest }) => {
  return (
    <BaseMenu gutter={4} {...rest}>
      {children}
    </BaseMenu>
  )
}

export interface MenuHeaderProps {
  label: string
  leftContent?: ReactNode
  onClose?: () => void
}

export const MenuHeader = ({ label, leftContent, onClose }: MenuHeaderProps) => {
  return (
    <Flex px={3} py={4} bg="layerPrimary" alignItems="center" gap={2}>
      {leftContent}
      <Text flex={1} variant="subheadline2" color="facePrimary">
        {label}
      </Text>
      {onClose && (
        <IconButton
          icon={<CloseIcon />}
          boxSize={4}
          variant="ghost"
          size="hug"
          borderRadius="2px"
          aria-label="Close"
          onClick={onClose}
        />
      )}
    </Flex>
  )
}
