import type { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { createContext, useContext, type FC, type ReactNode } from 'react'

export type UserMode = 'merchant' | 'consumer'

export interface UserSettingsContextData {
  userMode: UserMode
}

const UserSettingsContext = createContext<UserSettingsContextData>({} as UserSettingsContextData)
UserSettingsContext.displayName = 'UserSettingsContext'

interface UserSettingsProviderProps {
  children: ReactNode
}

export const UserSettingsProvider: FC<UserSettingsProviderProps> = (props) => {
  const { data } = useSession()

  const value = {
    userMode: getUserMode(data),
  }

  return <UserSettingsContext.Provider value={value} {...props} />
}

export function useUserSettings() {
  const context = useContext(UserSettingsContext)
  if (context === undefined) {
    throw new Error(`useUserSettings() hook must be used within a UserSettingsProvider`)
  }
  return context
}

function getUserMode(data: Session | null): UserMode {
  return data?.user.business ? 'merchant' : 'consumer'
}
