import { type Environment, throwMissingConfig } from '@strike-apps/shared/config'
import type { EnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import { getEmulator } from '@strike-apps/shared/firebase'

const environment =
  (process.env.NEXT_PUBLIC_ENVIRONMENT as Environment) ??
  throwMissingConfig('NEXT_PUBLIC_ENVIRONMENT')

// For publicly exposed configs (vars starting with NEXT_PUBLIC_) we must use process.env.NEXT_PUBLIC_<NAME> explicitly
// instead of process.env[<NAME>] not to break Webpack's replacement at build time
export const PUBLIC_CONFIG: EnvSettings = {
  strikeIdentityServerUrl:
    process.env.NEXT_PUBLIC_STRIKE_IDENTITY_SERVER_URL ??
    throwMissingConfig('NEXT_PUBLIC_STRIKE_IDENTITY_SERVER_URL'),
  origin: process.env.NEXT_PUBLIC_ORIGIN ?? throwMissingConfig('NEXT_PUBLIC_ORIGIN'),
  environment,
  isSandboxEnvironment: environment !== 'live',
  bugsnagApiKey:
    process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? throwMissingConfig('NEXT_PUBLIC_BUGSNAG_API_KEY'),
  gaMeasurementId:
    process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ??
    throwMissingConfig('NEXT_PUBLIC_GA_MEASUREMENT_ID'),
  featureFlagsClientKey:
    process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY ??
    throwMissingConfig('NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY'),

  isRunningOnCI: process.env.CI === 'true',

  strikeApiScope:
    process.env.NEXT_PUBLIC_STRIKE_API_SCOPE ?? throwMissingConfig('NEXT_PUBLIC_STRIKE_API_SCOPE'),

  rqRetryCount: process.env.NEXT_PUBLIC_RQ_RETRY_COUNT
    ? parseInt(process.env.NEXT_PUBLIC_RQ_RETRY_COUNT)
    : throwMissingConfig('NEXT_PUBLIC_RQ_RETRY_COUNT'),
  rqRetryIncrementalDelay: process.env.NEXT_PUBLIC_RQ_RETRY_INCREMENTAL_INTERVAL
    ? parseInt(process.env.NEXT_PUBLIC_RQ_RETRY_INCREMENTAL_INTERVAL)
    : throwMissingConfig('NEXT_PUBLIC_RQ_RETRY_INCREMENTAL_INTERVAL'),

  supportEmailAddress:
    process.env.NEXT_PUBLIC_SUPPORT_EMAIL_ADDRESS ??
    throwMissingConfig('NEXT_PUBLIC_SUPPORT_EMAIL_ADDRESS'),

  firestoreDatabaseId:
    process.env.NEXT_PUBLIC_FIRESTORE_DATABASE_ID ??
    throwMissingConfig('NEXT_PUBLIC_FIRESTORE_DATABASE_ID'),
  firestoreEmulator: getEmulator(
    'NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST',
    process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST,
  ),
  plaidRedirectURL:
    process.env.NEXT_PUBLIC_PLAID_REDIRECT_URL ??
    throwMissingConfig('NEXT_PUBLIC_PLAID_REDIRECT_URL'),
  blockExplorerAddressBaseUrl:
    process.env.NEXT_PUBLIC_BLOCK_EXPLORER_ADDRESS_BASE_URL ??
    throwMissingConfig('NEXT_PUBLIC_BLOCK_EXPLORER_ADDRESS_BASE_URL'),
  blockExplorerTxBaseUrl:
    process.env.NEXT_PUBLIC_BLOCK_EXPLORER_TX_BASE_URL ??
    throwMissingConfig('NEXT_PUBLIC_BLOCK_EXPLORER_TX_BASE_URL'),
  maxBtcBuy: process.env.NEXT_PUBLIC_MAX_BTC_BUY
    ? Number(process.env.NEXT_PUBLIC_MAX_BTC_BUY)
    : throwMissingConfig('process.env.NEXT_PUBLIC_MAX_BTC_BUY'),
  maxLightningWithdrawal: process.env.NEXT_PUBLIC_MAX_LIGHTNING_WITHDRAW
    ? Number(process.env.NEXT_PUBLIC_MAX_LIGHTNING_WITHDRAW)
    : throwMissingConfig('NEXT_PUBLIC_MAX_LIGHTNING_WITHDRAW'),
  minOnchainWithdrawal: process.env.NEXT_PUBLIC_MIN_ONCHAIN_WITHDRAW
    ? Number(process.env.NEXT_PUBLIC_MIN_ONCHAIN_WITHDRAW)
    : throwMissingConfig('NEXT_PUBLIC_MIN_ONCHAIN_WITHDRAW'),
  maxRequestAmount: process.env.NEXT_PUBLIC_MAX_REQUEST_AMOUNT
    ? Number(process.env.NEXT_PUBLIC_MAX_REQUEST_AMOUNT)
    : throwMissingConfig('NEXT_PUBLIC_MAX_REQUEST_AMOUNT'),
  minRequestAmount: process.env.NEXT_PUBLIC_MIN_REQUEST_AMOUNT
    ? Number(process.env.NEXT_PUBLIC_MIN_REQUEST_AMOUNT)
    : throwMissingConfig('NEXT_PUBLIC_MIN_REQUEST_AMOUNT'),
  personaEnvironmentUS: process.env.NEXT_PUBLIC_PERSONA_ENVIRONMENT_US
    ? process.env.NEXT_PUBLIC_PERSONA_ENVIRONMENT_US
    : throwMissingConfig('NEXT_PUBLIC_PERSONA_ENVIRONMENT_US'),
  personaEnvironmentNonUS: process.env.NEXT_PUBLIC_PERSONA_ENVIRONMENT_NON_US
    ? process.env.NEXT_PUBLIC_PERSONA_ENVIRONMENT_NON_US
    : throwMissingConfig('NEXT_PUBLIC_PERSONA_ENVIRONMENT_NON_US'),
}
