export { ReactComponent as UsaFlagIcon } from './usa.svg'
export { ReactComponent as CanadaFlagIcon } from './canada.svg'
export { ReactComponent as MexicoFlagIcon } from './mexico.svg'
export { ReactComponent as ElSalvadorFlagIcon } from './el-salvador.svg'
export { ReactComponent as ArgentinaFlagIcon } from './argentina.svg'
export { ReactComponent as EuropeFlagIcon } from './eu.svg'
export { ReactComponent as BeninFlagIcon } from './benin.svg'
export { ReactComponent as GhanaFlagIcon } from './ghana.svg'
export { ReactComponent as KenyaFlagIcon } from './kenya.svg'
export { ReactComponent as SenegalFlagIcon } from './senegal.svg'
export { ReactComponent as NigeriaFlagIcon } from './nigeria.svg'
export { ReactComponent as PhilippinesFlagIcon } from './philippines.svg'
