import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'
import { textStyles } from '../foundations/textStyles'

export const badgeStyles: ComponentSingleStyleConfig = {
  baseStyle: {
    ...textStyles.caption,
    borderRadius: '4px',
    px: '4px',
    fontFamily: 'mono',
  },

  variants: {
    normal: {
      bg: 'layerSecondarySurface',
      color: 'facePrimary',
    },
    warning: {
      bg: 'layerWarn',
      color: 'faceWarn',
    },
  },

  defaultProps: {
    variant: 'normal',
  },
}
