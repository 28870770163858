import { Icon, IconButton } from '@chakra-ui/react'
import { CopyIcon } from '@strike-apps/shared/icons'
import type { IconButtonProps } from '../forms/Button'
import { useCopy } from '../../hooks'

interface CopyIconButtonProps extends IconButtonProps {
  value: string
  successMessage: string
}

export const CopyIconButton = ({ value, successMessage, ...rest }: CopyIconButtonProps) => {
  const handleCopy = useCopy(value, successMessage)

  return (
    <IconButton
      bg="transparent"
      icon={<Icon as={CopyIcon} boxSize={4} />}
      variant="mono2"
      onClick={handleCopy}
      size="cd"
      {...rest}
    />
  )
}
