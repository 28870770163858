import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UserSettings } from '@strike-apps/commerce-dashboard/data-access'
import { USER_SETTINGS_QUERY } from './queries'

export const useSaveUserSettings = () => {
  const queryClient = useQueryClient()

  return useMutation<UserSettings, StrikeFetchError<ErrorDetails>, Partial<UserSettings>>(
    (settings: Partial<UserSettings>) =>
      fetch<UserSettings>('/api/settings', {
        method: 'POST',
        body: JSON.stringify(settings),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER_SETTINGS_QUERY])
      },
    },
  )
}
