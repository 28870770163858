import { Timestamp } from 'firebase/firestore'

// We have the same code defined in firebase-admin lib, but we have to duplicate that because firebase admin and client
// libs have different Timestamp classes even though they are effectively the same.
export type DateToTimestamps<T> = {
  [K in keyof T]: T[K] extends Date
    ? Timestamp
    : T[K] extends Date | undefined
    ? Timestamp | undefined
    : T[K] extends Array<infer I>
    ? Array<DateToTimestamps<I>>
    : T[K] extends object
    ? DateToTimestamps<T[K]>
    : T[K]
}

export function convertDatesToTimestamps<T>(obj: T): DateToTimestamps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    const value = obj[key]
    if (value instanceof Date) {
      result[key] = Timestamp.fromDate(value)
    } else if (typeof value === 'object' && value !== null) {
      result[key] = convertDatesToTimestamps(value)
    } else {
      result[key] = value
    }
  }
  return result
}

export function convertTimestampsToDates<T>(obj: DateToTimestamps<T>): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    const value = obj[key]
    if (value instanceof Timestamp) {
      result[key] = value.toDate()
    } else if (typeof value === 'object' && value !== null) {
      result[key] = convertTimestampsToDates(value)
    } else {
      result[key] = value
    }
  }
  return result
}

export const getEmulator = (
  envVarName: string,
  envVar: string | undefined,
): { host: string; port: number } | null => {
  if (!envVar) {
    return null
  }

  try {
    const url = new URL(envVar)
    return { host: url.hostname, port: parseInt(url.port) }
  } catch (e) {
    throw new Error(
      `Invalid value set for '${envVarName}' env variable. The var must contain valid url with host and port`,
    )
  }
}
