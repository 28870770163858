import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  px: 3,
  py: 2,
  borderRadius: '8px',
  bg: 'layerSecondary',
  color: 'facePrimary',
})

export const tooltipStyles = defineStyleConfig({ baseStyle })
