import { useUserInfo } from '@strike-apps/commerce-dashboard/hooks'
import { isSupportedCurrency } from '@strike-apps/shared/utils'
import { BitcoinUnit } from './BitcoinUnit'
import { Divider } from '@strike-apps/shared/ui'
import { PassiveReceiveCurrency } from './PassiveReceiveCurrency'

export function BitcoinSectionContent() {
  const { data: userInfo } = useUserInfo()
  const defaultPassiveCurrency = userInfo?.defaultPassiveCurrency
  const defaultFiatCurrency = userInfo?.defaultFiatCurrency

  return (
    <>
      <BitcoinUnit />
      <Divider borderColor="borderPrimary" />
      {isSupportedCurrency(defaultPassiveCurrency) && isSupportedCurrency(defaultFiatCurrency) && (
        <PassiveReceiveCurrency
          defaultPassiveCurrency={defaultPassiveCurrency}
          defaultFiatCurrency={defaultFiatCurrency}
        />
      )}
    </>
  )
}
