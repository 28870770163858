export { ReactComponent as CameraIcon } from './camera.svg'
export { ReactComponent as NoCameraIcon } from './no-camera.svg'
export { ReactComponent as FlashIcon } from './flash.svg'
export { ReactComponent as NoFlashIcon } from './no-flash.svg'
export { ReactComponent as FaceIdIcon } from './face-id.svg'
export { ReactComponent as TouchIdIcon } from './touch-id.svg'
export { ReactComponent as FocusIcon } from './focus.svg'
export { ReactComponent as SelfieIcon } from './selfie.svg'
export { ReactComponent as BarcodeIcon } from './barcode.svg'
export { ReactComponent as ScanBarcodeIcon } from './scan-barcode.svg'
export { ReactComponent as QRCodeIcon } from './qrcode.svg'
export { ReactComponent as ScanQRCodeIcon } from './scan-qrcode.svg'
