export const GridColumns = {
  base: 4,
  md: 6,
  lg: 6,
  xl: 8,
  '2xl': 8,
}

export type GridBreakpoints = keyof typeof GridColumns

export const GridConfig = {
  base: 'repeat(4, 1fr)',
  md: 'repeat(6, 1fr)',
  lg: 'repeat(6, 1fr)',
  xl: 'repeat(8,1fr)',
  '2xl': 'repeat(8, 1fr)',
}

export const GridGap = 6

// Max grid size for 2xl breakpoint 150 px per column
export const MaxGridSize = GridGap * 4 * (GridColumns['2xl'] - 1) + 150 * GridColumns['2xl']

export function centerInGrid(elementWidth: Record<GridBreakpoints, number>) {
  return Object.keys(elementWidth).reduce((acc: Record<string, string>, key) => {
    const colWidth = elementWidth[key as GridBreakpoints]
    const position = Math.floor((GridColumns[key as GridBreakpoints] - colWidth) / 2)
    acc[key] = `${position + 1} / span ${colWidth}`
    return acc
  }, {})
}
