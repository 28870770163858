import { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'

export const useDetectMobilePlatform = () => {
  const [platform, setPlatform] = useState<'ios' | 'android' | null>(null)

  useEffect(() => {
    const parser = new UAParser(window.navigator.userAgent)
    const os = parser.getOS()?.name?.toLowerCase()
    if (os !== 'ios' && os !== 'android') {
      setPlatform(null)
    } else {
      setPlatform(os)
    }
  }, [])

  return platform
}
