import { useState } from 'react'

export const useValueTrack = (initialValue: string[] = ['']) => {
  const [enteredAmountsStack, setEnteredAmountsStack] = useState<string[]>(initialValue)

  function updateValue(newValue: string) {
    setEnteredAmountsStack((currentStack) => {
      return currentStack.concat([newValue])
    })
  }

  function revertToPreviousValue() {
    setEnteredAmountsStack((currentStack) => {
      // we need at least one value in the stack
      if (currentStack.length === 1) return currentStack

      return currentStack.slice(0, currentStack.length - 1)
    })
  }

  function reset() {
    // we need at least one value in the stack
    setEnteredAmountsStack([''])
  }

  return {
    value: enteredAmountsStack[enteredAmountsStack.length - 1],
    updateValue,
    revertToPreviousValue,
    reset,
  }
}
