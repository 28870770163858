import type { FC, ReactNode } from 'react'

export interface LnDeepLinkProps {
  children: ReactNode
  protocol?: string
  lnInvoice: string
}

export const LnDeepLink: FC<LnDeepLinkProps> = ({
  children,
  protocol = 'lightning',
  lnInvoice,
}) => {
  return <a href={`${protocol}:${lnInvoice}`}>{children}</a>
}
