import { useCurrency } from '../../../hooks'
import { Flex, type FlexProps } from '../../layout/Flex'
import { Text } from '../../typography/Text'
import { Currency } from '@strike-apps/strike/api-client'

export interface AmountProps extends FlexProps {
  value?: string
  currency?: Currency
  color?: string
}

const NO_CONTENT_PLACEHOLDER = '--.--'

export const Amount = ({
  value,
  currency = Currency.USD,
  color = 'facePositive',
  ...rest
}: AmountProps) => {
  const { currencySymbol } = useCurrency(currency)
  const amountColor = value ? color : 'facePrimaryDisabled'

  return (
    <Flex gap={2} {...rest}>
      <Text variant="body1" fontWeight={700} color={amountColor}>
        {currencySymbol}
      </Text>
      <Text
        fontSize="80px"
        fontWeight={700}
        lineHeight="80px"
        color={amountColor}
        whiteSpace="nowrap"
      >
        {value || NO_CONTENT_PLACEHOLDER}
      </Text>
    </Flex>
  )
}
