import { Grid, GridItem, Icon, Show } from '@chakra-ui/react'
import { StrikeLogoIcon, StrikeWordmarkIcon } from '@strike-apps/shared/icons'
import { Box, Flex, Link, Text } from '@strike-apps/shared/ui'
import NextLink from 'next/link'
import { type FC, type ReactNode } from 'react'
import { useWideModeBreakpoint } from '../molecules/Sidebar/hooks'
export interface PageProps {
  children: ReactNode
  title?: ReactNode
  headerContent?: React.ReactElement
  headerStickyContent?: React.ReactNode
  sidebarContent?: React.ReactElement
  preMainContent?: React.ReactElement | null
}

export const Page: FC<PageProps> = ({
  children,
  title,
  headerContent,
  headerStickyContent,
  sidebarContent,
  preMainContent,
}) => {
  const wideMode = useWideModeBreakpoint()
  return (
    <Grid
      h="100vh"
      gridTemplateColumns={{ base: '60px 1fr', [wideMode]: '176px 1fr' }}
      gridTemplateRows="68px 1fr 65px"
    >
      <Show above="sm">
        <GridItem rowStart={1} bg="layerPrimary" colStart={1}>
          <Flex alignItems="center" justify={['center', 'flex-start']} height="100%" pl={5}>
            <Icon as={StrikeLogoIcon} color="facePrimary" boxSize={5} />
          </Flex>
        </GridItem>

        <GridItem rowStart={2} colStart={1} rowSpan={2} bg="layerPrimary">
          {sidebarContent}
        </GridItem>
      </Show>
      <GridItem
        colStart={{
          base: 1,
          sm: 2,
        }}
        colSpan={2}
        borderBottomColor="borderPrimary"
        borderBottomWidth="1px"
      >
        <Flex h="100%" justifyContent="space-between" alignItems={'center'} px={6}>
          <Text variant="title4" color="facePrimary">
            {title}
          </Text>
          {headerContent}
        </Flex>
      </GridItem>

      <GridItem
        rowStart={2}
        rowSpan={3}
        colStart={{
          base: 1,
          sm: 2,
        }}
        bg="transparent"
        colSpan={3}
        overflowX="auto"
      >
        {headerStickyContent && (
          <Flex px={6} pt={6} position="sticky" top="0" zIndex={3} align="center" justify="center">
            {headerStickyContent}
          </Flex>
        )}
        <Flex flexDirection="column" justifyContent="space-between" h="100%">
          <Flex
            flexDirection="column"
            flexGrow={1}
            px={6}
            py={{
              base: 6,
              xl: 10,
            }}
          >
            {preMainContent && (
              <Box
                pb={{
                  base: 6,
                  xl: 10,
                }}
              >
                {preMainContent}
              </Box>
            )}

            <Flex flex={1} justify="center">
              {children}
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            p={6}
            borderTopColor="borderPrimary"
            borderTopWidth="1px"
          >
            <FooterLinks
              links={[
                {
                  name: 'API terms',
                  url: 'https://developer.strike.me/terms/',
                },
                {
                  name: 'Business terms',
                  url: 'https://strike.me/business-terms/',
                },
                {
                  name: 'Privacy notice',
                  url: 'https://developer.strike.me/legal/privacy/',
                },
              ]}
            />
            <Box color="facePrimary">
              <StrikeWordmarkIcon width={38} height={8} />
            </Box>
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  )
}

type LinkData = {
  name: string
  url: string
}

function FooterLinks({ links }: { links: LinkData[] }) {
  return (
    <Flex gap={4}>
      {links.map((link) => {
        return (
          <Link
            textStyle="caption"
            color="faceTertiary"
            textDecoration="none"
            fontWeight="normal"
            as={NextLink}
            href={link.url}
            key={link.name}
            sx={{
              '&:hover': { textDecoration: 'none' },
            }}
          >
            {link.name}
          </Link>
        )
      })}
    </Flex>
  )
}
