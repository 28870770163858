import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'
import { textStyles } from '../foundations/textStyles'

export const codeStyles: ComponentSingleStyleConfig = {
  baseStyle: {
    borderRadius: '4px',
  },

  sizes: {
    sm: {
      ...textStyles.mono2,
      px: '4px',
    },
    md: {
      ...textStyles.mono2,
      py: '2px',
      px: '8px',
    },
  },

  variants: {
    normal: {
      bg: 'objectSecondary',
      color: 'facePrimary',
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'normal',
  },
}
