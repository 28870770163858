import crypto from 'crypto'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
export type Encoding = 'base64' | 'hex'
export type Query = Record<string, string> | { [key: string]: string }
export type Body = Record<string, unknown>
export type SignatureType = 'HMAC-SHA256' | 'EC'

export type HttpCallData = {
  httpMethod?: HttpMethod | ''
  endpoint?: string | ''
  query?: Query | URLSearchParams
  body?: Body
}

export type GenerateSignature = HttpCallData & {
  secret: string
  encoding: Encoding
  signatureType: SignatureType
}

function sign(source: string, secret: string, type: SignatureType, encoding: Encoding): string {
  if (type === 'HMAC-SHA256') {
    const hmac = crypto.createHmac('sha256', secret)
    return hmac.update(source).digest(encoding)
  }

  if (type === 'EC') {
    const sign = crypto.createSign('sha256')
    return sign.update(source).sign(secret, encoding)
  }

  throw new Error('Invalid encryption type')
}

export function verifySignature({
  httpMethod = '',
  endpoint = '',
  query,
  body,
  secret,
  encoding,
  signatureType,
  signature,
}: GenerateSignature & { signature: string }) {
  if (signatureType === 'HMAC-SHA256') {
    return (
      signature ===
      generateSignature({
        httpMethod,
        endpoint,
        query,
        body,
        secret,
        encoding,
        signatureType,
      })
    )
  }

  if (signatureType === 'EC') {
    const verifier = crypto.createVerify('sha256')
    verifier.update(createMessage({ httpMethod, endpoint, query, body }))
    return verifier.verify(secret, signature, encoding)
  }

  throw new Error('Invalid encryption type')
}

function createMessage({ httpMethod = '', endpoint = '', query, body }: HttpCallData) {
  if (!['POST', 'PUT', 'PATCH'].includes(httpMethod.toUpperCase()) && body) {
    throw new Error('Body only allowed for POST, PUT and PATCH requests')
  }

  // Ensure stable order of query params
  const urlParams = query instanceof URLSearchParams ? query : new URLSearchParams(query)
  if (urlParams) {
    urlParams.sort()
  }
  const queryString = urlParams?.toString()

  const bodyString = body ? JSON.stringify(body) : ''

  return `${httpMethod.toUpperCase()}${endpoint}${queryString}${bodyString}`
}

export function generateSignature({
  httpMethod = '',
  endpoint = '',
  query,
  body,
  secret,
  encoding,
  signatureType,
}: GenerateSignature): string {
  return sign(createMessage({ httpMethod, endpoint, query, body }), secret, signatureType, encoding)
}
