import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { print } from 'graphql'
import axios from 'axios'

export type FetchParams = {
  query: string
  variables?: Record<string, string | number | string[] | number[]>
  apiUrl?: string
  apiToken?: string
  includeDrafts?: boolean
}
/**
 * @deprecated use queryDatoCMS instead
 */
export async function fetchAPI({
  query,
  variables,
  includeDrafts,
  apiUrl = 'https://graphql.datocms.com',
  apiToken,
}: FetchParams) {
  const { data } = await axios.post(
    apiUrl,
    JSON.stringify({
      query,
      variables,
    }),
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...(includeDrafts ? { 'X-Include-Drafts': 'true' } : {}),
      },
    },
  )

  if (data.errors) {
    console.error(data.errors)
    throw new Error('Failed to fetch API')
  }
  return data.data
}

export async function queryDatoCMS<TResult = unknown, TVariables = Record<string, unknown>>(
  apiToken: string,
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables,
  isDraft?: boolean,
): Promise<TResult> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Exclude-Invalid': 'true',
    Authorization: `Bearer ${apiToken}`,
  }

  if (isDraft) headers['X-Include-Drafts'] = 'true'

  const response = await fetch('https://graphql.datocms.com/', {
    cache: 'force-cache',
    method: 'POST',
    headers,
    body: JSON.stringify({ query: print(document), variables }),
  })

  const body = (await response.json()) as { data: TResult } | { errors: unknown[] }

  if ('errors' in body) {
    throw new Error(`Invalid GraphQL request: ${body.errors}`)
  }

  return body.data
}
