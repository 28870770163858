import { Flex, Grid, Button } from '@strike-apps/shared/ui'
import { Icon } from '@chakra-ui/react'
import { StrikeWordmarkIcon } from '@strike-apps/shared/icons'
import { useSignOut } from '@strike-apps/commerce-dashboard/hooks'

interface StrikeWordmarkHeaderProps {
  includeLogout?: boolean
}

export function StrikeWordmarkHeader({ includeLogout }: StrikeWordmarkHeaderProps) {
  const signOut = useSignOut()

  return (
    <Grid templateColumns="1fr 1fr 1fr" alignItems="center" h="72px" px={{ base: 4, md: 6 }}>
      <Flex gridColumn="2" justifyContent="center">
        <Icon color="facePrimary" as={StrikeWordmarkIcon} height="10px" w="auto" />
      </Flex>
      {includeLogout && (
        <Flex justifyContent="flex-end">
          <Button size="sm" variant="ghost" onClick={signOut}>
            Log out
          </Button>
        </Flex>
      )}
    </Grid>
  )
}
