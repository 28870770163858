import { decodeJwt } from 'jose'
import type { OAuthConfig, OAuthUserConfig } from 'next-auth/providers'
import type { SessionUser } from './next-auth'

export interface StrikeProviderConfig {
  identityServerUrl: string
  apiScopes: string
}

export const StrikeProvider = function Strike<P extends Record<string, unknown>>(
  config: StrikeProviderConfig,
  options: OAuthUserConfig<P>,
): OAuthConfig<P> {
  return {
    id: 'strike',
    name: 'Strike',
    type: 'oauth',
    wellKnown: `${config.identityServerUrl}/.well-known/openid-configuration`,
    authorization: {
      params: {
        scope: config.apiScopes,
        response_type: 'code',
      },
    },
    idToken: true,
    checks: ['pkce', 'state'],
    userinfo: {
      url: `${config.identityServerUrl}/connect/userinfo`,
      async request({ tokens }) {
        return decodeJwt(tokens.access_token ?? '')
      },
    },
    profile(profile) {
      return {
        id: profile['sub'],
        name: profile['name'],
        email: profile['email'],
        image: profile['avatarUrl'],
        username: profile['username'],
        business: (profile['business'] as string)?.toLocaleLowerCase() === 'true',
      } as SessionUser
    },
    options,
  }
}
