import {
  CSSReset,
  ChakraProvider,
  createCookieStorageManager,
  type ColorModeProviderProps,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import {
  StrikeUiContextProvider,
  type StrikeUiFeatures,
  type StrikeUiOptions,
} from '../context/StrikeUiContext'
import { colorModeCookieKeyName, defaultTheme } from '../theme'

export interface StrikeUiProviderProps {
  children: ReactNode
  theme?: typeof defaultTheme
  options?: StrikeUiOptions
  features?: StrikeUiFeatures
  cookies?: string
  colorModeManager?: ColorModeProviderProps['colorModeManager']
}

const defaultColorModeManager = createCookieStorageManager(colorModeCookieKeyName)

export const StrikeUiProvider = (props: StrikeUiProviderProps) => {
  return (
    <ChakraProvider
      theme={props.theme ?? defaultTheme}
      colorModeManager={props.colorModeManager ?? defaultColorModeManager}
    >
      <StrikeUiContextProvider
        value={{
          options: props.options,
          features: props.features,
        }}
      >
        <CSSReset />
        {props.children}
      </StrikeUiContextProvider>
    </ChakraProvider>
  )
}
