const convertPxToRem = (px: number, base = 16) => (1 / base) * px + 'rem'

export const headingStyles = {
  'title-lg1': {
    fontSize: convertPxToRem(96),
    lineHeight: convertPxToRem(88),
    fontWeight: '500',
    letterSpacing: '0',
  },
  'title-lg2': {
    fontSize: convertPxToRem(72),
    lineHeight: convertPxToRem(64),
    fontWeight: '500',
    letterSpacing: '0',
  },
  'title-lg3': {
    fontSize: convertPxToRem(48),
    lineHeight: convertPxToRem(48),
    fontWeight: '500',
    letterSpacing: '0',
  },
  title1: {
    fontSize: convertPxToRem(32),
    lineHeight: convertPxToRem(36),
    fontWeight: '500',
    letterSpacing: '0',
  },
  title2: {
    fontSize: convertPxToRem(24),
    lineHeight: convertPxToRem(28),
    fontWeight: '500',
    letterSpacing: '0',
  },
  title3: {
    fontSize: convertPxToRem(20),
    lineHeight: convertPxToRem(24),
    fontWeight: '500',
    letterSpacing: '0',
  },
  title4: {
    fontSize: convertPxToRem(18),
    lineHeight: convertPxToRem(20),
    fontWeight: '500',
    letterSpacing: '0',
  },
}

export const bodyStyles = {
  body1: {
    fontSize: convertPxToRem(20),
    lineHeight: convertPxToRem(28),
    fontWeight: '400',
    letterSpacing: '0',
  },
  body2: {
    fontSize: convertPxToRem(16),
    lineHeight: convertPxToRem(24),
    fontWeight: '400',
    letterSpacing: '0',
  },
  body3: {
    fontSize: convertPxToRem(14),
    lineHeight: convertPxToRem(20),
    fontWeight: '400',
    letterSpacing: '0',
  },
  body4: {
    fontSize: convertPxToRem(12),
    lineHeight: convertPxToRem(16),
    fontWeight: '400',
    letterSpacing: '0',
  },
  subheadline1: {
    fontSize: convertPxToRem(16),
    lineHeight: convertPxToRem(24),
    fontWeight: '500',
    letterSpacing: '0',
  },
  subheadline2: {
    fontSize: convertPxToRem(14),
    lineHeight: convertPxToRem(20),
    fontWeight: '500',
    letterSpacing: '0',
  },
  subheadline3: {
    fontSize: convertPxToRem(12),
    lineHeight: convertPxToRem(16),
    fontWeight: '500',
    letterSpacing: '0',
  },
  button1: {
    fontSize: convertPxToRem(14),
    lineHeight: convertPxToRem(20),
    fontWeight: '500',
    letterSpacing: '0',
  },
  button2: {
    fontSize: convertPxToRem(12),
    lineHeight: convertPxToRem(16),
    fontWeight: '500',
    letterSpacing: '0',
  },
  caption: {
    fontSize: convertPxToRem(10),
    lineHeight: convertPxToRem(12),
    fontWeight: '400',
    letterSpacing: '0',
  },
  mono1: {
    fontSize: convertPxToRem(14),
    lineHeight: convertPxToRem(20),
    fontWeight: '500',
    letterSpacing: '0',
    fontFamily: 'mono',
  },
  mono2: {
    fontSize: convertPxToRem(12),
    lineHeight: convertPxToRem(16),
    fontWeight: '500',
    letterSpacing: '0',
    fontFamily: 'mono',
  },
}

export const textStyles = {
  ...headingStyles,
  ...bodyStyles,
}

export type TextVariant = keyof typeof textStyles | Record<string, keyof typeof textStyles>
