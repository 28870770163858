import { StrikeLogoIcon } from '@strike-apps/shared/icons'
import { Flex, type FlexProps } from '@chakra-ui/react'
import { Icon, type IconProps } from '../../media-and-icons/Icon'
import type { FC, ReactNode } from 'react'

export interface StrikeHeaderProps extends FlexProps {
  children?: ReactNode
  iconSize?: number
  includeLogo?: boolean
  iconProps?: IconProps
  onLogoClick?: () => void
}

export const StrikeHeader: FC<StrikeHeaderProps> = ({
  includeLogo = true,
  iconSize = 5,
  children,
  onLogoClick,
  iconProps = {},
  ...rest
}) => {
  return (
    <Flex
      justifyContent={children ? 'space-between' : 'center'}
      alignItems="center"
      px={6}
      py={4}
      h={20}
      width="100%"
      {...rest}
    >
      {includeLogo && (
        <Icon
          as={StrikeLogoIcon}
          boxSize={iconSize}
          color="facePrimary"
          cursor={onLogoClick ? 'pointer' : 'default'}
          onClick={onLogoClick}
          {...iconProps}
        />
      )}
      {children}
    </Flex>
  )
}
