import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { BitcoinUnits } from '@ln-zap/consumer-api'
import { BITCOIN_UNIT_QUERY } from './queries'

export const useSaveBitcoinUnit = () => {
  const queryClient = useQueryClient()

  return useMutation<
    BitcoinUnits,
    StrikeFetchError<ErrorDetails>,
    BitcoinUnits,
    { previousBitcoinUnit?: BitcoinUnits }
  >(
    (bitcoinUnit: BitcoinUnits) =>
      fetch<BitcoinUnits>('/api/settings/bitcoinunit', {
        method: 'PATCH',
        body: JSON.stringify({ bitcoinUnit }),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      // Optimistic update
      onMutate: async (newBitcoinUnit) => {
        await queryClient.cancelQueries([BITCOIN_UNIT_QUERY])

        const previousBitcoinUnit = queryClient.getQueryData<BitcoinUnits>([BITCOIN_UNIT_QUERY])

        // Optimistically update to the new value
        queryClient.setQueryData([BITCOIN_UNIT_QUERY], newBitcoinUnit)

        // Return a context object with the previous value
        return { previousBitcoinUnit }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newBitcoinUnit, context) => {
        if (context?.previousBitcoinUnit) {
          queryClient.setQueryData([BITCOIN_UNIT_QUERY], context.previousBitcoinUnit)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([BITCOIN_UNIT_QUERY])
      },
    },
  )
}
