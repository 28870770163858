import { Flex, Icon } from '@chakra-ui/react'
import { Text } from '@strike-apps/shared/ui'
import type { FC, ReactElement } from 'react'
import { useWideModeBreakpoint } from './hooks'

export interface SidebarMenuItemProps {
  label: string
  href: string
  isActive: boolean
  onClick?: () => void
  renderLink?: (children: ReactElement, href: string) => ReactElement
  IconComponent: FC
}

export const SidebarMenuItem = ({
  label,
  href,
  isActive,
  IconComponent,
  onClick,
  renderLink = (children) => children,
}: SidebarMenuItemProps) => {
  const bg = isActive ? 'layerSecondary' : 'layerPrimary'
  const bgHover = isActive ? 'layerPrimaryHover' : 'layerPrimaryHover'
  const pointerEvents = isActive ? 'none' : 'inherit'
  const iconColor = isActive ? 'facePrimary' : 'faceTertiary'

  const wideModeBreakpoint = useWideModeBreakpoint()

  return renderLink(
    <Flex
      role="group"
      position="relative"
      onClick={onClick}
      alignItems="center"
      p={3}
      borderRadius="8px"
      bg={bg}
      gap={3}
      cursor="pointer"
      pointerEvents={pointerEvents}
      transitionProperty="common"
      transitionDuration="normal"
      _hover={{
        background: bgHover,
      }}
    >
      <Flex
        color={iconColor}
        _groupHover={{
          color: 'facePrimary',
        }}
      >
        <Icon as={IconComponent} boxSize={5} />
      </Flex>

      <Text
        display={{
          base: 'none',
          [wideModeBreakpoint]: 'block',
        }}
        mt="2px"
        variant="body3"
        color={iconColor}
        _groupHover={{
          color: 'facePrimary',
        }}
      >
        {label}
      </Text>
    </Flex>,
    href,
  )
}
