import {
  AccordionItemV2,
  AccordionV2,
  Badge,
  Checkbox,
  CodeLabel,
  Flex,
  Text,
} from '@strike-apps/shared/ui'
import { useState } from 'react'

export interface Scope {
  name: string
  description: string
  disabled?: boolean
}

export interface ScopeCategory {
  category: string
  scopes: Scope[]
}

export interface ScopeSelectionProps {
  label: string
  items: Scope[]

  initialSelection?: string[]
  onSelectionChanged: (scopes: string[]) => void
}

export const ScopeSelection = ({
  label,
  items,
  onSelectionChanged,
  initialSelection,
}: ScopeSelectionProps) => {
  const [selectedScopes, setSelectedScopes] = useState(initialSelection ?? [])

  const handleSelectionChanged = (name: string, isChecked: boolean) => {
    const setArray = new Set(selectedScopes)

    if (isChecked) {
      setArray.add(name)
    } else {
      setArray.delete(name)
    }

    const newSelection = Array.from(setArray)

    setSelectedScopes(newSelection)
    onSelectionChanged(newSelection)
  }

  return (
    <AccordionV2
      allowToggle
      w="100%"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius="12px"
    >
      <AccordionItemV2
        label={
          <Flex gap={2} alignItems="center">
            <Text as="h3" variant="subheadline2" color="facePrimary">
              {label}
            </Text>
            <SelectedScopesBadge value={selectedScopes.length} />
          </Flex>
        }
        buttonProps={{
          px: 4,
          py: 6,
        }}
        px={4}
        border={0}
      >
        <Flex flexDir="column">
          {items?.map((item) => (
            <ScopeRow
              key={item.name}
              initialChecked={initialSelection?.includes(item.name)}
              onChange={handleSelectionChanged}
              {...item}
            />
          ))}
        </Flex>
      </AccordionItemV2>
    </AccordionV2>
  )
}

interface SelectedScopesBadgeProps {
  value: number
}

const SelectedScopesBadge = ({ value }: SelectedScopesBadgeProps) => {
  const isSelected = value > 0

  return (
    <Badge
      px="5px"
      py="2px"
      bg={isSelected ? 'objectAccent' : 'objectPrimaryDisabled'}
      color={isSelected ? 'facePrimaryAlt' : 'facePrimaryDisabled'}
      borderRadius="36px"
    >
      {value}
    </Badge>
  )
}

interface ScopeRowProps extends Scope {
  initialChecked?: boolean
  onChange: (name: string, isChecked: boolean) => void
}

const ScopeRow = ({ name, disabled, description, initialChecked, onChange }: ScopeRowProps) => {
  const [isChecked, setIsChecked] = useState(initialChecked)
  return (
    <Checkbox
      defaultChecked={initialChecked}
      disabled={disabled}
      value={name}
      size="sm"
      alignItems="flex-start"
      pl={4}
      py={6}
      px={0}
      overflowX="auto"
      _notLast={{
        borderBottom: '1px solid',
        borderColor: 'borderPrimary',
      }}
      onChange={(ev) => {
        onChange(name, ev.currentTarget.checked)
        setIsChecked(ev.currentTarget.checked)
      }}
    >
      <Flex flexDirection="column" pl={1} gap={2}>
        <Text variant="subheadline2" color="facePrimary">
          {description}
        </Text>
        <CodeLabel
          bg={isChecked ? 'faceAccent' : 'objectTertiary'}
          textStyle="mono2"
          color={isChecked ? 'facePrimaryInverse' : 'faceTertiary'}
          width="fit-content"
          px={1}
        >
          {name}
        </CodeLabel>
      </Flex>
    </Checkbox>
  )
}
