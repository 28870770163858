import type { InputProps as BaseInputProps } from '@chakra-ui/react'
import { type FC } from 'react'

import { forwardRef, Input as BaseInput } from '@chakra-ui/react'

export type InputSize = 'lg' | 'md' | 'sm'
export type InputVariant = 'normal' | 'modal' | 'textField' | 'phoneTextFieldPart'

export interface InputProps extends BaseInputProps {
  variant?: InputVariant
  isInvalid?: boolean
  hasWarning?: boolean
  size?: InputSize
}

export const Input: FC<InputProps> = forwardRef<InputProps, typeof BaseInput>(
  ({ children, hasWarning, variant, ...rest }, ref) => {
    const defaultVariant = 'normal'

    return (
      <BaseInput
        variant={variant || defaultVariant}
        ref={ref}
        role="textbox"
        {...rest}
        sx={getBorderColor({ isInvalid: rest.isInvalid, hasWarning })}
      >
        {children}
      </BaseInput>
    )
  },
)

export const getBorderColor = ({
  isInvalid,
  hasWarning,
}: Pick<InputProps, 'isInvalid' | 'hasWarning'>): object => {
  if (isInvalid) return { borderColor: 'borderNegative' }
  if (hasWarning) return { borderColor: 'borderWarn' }

  return {}
}
