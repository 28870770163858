import type { ButtonProps } from '../../forms/Button'
import { Flex, type FlexProps } from '../../layout/Flex'
import { Select } from '../Select'

import { IconButton } from '@chakra-ui/react'
import { BaseMenuButton, Menu } from '../../overlay/Menu'
import type { DropdownItem } from './Dropdown'

export interface CompactDropdownProps extends Omit<ButtonProps, 'onSelect'> {
  defaultValue?: string
  options: DropdownItem[]
  onSelect?: (opt: string) => void
  icon?: React.ReactElement
}

export function CompactDropdown({
  options,
  icon,
  onSelect,
  ...rest
}: CompactDropdownProps & FlexProps) {
  function selectOption(opt: string) {
    if (onSelect) {
      onSelect(opt)
    }
  }

  return (
    <Flex align="center" justify="center" {...rest}>
      <Menu placement="left-start" computePositionOnMount>
        <BaseMenuButton
          borderRadius="12px"
          background="layerPrimary"
          boxSize={9}
          as={IconButton}
          icon={icon}
          variant="ghost"
          size="cd"
          role="menu"
          aria-label="User menu"
        />
        <Select.List>
          {options.map((o) => (
            <Select.Option
              key={o.key}
              value={o.key}
              onClick={() => selectOption(o.key)}
              isDisabled={o.disabled}
            >
              {o.value}
            </Select.Option>
          ))}
        </Select.List>
      </Menu>
    </Flex>
  )
}
