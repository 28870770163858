import { AccordionItemV2, AccordionV2, Flex, Text } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'

interface AccordionProps {
  label: string
  description: string
  children: ReactNode
}

export function Accordion({ label, description, children }: AccordionProps) {
  return (
    <AccordionV2 allowToggle>
      <AccordionItemV2
        label={
          <Flex direction="column" textAlign="left">
            <Text variant="subheadline2" color="facePrimary">
              {label}
            </Text>
            <Text variant="subheadline2" color="faceTertiary">
              {description}
            </Text>
          </Flex>
        }
        border={0}
        py={4}
      >
        {children}
      </AccordionItemV2>
    </AccordionV2>
  )
}
