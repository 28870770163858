import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'

export interface UseSupportLinkResult {
  emailHref: string
  openSupportLink: () => void
}

export const useSupportLink = (emailSubject = 'Strike dashboard support request') => {
  const { supportEmailAddress } = useEnvSettings()
  const emailHref = `mailto:${supportEmailAddress}?subject=${encodeURIComponent(emailSubject)}`

  return {
    supportEmailAddress,
    emailHref,
    openSupportLink: () => {
      window.location.href = emailHref
    },
  }
}

export const useTechnicalSupportLink = () => {
  const { supportEmailAddress } = useEnvSettings()
  const emailHref = `mailto:${supportEmailAddress}`

  return {
    emailHref,
    openSupportLink: () => {
      window.location.href = emailHref
    },
  }
}
