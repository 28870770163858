import type { FC, ReactNode } from 'react'
import { Flex } from '../Flex'
import { Box } from '../Box'

export interface TileProps {
  children: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
}

export const Tile: FC<TileProps> = ({ leftContent, rightContent, children }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      boxSizing="border-box"
      w="100%"
      minH="64px"
      borderRadius="16px"
      p={4}
      gap={3}
      bg="layerPrimarySurface"
    >
      {leftContent && <Flex>{leftContent}</Flex>}

      <Box flex={1} fontSize="15px" color="facePrimary">
        {children}
      </Box>

      {rightContent && <Flex>{rightContent}</Flex>}
    </Flex>
  )
}
