export function insertStringAt(inserted: string, str: string, position: number) {
  const index = position < 0 ? str.length + position : position

  if (!str[index]) return str

  return str.substring(0, index) + inserted + str.substring(index)
}

export function truncateLeadingZeros(numberAsString: string) {
  return numberAsString.replace(/^0+/, '')
}

export function capitalize(text: string) {
  return text.replace(/^\w/, (c) => c.toUpperCase())
}

export function shortifyId(id: string) {
  const firstPart = id.substring(0, id.indexOf('-'))
  const lastPart = id.substring(id.lastIndexOf('-') + 1)
  const divider = `${firstPart ? '...' : ''}`

  return `${firstPart}${divider}${lastPart}`
}
