import type { FC } from 'react'
import type { IconButtonProps as BaseIconButtonProps } from '@chakra-ui/react'
import { forwardRef, IconButton as BaseIconButton } from '@chakra-ui/react'
import type { ButtonColorScheme, ButtonSize, ButtonVariant } from './Button'

export type IconButtonVariant = ButtonVariant | 'simple'

export interface IconButtonProps extends BaseIconButtonProps {
  variant?: IconButtonVariant
  size?: ButtonSize
  colorScheme?: ButtonColorScheme
}
export const IconButton: FC<IconButtonProps> = forwardRef<IconButtonProps, typeof BaseIconButton>(
  ({ children, size = 'lg', ...rest }, ref) => {
    return (
      <BaseIconButton ref={ref} size={size} {...rest}>
        {children}
      </BaseIconButton>
    )
  },
)
