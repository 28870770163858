export { default as CameraIcon } from './camera.svg'
export { default as NoCameraIcon } from './no-camera.svg'
export { default as FlashIcon } from './flash.svg'
export { default as NoFlashIcon } from './no-flash.svg'
export { default as FaceIdIcon } from './face-id.svg'
export { default as TouchIdIcon } from './touch-id.svg'
export { default as FocusIcon } from './focus.svg'
export { default as SelfieIcon } from './selfie.svg'
export { default as BarcodeIcon } from './barcode.svg'
export { default as ScanBarcodeIcon } from './scan-barcode.svg'
export { default as QRCodeIcon } from './qrcode.svg'
export { default as ScanQRCodeIcon } from './scan-qrcode.svg'
