import { useQuery } from '@tanstack/react-query'
import type { StrikeFetchError } from '@strike-apps/strike/fetch'
import { fetch } from '@strike-apps/strike/fetch'
import type { Currency, PrivateAccountProfile } from '@strike-apps/strike/api-client'
import { GET_PROFILE } from './queries'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'

export type Profile = PrivateAccountProfile & {
  defaultCurrency: Currency
}

type Options = { onSuccess?: (data: Profile) => void }

export const useProfile = (options: Options = {}) => {
  return useQuery<Profile, StrikeFetchError<ErrorDetails>>(
    [GET_PROFILE],
    () => fetch<Profile>('/api/profile', { method: 'GET' }),
    options,
  )
}
