import {
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  useClipboard,
  type InputGroupProps,
  Flex,
} from '@chakra-ui/react'
import { CheckIcon, CopyIcon } from '@strike-apps/shared/icons'
import { formatLnInvoice } from '@strike-apps/shared/utils'
import { useInfoToast } from '../../../hooks/useInfoToast'
import { Input, type InputProps } from './Input'
import { Text } from '../../typography/Text'

export interface InputClipboardCopyProps extends InputGroupProps {
  value: string
  trailingLength?: number
  inputProps?: InputProps
  infoMessage?: string
  label?: string
}

export const InputClipboardCopy = (props: InputClipboardCopyProps) => {
  const { value, inputProps, trailingLength, infoMessage, label, ...rest } = props
  const toast = useInfoToast()
  const { hasCopied, onCopy } = useClipboard(value)

  const handleCopy = function () {
    if (infoMessage) {
      toast.showToast({ message: infoMessage })
    }
    onCopy()
  }

  const height = label ? '40px' : '68px'

  return (
    <InputGroup {...rest} height={height}>
      <Flex flexDirection="column" flex={1}>
        {Boolean(label) && (
          <Text pl={4} variant="subheadline2" color="facePrimary">
            {label}
          </Text>
        )}
        <Input
          bg="layerBackground"
          fontStyle="mono2"
          value={trailingLength ? formatLnInvoice(value, trailingLength) : value}
          isReadOnly
          resize="none"
          pr={20}
          variant="normal"
          border="1px solid"
          borderColor="borderPrimary"
          borderRadius="16px"
          {...inputProps}
        />
      </Flex>
      <InputRightElement height="100%">
        <IconButton
          bg={hasCopied ? 'objectPositive' : 'layerPrimary'}
          aria-label="Copy to clipboard"
          icon={<Icon as={hasCopied ? CheckIcon : CopyIcon} boxSize={4} />}
          colorScheme={hasCopied ? 'positive' : 'secondary'}
          onClick={handleCopy}
          size="cd"
          boxSize="36px"
          isDisabled={inputProps?.isDisabled}
        />
      </InputRightElement>
    </InputGroup>
  )
}
