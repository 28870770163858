import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const radioStyles: ComponentMultiStyleConfig = {
  parts: ['label'],
  baseStyle: {
    control: {
      // Customize the outer circle
      borderColor: 'faceTertiary',
      borderWidth: '1px',
      _checked: {
        bg: 'transparent',
        borderColor: 'facePrimary',
        _before: {
          // Style for the inner circle
          width: '10px', // Change the size of the inner circle
          height: '10px', // Change the size of the inner circle
          bg: 'faceAccent', // Background color of the inner circle
        },
      },
    },
    label: {
      textStyle: 'body3',
      fontWeight: 700,
      marginInlineStart: '12px',
    },
  },
}
