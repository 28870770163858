declare global {
  interface Window {
    _ccRun: boolean
  }
}

const addCookieConsentListeners = ({ onChange }: { onChange?: () => void }) => {
  /**
   * React specific fix: avoid adding event listeners twice
   */
  if (window._ccRun) return

  window.addEventListener('cc:onConsent', () => {
    if (onChange) {
      onChange()
    }
  })

  window.addEventListener('cc:onChange', (_event: Event) => {
    if (onChange) {
      onChange()
    }
  })
}

export default addCookieConsentListeners
