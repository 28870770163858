import { extendTheme, type ChakraTheme } from '@chakra-ui/react'
import type { DeepPartial } from '@chakra-ui/react'

// foundation styles
import { colors } from './foundations/colors'
import { semanticTokens } from './foundations/tokens'
import { fonts } from './foundations/fonts'
import { textStyles } from './foundations/textStyles'

// components base styles
import { buttonStyles as Button } from './components/button'
import { headingStyles as Heading } from './components/heading'
import { menuStyles as Menu } from './components/menu'
import { avatarStyles as Avatar } from './components/avatar'
import { tableStyles as Table } from './components/table'
import { modalStyles as Modal } from './components/modal'
import { inputStyles as Input, textAreaStyles as Textarea } from './components/input'
import { numberInputStyles as NumberInput } from './components/numberInput'
import { formLabelStyles as FormLabel } from './components/formLabel'
import { accordionStyles as Accordion } from './components/accordion'
import { checkboxStyles as Checkbox } from './components/checkbox'
import { codeStyles as Code } from './components/code'
import { badgeStyles as Badge } from './components/badge'
import { cardStyles as Card } from './components/card'
import { tabsStyles as Tabs } from './components/tabs'
import { selectStyles as Select } from './components/select'
import { linkStyles as Link } from './components/link'
import { progressStyles as Progress } from './components/progress'
import { radioStyles as Radio } from './components/radio'
import { tooltipStyles as Tooltip } from './components/tooltip'
import { drawerStyles as Drawer } from './components/drawer'
import { switchTheme as Switch } from './components/switch'
import { alertStyles as Alert } from './components/alert'

// global styles
import { styles } from './styles'

const overrides: OverridesDict = {
  colors,
  semanticTokens,
  styles,
  fonts,
  textStyles,

  components: {
    Button,
    Drawer,
    Heading,
    Menu,
    Avatar,
    Table,
    Modal,
    Input,
    NumberInput,
    Textarea,
    FormLabel,
    Accordion,
    Checkbox,
    Code,
    Badge,
    Card,
    Tabs,
    Select,
    Link,
    Progress,
    Radio,
    Tooltip,
    Switch,
    Alert,
  },

  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
}

export type OverridesDict = DeepPartial<ChakraTheme>

export { styles }
export const defaultTheme = extendTheme(overrides)
export const extendDefaultTheme = (overrides: OverridesDict) => extendTheme(defaultTheme, overrides)
export const colorModeCookieKeyName = 'strike-chakra-ui-color-mode'
