import { Flex, Icon, type FlexProps } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { StrikeLogoIcon } from '@strike-apps/shared/icons'
import { Footer } from '../../navigation/Footer'
import { Header } from '../../navigation/Header'
import { Main } from '../../others/Main'

export interface PageProps extends FlexProps {
  children?: ReactNode
  fullWidth?: boolean
  header?: ReactNode
  footer?: ReactNode
}

export const Page: FC<PageProps> = ({ children, header, footer, fullWidth, ...rest }) => {
  return (
    <Flex m="auto" minH="100vh" maxW={fullWidth ? 'auto' : '1440px'} direction="column" {...rest}>
      {header === undefined ? (
        <Header>
          <Icon as={StrikeLogoIcon} color="facePrimary" boxSize={5} />
        </Header>
      ) : (
        header
      )}
      <Main>{children}</Main>
      {footer === undefined ? <Footer /> : footer}
    </Flex>
  )
}
