import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const cardStyles: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexDir: 'column',
    justify: 'center',
    align: 'center',
    bg: 'layerBackground',
    borderColor: ['transparent', 'borderSecondary'],
    borderRadius: '24px',
  },
  sizes: {
    md: {
      w: ['100%', 'auto'],
      minW: ['auto', '456px'],
      p: ['16px', '48px 40px 72px 40px'],
    },
    lg: {
      w: '100%',
      p: ['16px', '48px'],
    },
  },
}
