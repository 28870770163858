import { useMultiStyleConfig, type CSSWithMultiValues } from '@chakra-ui/system'
import { useCountries } from '@strike-apps/shared/hooks'
import { getCountryCodeForRegionCode, getExample } from 'awesome-phonenumber'
import { useFieldState } from 'informed'
import Image from 'next/image'
import { useState } from 'react'
import { Box } from '../../layout/Box'
import { Flex } from '../../layout/Flex'
import { Warning } from '../../typography/Warning'
import { CompactDropdown } from '../Dropdown'
import { InformedTextField, type InformedTextFieldProps } from '../informed'

interface PhoneInputProps extends InformedTextFieldProps {
  defaultCountry?: string
  onChange?(): void
}

function createFormatter(countryCode: string) {
  const placeholder = getExample(countryCode, 'mobile').number?.international
  const callingCode = `+${getCountryCodeForRegionCode(countryCode)} `
  const formatter = callingCode + placeholder?.replace(callingCode, '').replace(/\d/g, '#')
  return { placeholder, formatter }
}

function getFlagResourceUrl(countryCode: string): string {
  return `/flags/${countryCode}.png`
}

function parser(v: string) {
  return v && v.replace(/[^\d-+ ()]+/g, '')
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  defaultCountry = 'US',
  informed,
  ...rest
}) => {
  const [selectedCountry, setSelectedCountry] = useState<string>(defaultCountry?.toUpperCase())
  const { placeholder, formatter } = createFormatter(selectedCountry)
  const { showError, error } = useFieldState(informed.field)
  const countryNames = useCountries()
  const countriesOptions = countryNames.map((item) => ({
    key: item.shortCode ?? item.name,
    value: item.name,
  }))

  const styles = useMultiStyleConfig('Input', { variant: 'textField' })
  const fieldStyle = styles['field'] as Record<string, CSSWithMultiValues>
  return (
    <Flex direction="column">
      <Flex
        border="1px solid"
        borderColor="borderPrimary"
        borderRadius={16}
        pr={3}
        overflow="hidden"
        sx={{
          _hover: fieldStyle['_hover'],
          _focus: fieldStyle['_focus'],
          _disabled: fieldStyle['_disabled'],
        }}
      >
        <InformedTextField
          autoComplete="off"
          placeholder={placeholder}
          borderStyle="none"
          willShowError={false}
          variant="phoneTextFieldPart"
          informed={{
            formatter,
            parser,
            ...informed,
          }}
          type="text"
          {...rest}
        />
        <CompactDropdown
          icon={
            <Box borderRadius="50%" overflow="hidden" width={4} height={4} position="relative">
              <Image
                style={{
                  borderRadius: '20px',
                }}
                src={getFlagResourceUrl(selectedCountry)}
                alt={selectedCountry}
                layout="fill"
                objectFit="cover"
              />
            </Box>
          }
          options={countriesOptions}
          onSelect={(countryCode) => setSelectedCountry(countryCode as string)}
        />
      </Flex>
      {showError && (
        <Warning pt={2} ml={4} isInvalid={true}>
          {error as string}
        </Warning>
      )}
    </Flex>
  )
}
