import { Radio as BaseRadio, Text } from '@strike-apps/shared/ui'

interface RadioProps {
  label: string
  description: string
  value: string
}

export function Radio({ label, description, value }: RadioProps) {
  return (
    <BaseRadio value={value} w="100%">
      <Text variant="subheadline2" color="facePrimary">
        {label}
      </Text>
      <Text variant="subheadline2" color="faceTertiary">
        {description}
      </Text>
    </BaseRadio>
  )
}
