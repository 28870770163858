import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import type { Environment } from '@strike-apps/shared/config'
import React, { type FC, type ReactNode } from 'react'

export interface GetGlobalErrorBoundaryProps {
  isRunningOnCI: boolean
  environment: Environment
  bugsnagApiKey: string
  generateAnonymousId?: boolean
}

interface GlobalErrorBoundaryProps {
  children: ReactNode
}

export function getGlobalErrorBoundary(config: GetGlobalErrorBoundaryProps) {
  const isClientSide = typeof window !== 'undefined'
  const enabledReleaseStages: Environment[] = ['next', 'dev', 'live']

  if (config.isRunningOnCI && config.environment === 'test') enabledReleaseStages.push('test')

  if (isClientSide && !Bugsnag.isStarted()) {
    Bugsnag.start({
      apiKey: config.bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
      maxEvents: 100,
      releaseStage: config.environment,
      enabledReleaseStages,
      generateAnonymousId: config.generateAnonymousId,
    })
  }

  const ErrorBoundary = Bugsnag.isStarted()
    ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? false
    : false

  const GlobalErrorBoundary: FC<GlobalErrorBoundaryProps> = ({ children }) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : <>{children}</>
  }

  return GlobalErrorBoundary
}
