import type { FC } from 'react'
import type { AvatarProps as BaseAvatarProps } from '@chakra-ui/react'

import { Avatar as BaseAvatar, forwardRef } from '@chakra-ui/react'

export type AvatarSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export interface AvatarProps extends BaseAvatarProps {
  size?: AvatarSize
  initialOnly?: boolean
}

export const Avatar: FC<AvatarProps> = forwardRef<AvatarProps, typeof BaseAvatar>(
  ({ children, initialOnly = false, name, size = 'sm', ...rest }, ref) => {
    return (
      <BaseAvatar
        getInitials={initialOnly ? (fullName) => fullName?.slice(0, 1) : undefined}
        name={name}
        ref={ref}
        size={size}
        {...rest}
      >
        {children}
      </BaseAvatar>
    )
  },
)
