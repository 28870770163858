import {
  Currency,
  getBrowserLanguage,
  getCurrencySymbol,
  getFormatter,
} from '@strike-apps/shared/utils'
import { useServerSide } from './useServerSide'

/**
 * Provides formatCurrency function that allows to translate number amount to currency format.
 * @param currency
 */
export const useCurrency = (currency: Currency = Currency.USD) => {
  const { isBrowser } = useServerSide()
  const locale = isBrowser ? getBrowserLanguage() : 'en'
  const formatter = getFormatter(currency, locale)
  const currencySymbol = getCurrencySymbol(currency === Currency.USDT ? 'USD' : currency, locale)

  return {
    currencySymbol,
    formatCurrency: (amount: number): string | undefined => formatter.format(amount),
  }
}
