import { type FirebaseApp } from 'firebase/app'
import {
  connectFirestoreEmulator,
  getFirestore as firebaseGetFirestore,
  type Firestore,
} from 'firebase/firestore'

export { type FirebaseApp } from 'firebase/app'
export type { Firestore } from 'firebase/firestore'

type Config = {
  firebaseApp: FirebaseApp
  emulator?: { host: string; port: number } | null
}

type Global = {
  firestoreEmulatorStarted: boolean
}

const _global = global as unknown as Global

export function getFirestore(config: Config): Firestore {
  const db = firebaseGetFirestore(config.firebaseApp)

  /*
   * The call to connectFirestoreEmulator runs multiple times.
   * That causes the app to crash when developing.
   * https://stackoverflow.com/questions/69620265
   * https://stackoverflow.com/questions/65066963
   *
   * The global hack:
   * https://stackoverflow.com/questions/40743131
   */
  if (config.emulator && !_global.firestoreEmulatorStarted) {
    connectFirestoreEmulator(db, config.emulator.host, config.emulator.port)
    _global.firestoreEmulatorStarted = true
  }

  return db
}
