import { CopyIcon } from '@strike-apps/shared/icons'
import { formatLnInvoice } from '@strike-apps/shared/utils'
import { Text, type TextProps } from '../../typography/Text'
import { Icon } from '@chakra-ui/react'

export type LnInvoiceRowProps = TextProps & {
  lnInvoice: string
  trailingLength: number
  onCopy?: () => void
}

export function LnInvoiceRow({ onCopy, lnInvoice, trailingLength, ...rest }: LnInvoiceRowProps) {
  return (
    <Text {...rest} variant="mono1">
      {onCopy && (
        <Icon
          color="facePrimary"
          as={CopyIcon}
          mr={'2px'}
          position="relative"
          onClick={onCopy}
          top="2px"
          sx={{
            cursor: 'pointer',
          }}
        />
      )}
      {formatLnInvoice(lnInvoice, trailingLength)}
    </Text>
  )
}
