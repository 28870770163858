import { signIn, useSession } from 'next-auth/react'
import { useIsUS } from '@strike-apps/commerce-dashboard/hooks'
import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import { useFeatureFlag } from '@strike-apps/shared/feature-flags/react'
import { arePermissionsSufficient } from '@strike-apps/commerce-dashboard/util-auth/session-permissions'
import { Alert } from '@strike-apps/commerce-dashboard/ui'
import { InfoIcon, TransferIcon } from '@strike-apps/shared/icons'
import { useRouter } from 'next/router'
import {
  useSaveUserSettings,
  useUserSettings,
} from '@strike-apps/commerce-dashboard/feature-settings'

type PreMainContentType = 'permissions-alert' | 'sandbox' | 'ach-promo' | null

function usePreMainContent(): PreMainContentType {
  const { data: session } = useSession()
  const isUS = useIsUS()
  const { strikeApiScope, isSandboxEnvironment } = useEnvSettings()
  const isACHEnabled = useFeatureFlag('feat-enable-ach-payment-methods', false)
  const { data: userSettings } = useUserSettings()
  const { isAchBannerDismissed } = userSettings ?? {}

  if (!arePermissionsSufficient(session, strikeApiScope)) {
    return 'permissions-alert'
  }

  if (isACHEnabled && isUS && !isAchBannerDismissed) {
    return 'ach-promo'
  }

  if (isSandboxEnvironment) {
    return 'sandbox'
  }

  return null
}

export const PreMainContent = () => {
  const router = useRouter()
  const { mutateAsync: saveUserSettings } = useSaveUserSettings()
  const type = usePreMainContent()

  switch (type) {
    case 'permissions-alert':
      return (
        <Alert
          title="Required permissions have changed"
          description="Please re-authorize your application to continue using the dashboard. You will be directed to the authorization page."
          actionText="Allow new permissions"
          icon={InfoIcon}
          onAction={() => signIn('strike')}
        />
      )

    case 'ach-promo':
      return router.pathname !== '/accounts' ? (
        <Alert
          title="ACH bank deposits are here"
          description="Link a bank account within your Strike Dashboard and buy bitcoin instantly."
          actionText="Link a bank account"
          icon={TransferIcon}
          onAction={() => router.push('/accounts')}
          onDismiss={() => saveUserSettings({ isAchBannerDismissed: true })}
        />
      ) : null

    case 'sandbox':
      return <Alert title="Sandbox Environment" />
    default:
      return null
  }
}
