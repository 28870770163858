import { type FC } from 'react'
import {
  forwardRef,
  NumberInput as BaseInput,
  NumberInputField,
  type NumberInputProps as BaseInputProps,
} from '@chakra-ui/react'
import { getBorderColor, type InputSize, type InputVariant } from './Input'

export interface NumberInputProps extends BaseInputProps {
  variant?: InputVariant
  placeholder?: string
  isInvalid?: boolean
  hasWarning?: boolean
  size?: InputSize
}

export const NumberInput: FC<NumberInputProps> = forwardRef<NumberInputProps, typeof BaseInput>(
  ({ hasWarning, variant, ...rest }, ref) => {
    const defaultVariant = 'normal'

    return (
      <BaseInput variant={variant || defaultVariant} ref={ref} role="textbox" {...rest}>
        <NumberInputField
          placeholder={rest.placeholder}
          sx={getBorderColor({ isInvalid: rest.isInvalid, hasWarning })}
        />
      </BaseInput>
    )
  },
)
